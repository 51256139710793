#block-views-bdtv-block  {
//  @include clearfix;
  position: relative;
  margin-bottom: 40px;
//  @include divider(40px, $border-color, top);

  h2.block__title {
    border-bottom: none;

//    @include at-breakpoint($tab) {
//      float: left;
//    }

    background: #fff;
    color: $bh-blue;
    span {
      display: block;
      text-align: left;
//      @include box-title($bh-blue, #fff);
//      @include at-breakpoint($tab) {
//        display: inline-block;
//        margin-right: 10px;
//      }

    }
    margin-bottom: 20px;
  }

  .block__content {
    clear: both;
    padding: 0 0px;
  }
  
}

.view-bdtv {
  
  .view-footer {
//    @include more-link();
    width: 100%;
    text-align: center;
  }
  
  .view-content {

    .owl-carousel {
//      @include owl-buttons;

      .owl-buttons {
        margin: 0;
        top: 40px;
      }

    }

    .owl-item {
      padding: 0 10px;

      img {
       // width: 100%;
        height: auto;
      }
      
      .field--name-field-image .field__item a {
        position: relative;
        display: block;

        &::after {
          position: absolute;
          font-family: FontAwesome;
          content: "\f01d";
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto auto;
          height: 50px;
          width: 50px;
          font-size: 50px;
          opacity: .5;


        }

        &:hover:after {
          opacity: 1;
        }
      }
  
      .views-field-title {
        background: #fff;
        padding: 10px;
        font-weight: 300;
        text-align: center;
      }
    }
  }
}

.view-directories, .page-directory {


  h1 {
    margin-bottom: 20px !important;
    padding-bottom: 0 !important;
  }

  .view-header {
    margin-bottom: -20px;
    font-weight: 300;
  }

  .form-type-bef-link {
//    @include span-columns(1, 3);
    margin-bottom: 5px;
    padding: 2px;
    font-size: 0.9em;
    color: #999;
    font-weight: 300;

    &:nth-child(3n+0) {
      @include omega;
    }

    &.selected {
      background: #eee;
      color: #000;
    }
  }

  .views-exposed-form {
//    padding: 5px 12px;
//    border: solid thin $border-color;

    label {
      border-bottom: none;
    }

    select {
      width: 100%;
      border: solid thin $border-color;
    }
  }

  > .view-content {
    clear: both;
  }
}

.directory_images div {
  float: left;
  width: 20%;
  display: block;
  height: auto;
  padding: 20px;
  border: solid thin $border-color;
  margin: 0 20px 20px 0;
  text-align: center;
  h3 {
    
    
  }
  p {
    
  }
}

.field--name-field-services {
  margin-top: 20px;
}

.node-type-experts {
  .field--name-field-image {
    float: right;
    padding: 0 0 200px 3%;
    img {
      width: 100%;
      height: auto;
    }
  }
}
  


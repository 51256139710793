
body.page-my-boards, body.page-user-dashboard-favourite, body.view-user-favourite-dashboard,
body.page-user-dashboard-brands {
  .view-content {
    max-width: 100%;
    .views-field-title, h2 {
      font-family: inherit!important;
      letter-spacing: inherit!important;
      font-size: inherit!important;
      padding-top: 0px!important;
      margin-top: 0px;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
      a {
        @include title();
        padding: 0px 1em 1em 1em;
        font-weight: bold;
        letter-spacing: inherit;
        font-family: inherit;

        &:hover {
          color: $bd_pink;
          text-decoration: none;
        }
      }
    }
    img {
      margin: 0 auto;
      display: block;
    }
    .as-button {
      #magical-modal-link {
        a {
          text-transform: uppercase;
          background: $bd_pink;
          color: #ffffff;
          padding: 10px;
          max-width: 200px;
          text-decoration: none;
          margin-left: auto;
          display: flex;
          margin-bottom: 2em;
          &:hover {
            text-decoration: none;
            background: $bd-brand;
          }
        }
      }
    }
  }
  .view-display-id-page_1 {
    .as-button {
      .download-links {
        a {
          @include title();
          text-align: center;
          font-family: inherit;
          mso-line-spacing: inherit;
          display: block;
        }
      }
    }
  }
  .view-header {
    h2 {
      font-style: normal;
      text-align: center;
      padding-top: 0em;
      padding-bottom: 3em;
      text-transform: uppercase;
      font-size: 3rem;
      font-family: inherit;
      &::before {
        content: " ";
        display: table;
        background: #ccc;
        width: 30%;
        height: 1px;
        position: relative;
        margin-top: 20px;
        float: left;
        @media screen and (max-width: $screen-fx-max) {
          width: 20%;
        }
      }
      &::after {
        content: " ";
        display: table;
        background: #ccc;
        width: 30%;
        height: 1px;
        position: relative;
        margin-top: 20px;
        float: right;
        @media screen and (max-width: $screen-fx-max) {
          width: 20%;
        }
      }
    }
  }
  .view-views-list-user-dashboards {
    .download-links, .view-empty {
      a {
        margin-top: 2em;
        text-decoration: none;
        font-weight: bold;
        letter-spacing: inherit;
        font-family: inherit;
        text-align: center;
        display: block;
        &::after {
          content: '\f067';
          font-family: FontAwesome;
          display: block;
          margin-top: 2em;
          color: #ffffff;
          background: $bd_pink;
          max-width: 50px;
          margin-left: auto;
          margin-right: auto;
          padding: 10px 0px;
          border-radius: 30px;
          -moz-border-radius: 30px;
          -webkit-border-radius: 30px;
        }
        &:hover {
          text-decoration: none;
          color: $bd_pink;
        }
      }
    }
    .views-field-edit-list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 18px;
      margin-left: auto;
      position: absolute;
      right: 0px;
      @media screen and (max-width: $screen-xs-max) {
        right: 20px;
      }
    }
  }
  .view-user-favourite-dashboard {
    .group-wrapper {
      a {
        text-transform: uppercase;
        color: $bd_pink;
        text-align: center;
        display: block;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .col-xs-12.col-sm-6.col-md-4.col-lg-4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 3em;
    @media screen and (max-width: $screen-xs-max) {
      margin-bottom: 3em;
    }
  }
  .view-id-views_user_dashboard_content {
    .view-header {
      div {
        font-style: normal;
        text-align: center;
        padding-top: 0em;
        padding-bottom: 3em;
        text-transform: uppercase;
        font-size: 3rem;
        font-family: inherit;
        &::before {
          content: " ";
          display: table;
          background: #ccc;
          width: 30%;
          height: 1px;
          position: relative;
          margin-top: 20px;
          float: left;
          @media screen and (max-width: $screen-fx-max) {
            width: 20%;
          }
        }
        &::after {
          content: " ";
          display: table;
          background: #ccc;
          width: 30%;
          height: 1px;
          position: relative;
          margin-top: 20px;
          float: right;
          @media screen and (max-width: $screen-fx-max) {
            width: 20%;
          }
        }
      }
    }
  }
}

body.page-flag-lists-add-product {
  #flag-lists-form {
    #edit-submit, #edit-cancel {
      background: $bd_pink;
    }
  }
}

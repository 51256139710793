.view-id-bdtv {

  .view-content {

//    @include span-columns(12 omega, 12);
    text-align: center;
    
    .views-row {
//      @include at-breakpoint($tab) {
//        @include span-columns(4, 12);
//        @include nth-omega(3n);
//      }

      margin-bottom: 20px;

      img {
        height: auto;
      }

    /*  .field--name-field-image .field__item a {
        position: relative;
        display: block;

        &::after {
          position: absolute;
          font-family: FontAwesome;
          content: "\f01d";
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto auto;
          height: 50px;
          width: 50px;
          font-size: 50px;
          opacity: .5;
        }
      }*/

      .views-field-title {
        background: #fff;
        padding: 10px;
        font-weight: 300;
        text-align: center;
        min-height: 70px;
      }
    }
  }
}

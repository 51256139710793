.page-directory-pr-resources, .page-directory-media, .page-node-1041087 {

  h1 {
    width: 100%;
    text-align: center;
//    @include line-through(#000);
    margin-bottom: 60px;
  }

  .l-header {
    display: none;
  }

  .l-main {

    .view-header {
//      @include pie-clearfix;
      margin-bottom: 20px;
      text-align: center;
    }

    .item-list {

      ul {
        padding: 20px 0 0;
        margin: 0;    

        li {
          position: relative;
//          @include grayscale(100%);
          transition: 0.5s;


          &:hover {
//            @include grayscale(0%);
          }

          .views-field-name {
//            @include absolute-center;
            padding: 10px;
            height: 45px;
            width: 200px;
            background: rgba(#000, .7);
            color: #fff;
          }

          .field--name-field-image {

            width: 100%;
            height: 100%;
            
            img {
              width: 100%;
              height: 100%;
            }
          }

          $grid-padding: 20px;
//          @include span-columns(1, 4);
          height: 200px;
          width: 24%;
          float: left;
          margin-right: 0.9%;
          border: solid thin $border-color;
          margin-bottom: 40px;
          text-align: center;
          &:nth-child(4n+0) {
            margin-right: 0;
          }
        }
      }
    }
  }
}


#block-views-upcoming-events-block-2 {

  .date-heading {
    display: none;
  }

  .view-header {
    position: absolute;
    width: 100%;
    top: 38px;
  }

  .view-content {

    > h3 {

      &:first-child {
//        @include clear-divider(top);
      }

//      @include sub-heading;
      font-weight: normal !important;
      border-bottom: 0 !important;
      margin-bottom: 0px;
//      @include divider(10px, $border-color, top);
    }
  }

  .more-link {
    margin-top: 20px;
//    @include more-link();
    width: 100%;
    text-align: center;
  }

  .views-field-title {
    display: inline-block;
    margin-right: 20px;
  }

  .views-field-city {
    display: inline-block;
  }

  .views-field-postal-code {
    display: inline-block;
    margin-left: 5px;
  }

  .views-field-field-event-start-date {
    display: inline-block;
    font-weight: 300;
    color: #aaa;
    text-transform: uppercase;
    margin-left: 10px;
    float: right;

    > span, > div {
      display: inline-block;
    }
  }

  margin-bottom: 60px;
  position: relative;

  h2.block__title {
//    @include box-title($bh-blue, #fff);
    margin-bottom: 20px;
    float: left;
//    @include pie-clearfix;
  }

  .pager {
    position: absolute;

    top: -50px;

//    @include at-breakpoint($tab) {
//      top: 0px;
//    }

    right: 0;

    li a {
      color: #000;
      background: #eee;
      border: none;
      padding: 8px 12px;

      &:hover {
        border: none;
      }
    }
  }
  
  .block__content {
    clear: both;
  }

  .views-row {
    margin-bottom: 10px;
  }
}

body.page-event-calendar {
  section#block-quicktabs-beautydiary-home-page {
    #quicktabs-beautydiary_home_page {
      @media screen and (min-width: 980px) {
        display: none;
      }
    }
  }
  .view-upcoming-events {
    .view-header {
      @media screen and (max-width: 979px) {
        display: none;
      }
    }
    .view-content {
      .calendar-calendar {
        @media screen and (max-width: 979px) {
          display: none;
        }
      }
    }
  }
}

body.front, body.page-event-calendar {
  section#block-quicktabs-beautydiary-home-page {
    .view-display-id-block_4 {
      &::after {
        content: '';
        clear: both;
      }
      .view-footer {
        margin-left: -15px;
        margin-right: -15px;
        &::before {
          content: '';
          clear: both;
          display: table;
        }
      }
      position: relative;
      .view-content {
        position: relative;
        width: 95%;
        /* display: inline-block; */
        float: left;
        max-width: 100%;
      }
      ul.pager {
        &::before {
          content: '';
          clear: both;
        }
        @media screen and (max-width: $screen-xs-max) {
          display: none;
        }
        display: block;
        float: right;
        position: relative;
        width: 5%;
        height: 100%;
        min-height: 500px;
        z-index: 99999;
        padding-left: 15px;
        li.pager-previous {
          top: 20%;
          position: absolute;
          &::before {
            content: '\f106';
            font-family: FontAwesome;
            display: block;
            position: absolute;
            font-size: 3em;
            z-index: -1;
          }
          a {
            padding: 0;
            border: 0px;
            padding: 10px;
            height: 0px;
            display: block;
            background: #000000;
            background: none;
            text-indent: -9999999999px;
            &:hover {
              background: none;
              border: 0px;
            }
          }
        }
        li.pager-next {
          bottom: 35%;
          position: absolute;
          &::before {
            content: '\f107';
            font-family: FontAwesome;
            display: block;
            position: absolute;
            font-size: 3em;
            z-index: -1;
          }
          a {
            padding: 0;
            border: 0px;
            padding: 10px;
            height: 0px;
            display: block;
            background: #000000;
            background: none;
            text-indent: -9999999999px;
            &:hover {
              background: none;
              border: 0px;
            }
          }
        }
        li.pager-current {
          display: none;
        }
      }
      ul {
        li.list-group-item {
          margin-left: -15px;
          margin-right: -15px;
          border: 1px solid #ddd;
          border-left: 0px;
          border-right: 0px;
          border-radius: 0px;
          border-top-right-radius: 0px;
          border-top-left-radius: 0px;
          padding-top: 0px;
          padding-bottom: 0px;

          &::before {
            content: " ";
            display: table;
          }
          &::after {
            content: " ";
            display: table;
            clear: both;
          }
          .views-field-field-event-start-date-1 {
            text-align: center;
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: left;
            line-height: 32px;
            letter-spacing: 2px;
            font-weight: bold;
            span.date-left {
              max-width: 80px;
              display: block;
              text-align: center;
            }
            @media screen and (max-width: $screen-xs-max) {
              max-width: 100px;
            }
          }
          .date-display-single {

            text-align: left;
            display: block;
           // font-family: 'latoheavy';
            letter-spacing: 2px;
            font-size: inherit;
            font-style: normal;
            color: $link_hover_color;
            padding-top: 15px;
            padding-bottom: 5px;
            font-weight: bold;
          }
          span.city-title {
            text-transform: capitalize;
            text-align: left;
            display: block;
            //font-family: 'latoheavy';
            letter-spacing: 2px;
            font-style: normal;
            /*padding-top: 5px;
            padding-bottom: 5px;*/
            font-weight: bold;
            h5 {
              margin-top: 0px;
              margin-bottom: 0px;
            }
            a {
              text-transform: none;
              &:link {
                color: $link_color;
              }
              &:visited {
                color: $link_visited_color;
              }
              &:hover {
                color: $link_hover_color;
                text-decoration: none;
              }
              &:active {
                color:$link_active_color;
              }
            }
          }
          span.city {
            text-align: left;
            display: block;
            letter-spacing: 2px;
            font-size: inherit;
            font-style: normal;
            padding-top: 5px;
            padding-bottom: 5px;
          }
          span.event-link {
            a {
              /*text-transform: uppercase;*/
              text-align: center;
              display: block;
              //font-family: 'latoheavy';
              padding-top: 3em;
              padding-bottom: 5px;
              text-decoration: underline;
              font-weight: bold;
              &:hover {
                text-decoration: none;
                color: $bd_pink;
              }
            }
          }
        }
      }
    }
    h2.block-title {
      font-style: normal;
      text-align: center;
      padding-top: 1em;
      padding-bottom: 2em;
      text-transform: uppercase;
      //font-size: 40px;
      font-family: inherit;

      &::before {
        content: " ";
        display: table;
        background: #ccc;
        width: 30%;
        height: 1px;
        position: relative;
        margin-top: 20px;
        float: left;
        @media screen and (max-width: $screen-fx-max) {
          width: 20%;
        }
      }

      &::after {
        content: " ";
        display: table;
        background: #ccc;
        width: 30%;
        height: 1px;
        position: relative;
        margin-top: 20px;
        float: right;
        @media screen and (max-width: $screen-fx-max) {
          width: 20%;
        }
      }
    }
    ul.quicktabs-tabs {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 60px;
      padding-left: 0px;
        li {
        background: none;
        border: none;
        a {
          font-size: 14px;
          font-weight: bold;
          padding: .5em 1em;
          &:hover {
            color: $pitch;
          }
        }
      }
      li.active {
        a {
          color: $pitch !important;
        }
      }
    }
  }
  .more-link {
    a {
      color: #ffffff !important;
      background: #000000;
      text-align: center;
      display: block;
      max-width: 200px;
      padding: 15px;
      text-decoration: none;
      text-transform: uppercase;
      margin: 3em auto;
      font-size: 0.8em;
      font-weight: bold;

      &:hover {
        background: $pitch;
      }
    }
  }
}

body.page-event-calendar {
  .view-display-id-block_4 {
    .view-footer {
      @media screen and (max-width: 979px) {
        display: none!important;
      }
    }
  }
}

.node--search.node--brand--search {

  height: 300px;

  .node__title {
    position: static;
    padding: 40px 10px 0px !important;
    width: 100%;
    height: auto;
    z-index: 9999999;
    background: rgba(255,255,255,0.5);
  }

  .field--name-field-logo-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    margin: auto auto;
    overflow: auto;
    
    img {
      position: absolute;
      height: auto;
      max-width: 85%;
      top: 0 !important;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto auto;
    }
  }
}

/*
 * SEARCH AUTOCOMPLETE
 *
 * Sponsored by:
 * www.axiomcafe.fr
 */

/**
 * THEME bd-theme
 *
 * 1- duplicate this file
 * 2- rename this "your theme".css
 * 3- Change all instance of bd-theme by your-theme
 * 4- Change #73CDDC by the color you like
 */


/* AUTOCOMPLETE */


.ui-theme-bd-theme.ui-autocomplete {
  position: absolute;
  cursor: default;
}
* html .ui-theme-bd-theme.ui-autocomplete {
  width: 1px; /* without this, the menu expands to 100% in IE6 */
}
/*.ui-theme-bd-theme.ui-autocomplete-input {
  background-image: url("images/throbber.gif");
  background-position: 100% 4px;
  background-repeat: no-repeat;
  display: none;
}
.ui-theme-bd-theme.ui-autocomplete-loading {
  background-image: url("images/throbber.gif");
  background-position: 100% -16px;
  background-repeat: no-repeat;
  display: none;

}*/

/* AUTOCOMPLETE MENU */
#ui-theme-bd-theme div {
  display: block;
}
#ui-theme-bd-theme.ui-menu {
  list-style: none;
  padding: 0px;
  margin: 0;
  display: block;
  float: left;
  min-width: 350px;
  position: absolute;
  background-color: #fff;
  border: 1px solid $border-color;
  box-shadow: 2px 2px 2px -1px rgba(128,128,128,0.6);
  z-index: 99999;
  top: 0;
  left: 0;

}

#ui-theme-bd-theme.ui-menu .ui-menu-item {
  margin: 0;
  float: left;
  clear: left;
  width: 100%;
  line-height: 14px;
  font-size: 12px;
  border-bottom: solid thin $border-color;
  border-top: none;
}

#ui-theme-bd-theme.ui-menu .ui-menu-item a {
  text-decoration: none;
  display: block;
  padding: 0;
  font-weight: 300;
  margin: -1px;
}

/* Interaction States */
#ui-theme-bd-theme .ui-state-hover {
  background: none;
  border: none;
  border-radius: 0px;
  background-color: #73CDDC;
  color: #FFFFFF;
}
#ui-theme-bd-theme .ui-autocomplete-fields {
  padding: 5px 6px;
  overflow: hidden;
}
#ui-theme-bd-theme .ui-menu-item-first {
  border-top: 1px solid #73CDDC;
}
#ui-theme-bd-theme .ui-autocomplete-field-title {
  text-align: left;
  font-size: 1.3em;
  margin-top: 10px;
  margin-bottom: 5px;
}

#ui-theme-bd-theme .ui-autocomplete-field-type {
  font-weight: normal;
//  padding-bottom: 10px;
  font-size: 0.7em;
  text-transform: uppercase;
  color: #ccc;
}

.ui-autocomplete-field-field_logo_image, .ui-autocomplete-field-field_product_image {
  float: right;
  max-height: 50px;
  max-width: 100px;
  height: auto;
  width: auto;
}

#ui-theme-bd-theme ul {
  background: #f7f7f7 !important;
}

div.ui-autocomplete-fields {
  border-left: solid 5px #fff;
}

div.ui-autocomplete-fields:hover {
  border-left: solid 5px #ddd;
}

.ui-helper-hidden-accessible {
  display: none;
}

.button {
  //width: 100%;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.1em;
  
  a {
    display: inline-block;
    background: $pitch;
    padding: 14px;
    width: 100%;
    
    &:hover {
      opacity: 0.9;
      text-decoration: none;
    }
  }
}

.bd_btn {
  min-width: 300px;
  height: 50px;
  padding: 10px;
  margin-bottom: 10px;

  display: inline-block;
  margin-right: 20px;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  color: #000;
  background-color: #fff;

  font-size: 20px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  
  &:hover {
    text-decoration: none;
  }

  &:nth-last-of-type {
    margin-right: 0;
  }

  span {
    font-weight: bold;
  }
}

// Hide the toolbar or admin menu on small screens.
@media only screen and (min-width: 768px) and (max-width: 991px){
  #admin-menu {
    display: none !important;
  }
  .contextual-links-region {
    position: static;
  }

  html {
    body.toolbar, body.admin-menu {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }
  }
}

.not-logged-in #block-tb-megamenu-main-menu {
  display: block;

//  @include at-breakpoint($tab) {
//    display: block;
//  }
}

#block-tb-megamenu-main-menu {
  position: absolute;
  top: 0;
  left: 0;

//  @include at-breakpoint($tab) {
//    position: relative;
//  }
}

.not-logged-in {
  #block-tb-megamenu-main-menu {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
}


.media .tb-megamenu-row.row-fluid:nth-child(2) {
  background: black;
  border: solid thin #000 !important;
  padding-top: 0;

  .tb-megamenu-column-inner {
    border: none;
  }
}

.expert-panel {
  background: $bh-blue;
  color: #fff;
  a {
    color: #fff !important;
    &:hover {
    }
  }
}



.l-nav {
  overflow: visible;
  padding: 0px;
  width: 100%;
  transition: 0.2s;
  z-index: 99999999999;
  background: #fff;
//  margin-bottom: 30px;
//  padding-bottom: 5px;
//  box-shadow: #f3f3f3 0 6px 31px 2px;


}

.l-nav.fixed {
  top: -106px - $categories !important;
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 14px;
}

.l-region--navigation {

//  display: none;
//  position: absolute;
//  top: 2px;
//  left: 2px;

//  @include at-breakpoint($tab) {
//    display: block;
//    position: relative;
//  }
  margin-bottom: 0px !important;

}

.l-region--navigation.logo-spacing {
  margin-left: 200px;
}

#block-tb-megamenu-main-menu {
  margin-bottom: 0 !important;
  width: 100%;
  /*font-weight: 300;*/
  transition: 0.2s;

  &.small {
    margin-left: 200px;
  }
}

#block-views-megamenu-block {

  .view-header p {
    font-size: 1.5em;
    line-height: 0.3em;
  }
}

.view-megamenu {
  .attachment-before, .attachment-after, .view-content {
    .views-row {

      width: 200px;
//      @include span-columns(1, 4);
//      @include nth-omega(5);
      padding: 10px 0;
      position: relative;

      .views-field-title {
        height: 34px;
        display: table-cell;
        vertical-align: middle;
      }

      img {
        width: 100%;
        height: auto;
      }

      a {
        color: #222;
      }

      &:hover {
      }

      a:hover {
        text-decoration: none;
        color: #000;
      }
    }
  }
  .view-id-megamenu {
    float: left;
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
  }
}

.tb-megamenu {
//  @include bh-gradient-2();
  border: none;
  font-size: 15px;
  color: #000;
  text-transform: uppercase;

}

.tb-megamenu-submenu {
  width: 100%;
}

.tb-megamenu-column-inner {
  border: solid thin $border-color;
  border-top: none;
  padding: 0 !important;
  font-size: 14px;
  width: 100%;

  .attachment-before {
    .view-id-megamenu {
      position: relative;
//      @include divider(20px, #eee, right);

      &:last-child {
//        @include clear-divider(right);
        padding-right: 10px;

      }
      .view-header p {
      }

      .view-footer {
        position: absolute;
        bottom: 0;

        p a {
          padding: 5px 10px;
          background: #f7f7f7;
          width: 100%;
        }
      }
    }
  }

  .categories {
    float: left;
    background: #000;
    color: #fff;
    margin-right: 20px;
//    @include span-columns(1, 5);
    box-shadow: #ccc 6px 0 9px -4px;

    li {
      color: #fff !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      line-height: 10px;
      border-bottom: solid thin #333 !important;

      &:last-child {
        border-bottom: 0 !important;
        margin-bottom: 0 !important;
      }

      a {
        color: #fff !important;
        padding-left: 5px !important;
        text-transform: uppercase !important;
        font-size: 12px;
        font-weight: normal !important;

        &:hover {
          color: $bd_pink;
          font-weight: bold;
          @media (max-width: 979px) {
            color: #000!important;
          }

        }
      }
    }
  }
}

.tb-megamenu .btn-navbar {
  margin-bottom: 0;
}

.tb-megamenu .nav > li {
  padding: 0 16px;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

.tb-megamenu .row-fluid [class*="span"] {
  margin-left: 0 !important;
}

.tb-megamenu li.dropdown {
  a.dropdown-toggle {
    &:hover {
      color: $link_hover_color!important;
    }
  }
}

.tb-megamenu .nav > li > a {
  border-color: $nav-background;
  color: #333;
  text-decoration: none;
  padding: 15px 0px 13px;
  border-bottom: solid 2px #fff;
  border: 0px;
  font-weight: 500;
}

.tb-megamenu .nav > li > a:focus,
.tb-megamenu .nav > li > a:hover {
  /*color: $main-nav-hover;*/
  /*background: $main-nav;*/
  /*opacity: 1;
  background: darken($main-nav-hover, 5%);*/
  border-bottom: solid 2px $link_hover_color;
  color: $link_hover_color;;
}

.tb-megamenu .nav > .active > a,
.tb-megamenu .nav > .active > a:hover,
.tb-megamenu .nav > .active > a:focus {
  background-color: #fff;
  color: $link_hover_color;;
  border-bottom: solid 2px $link_hover_color;
}

.tb-megamenu .nav-collapse .nav > li.active > a:hover,
.tb-megamenu .nav-collapse .nav > li.active > a:focus {
  background-color: #fff;
  color: $link_hover_color;
  border-bottom: solid 2px $link_hover_color;
}

.tb-megamenu .row-fluid + .row-fluid {
  border-color: $main-nav;
}

.tb-megamenu .dropdown .caret {
  display: none !important;
  border-top-color: $main-nav !important;
  margin-left: 5px;
  margin-top: 6px !important;
}

.tb-megamenu .dropdown-menu {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  height: auto !important;
  margin-top: 2px;
}

.tb-megamenu .dropdown-menu a {
  white-space: normal !important;
}

.tb-megamenu .dropdown-menu .item-list li {
  //border-bottom: solid thin $border-color !important;
}

.tb-megamenu .dropdown-menu li > a {
  //border-top: 1px solid #444;
  padding: 10px 20px !important;
  text-shadow: none;
  font-weight: 500 !important;/* Medium */
  color: #333;
  display: block !important;

}

.tb-megamenu .dropdown-menu li:first-child > a {
  border-top: 0;
}

.tb-megamenu .navbar .nav > li > .dropdown-menu:before {
  /*display: none;*/
}

.tb-megamenu .navbar .nav > li > .dropdown-menu:after {
  /*display: none;*/
}

.tb-megamenu .dropdown-menu .disabled > a,
.tb-megamenu .dropdown-menu .disabled > a:hover {
  color: #999;
}

.tb-megamenu .dropdown-menu .disabled > a:hover {
  background-color: transparent;
}

.tb-megamenu .nav li.dropdown.open > .dropdown-toggle {
  background-color: #fff;;
  color: #aaa;
  border-color: $main-nav-border;
}

.tb-megamenu .nav li.dropdown.open > .dropdown-toggle {
  background-color: #fff;;
  color: #aaa;
  border-color: $main-nav-border;
}

.tb-megamenu .nav li.dropdown.active >  a.dropdown-toggle {
  color: $link_hover_color;
}

.tb-megamenu .nav li.dropdown.active > .dropdown-toggle {
  background-color: $main-nav-border;
  color: $main-nav;
  border-color: $main-nav-border;
}

.tb-megamenu .dropdown-submenu:hover > a, .tb-megamenu .dropdown-menu .active > a {
  background: none !important;
}

.tb-megamenu .nav > li.dropdown.open.active > a:hover,
.tb-megamenu .nav > li.dropdown.open.active > a:focus {
  color: $main-nav;
  background: none!important;
  border-color: $bh-blue;
  border-right-color: $main-nav-border;
}

.tb-megamenu .dropdown-menu {
  background-color: #fff;
  border: #ccc;
}

.tb-megamenu .dropdown-menu li > a {
  color: $main-nav;
  border-top: 1px solid #fff;
}

/*.tb-megamenu .dropdown-menu li > a:hover,
.tb-megamenu .dropdown-menu li > a:focus,
.tb-megamenu .dropdown-submenu:hover > a {
  background: none !important;
  color: #fff;
}*/

.tb-megamenu .nav li.dropdown.open > .dropdown-toggle,
.tb-megamenu .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #fff;
  border-color: #fff;
  color: $main-nav;
  border-bottom: inherit;
}

.tb-megamenu .mega-nav .mega-group > .mega-group-title,
.tb-megamenu .dropdown-menu .mega-nav .mega-group > .mega-group-title,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group > .mega-group-title {
  color: #ddd;
}

.tb-megamenu .tb-block {
  background: transparent;
  color: inherit;
}

.tb-megamenu .tb-block .block-inner {
  padding: 0;
}

.tb-megamenu .tb-block .block-title {
  background: inherit;
  color: #ddd;
}

.tb-megamenu .block-ct {
  background: inherit;
  color: inherit;
  padding: 0;
  /**zoom: 1;*/
}

.tb-megamenu .block-ct:before,
.tb-megamenu .block-ct:after {
  display: table;
  content: "";
  line-height: 0;
}

.tb-megamenu .block-ct:after {
  clear: both;
}

.tb-megamenu .tb-block ul.unstyled,
.tb-megamenu .tb-block ol.unstyled,
.tb-megamenu .tb-block ul.nav,
.tb-megamenu .tb-block ol.nav {
  margin-left: 0;
}

.tb-megamenu .block_menu .nav {
  margin-bottom: 20px;
}

.tb-megamenu .block_menu .nav > li {
  border-top: 1px solid #444;
}

.tb-megamenu .block_menu .nav > li > a {
  padding: 5px;
}

.tb-megamenu .block_menu .nav > li:first-child {
  border-top: 0;
}

.tb-megamenu .block_menu .nav > li:first-child > a {
  padding-top: 0;
  border-top: 0;
}

@media screen and (max-width: 480px){
  .tb-megamenu {
    button.btn {
      top: -7.8em!important;
    }
  }
}

@media (max-width: 979px) {
  section#block-tb-megamenu-main-menu {
    padding: 0px !important;
  }
  .navbar-collapse {
    padding: 0px;
  }
  .tb-megamenu {
    .collapse.in {
      padding-top: 8em!important;
      display: block!important;
    }
    background-image: none;
    background-color: inherit;
    position: relative;
    button.btn {
      position: absolute;
      right: 0px;
      top: -7em;
      z-index: 99999999;
    }
    .nav-collapse, .nav-collapse.collapse {
      position: absolute;
      top: -8.7em;
      width: 100%;
      margin: 0px;
      z-index: 9999999;
    }
  }
  #block-easy-breadcrumb-easy-breadcrumb {
    a.contextual-links-trigger {
      display: none;
    }
  }

  .tb-megamenu .nav-collapse,
  .tb-megamenu .nav-collapse .dropdown-menu {
    background-color: white;
  }
  .tb-megamenu .nav-collapse .nav > li > a,
  .tb-megamenu .nav-collapse .dropdown-menu a {
    border-bottom-color: #999;
    border-top-color: #999;
    color: #000;
  }
  .tb-megamenu .nav > li > a {
    color: #444;
    font-family: 'latomedium';
    font-size: 1.3em;
    letter-spacing: 2px;
    border: 0px !important;
    &:first-child {
      border-left: solid thin rgba(255, 255, 255, 0.2);
    }
  }
  ul.tb-megamenu-subnav {
    li.tb-megamenu-item {
      border: 0px;
      a {
        border: 0px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px){
  .tb-megamenu {
    button.btn {
      right: 15px;
    }
  }
}
@media (max-width: 738px) {
  .tb-megamenu .dropdown-menu .item-list li {
    border: 0px !important;
  }

  .tb-megamenu .nav-collapse .nav li a {
    border: 0px;
  }
  .tb-megamenu .nav-collapse .nav > li > a,
  .tb-megamenu .nav-collapse .dropdown-menu a {
    border-bottom: none;
    border: 0px;
    color: #000;


  }
  .tb-megamenu .row-fluid + .row-fluid {
    border-top-color: #444;
  }
}

.block-view-demo .views-field-field-image img {
  border: 1px solid #ccc;
}

.block-view-demo .views-field-title a:hover,
.block-view-demo .views-field-title a:focus {
  color: $main-nav;
  min-height: 35px
}

.tb-megamenu .mega-align-justify > .dropdown-menu {
  margin-top: 0px;
}
li.active {
  .view-megamenu .attachment-before .views-row a,
  .view-megamenu .attachment-after .views-row a,
  .view-megamenu .view-content .views-row a {
    /*font-size: 14px;
    font-style: normal;
    font-weight: 300;*/
  }
}
body.view-nz-content {
  li.pr {
    display: none!important;
  }
}

.node-teaser {
  .flag-outer {
    top: -18px !important;
  }
}

.flag-outer {
  border: solid thin #fff;
  background: #fff;
  color: $border-color;
  font-size: 30px;
  position: absolute;
  top: 14px;
  right: 10px;
  transition: 0.2s;
  -web-kit-transition: 0.2s;
  -moz-transition: 0.2s;
  z-index: 9;
  overflow: visible;

  &.flag:hover {
    color: #000;
  }

  .unflag-action {
    color: #000;
  }
}

.flag-throbber {
  display: none;
}

.flag-outer:hover {
  background: #fff;
  border-color: #fff;
  color: #000;
  a {
    text-decoration: none;
  }
}

.flag-message {
  width: 400px;
  height: 90px;
  font-size: 16px;
  text-align: center;
  border: solid thin $border-color;
  background: #fff;
  color: #555;
  box-shadow: rgba(100, 100, 100, .3) 0 0 10px;
  position: fixed;
  bottom: 0px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto auto;
  padding: 20px;
  background: #fff;

  &:before {
    content: "";
    background: url("/sites/all/themes/bd/images/logos/brand-initials-small.png");
    float: left;
    height: 32px;
    width: 32px;
//    @include animate-spinner();

  }

}

.form-item-field-bookmark-category-und-select {
  > label[for=edit-field-bookmark-category-und-select] {
    margin-bottom: 10px;
//    @include sub-heading;
  }
  
  #edit-field-bookmark-category-und-select {
    > div {
      
    }
  }
}

.links.links--inline.node__links {
  display: none;
}

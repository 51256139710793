#block-views-portfolio-hub-views-block-10 {

  .view-content {

    .node--search {
      height: 350px;
    }

    .views-row {
      width: 46%;
      margin-right: 3%;
      display: inline-block;
      float: none;
      margin-bottom: 10px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

@import "../../node_modules/susy/sass/susy"; // Susy needs to be < version 3 to work with mixins

$grid-padding: 0;


.view-content {
  
  @include container(70em);
  
  .view-header {
    border-bottom: none;
    padding: 0;
  }
  
  .pager {
    display: none;
  }

  .cards {
//    @include span-columns(4, 12);
//    @include nth-omega(3n);
  }
}

.node-type-portfolio-hub {

  .l-main {
    margin-top: 80px;
  }

  .views-more {
    display: block;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
  }


  #block-views-portfolio-hub-views-block-6 {

    h2 {
      margin-bottom: 20px;
    }

    .views-row {
//      @include span-columns(1, 3);
//      @include nth-omega(3n);
      margin-bottom: 10px;

      .views-field-title {
        background: #fff;
        padding: 10px;
        font-weight: 300;
        text-align: center;
        min-height: 70px;
      }
    }
  }

  .l-region--sidebar-second {

    h2.sub-heading {
//      @include sub-heading;
      margin-bottom: 20px !important;
    }

    #block-views-portfolio-hub-views-block-4 {
      .view-portfolio-hub-views {
        margin-left: 0px;

        .views-row {

//          @include clearfix;
          margin-right: 3%;
          display: inline-block;
          width: 46%;
          border: solid thin $border-color;
          margin-bottom: 20px;
          padding: 20px 10px 0px;
          text-align: center;
          box-shadow: #eee 0 0 20px inset;
          min-height: 200px;
          
          &:nth-child(2n) {
            margin-right: 0;
          }

          div {
            text-align: center;
            display: inline-block;
          }

          .field--name-field-logo-image {
            margin-bottom: 10px;
            width: 100px;
            height: 100px;
            position: relative;

            img {
              max-height: 100px;
              max-width: 100px;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              margin: auto auto;
            }
          }

          .views-field-title {
            font-size: 1em;
          }
        }
      }
    }
  }
}

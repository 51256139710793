.node--media--teaser {

  margin-bottom: 20px !important;
  padding-bottom: 0px !important;

  .content {
//    @include span-columns(10 omega, 12);

    img {

    }
  }


  div {
    font-weight: 300;
  }

  .submitted {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
  }
}

@mixin card() {
  float: left;
  margin: 0 3% 20px 0;
  width: 31%;
  height: auto;
  overflow: auto;
  border-radius: 2px;
  position: relative;
  background: #fff;
  
  &.last {
    margin-right: 0;
  }
}

.card {
  
  @include card();
  .views-field-created {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    background: rgba(0, 0, 0, 0.7);
    margin: 0 20%;
    padding: 5px 5%;
    /* display: inline-block; */
    text-align: center;
    font-size: 12px;
    color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .views-field-title {
    margin: 10px 10px 20px;
    text-align: center;
    height: 35px;
    position: relative;
    overflow: hidden;
    
    a:hover {
      text-decoration: none;
    }
    
    &::after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30%;
      height: 1.09091em;
      background: -moz-linear-gradient(left,rgba(255,255,255,0),#ffffff 50%);
      background: -webkit-linear-gradient(left,rgba(255,255,255,0),#ffffff 50%);
      background: linear-gradient(to right,rgba(255,255,255,0),#ffffff 50%);
    }
  }
  
  img {
    height: auto;
    
    &:hover {
      opacity: 0.9;
    }
  }
}

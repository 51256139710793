section#block-quicktabs-features-video {
  @media screen and (max-width: $screen-sm-max) {
    .img-responsive {
      width: 100%;
    }
  }
  margin-top: -20px;
  ul.quicktabs-tabs {
    max-width: 600px;
    margin: 0 auto;
    li {
      padding-left: 30px;
      @media screen and (max-width: 441px) {
        padding-left: 10px !important;
      }
      a {
        border: 1px solid;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        width: 200px;
        display: inline-block;
        &:link {
          color: $link_color;
        }
        &:visited {
          color: $link_visited_color;
        }
        &:hover {
          text-decoration: none;
          color: $link_hover_color;
          border-color: $link_hover_color;
        }
        &:active {
          color: $link_active_color;
          border-color: $link_active_color
        }
      }
    }
    li.active {
      a {
        color: $link_active_color;
        border-color: $link_active_color;
      }
    }
  }
  .view-content {
    max-width: 100%;
    margin-top: 57px;
    .views-field-title {
      a {
        &:link {
          color: $link_color;
        }
        &:visited {
          color: $link_visited_color;
        }
        &:hover {
          text-decoration: none;
          color: $link_hover_color;
        }
        &:active {
          color: $link_active_color;
        }
      }
      margin-bottom: 37px;
      padding: 20px 20px 20px 0px;
      
    }
  }
}

.node-type-article {

  .field__label {
//    @include sub-heading;
  }
  .field--name-field-related-content {
    margin-top: 40px;
    overflow: auto;
    width: 100%;
  }
}

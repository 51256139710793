.node--pr-staff--full {

//  @include span-columns(12, 12);

  .media-content {

//    @include span-columns(10 omega, 12);
    padding-left: 20px;
  }

  .field-label {
    min-width: 80px;
    display: inline-block;
  }

  .field--name-field-email {
    .field-items {
      display: inline-block;
    }
  }

  .field--name-field-image {
//    @include span-columns(2, 12);
    img {
      height: auto;
    }
  }

  h3 {
    margin-top: 20px;
  }

  ul.field--name-field-brand {
    padding: 0;
    margin: 0;
    // @include vendor-prefix(column-count, 3);

    li {
      color: #999;
      font-weight: 300;

      a {
        color: #000;
        text-decoration: underline;
      }
    }

  }

    width: 100%;
    float: left;
    padding: 20px;
    border-bottom: solid thin #eee;
    min-height: 110px;
  }

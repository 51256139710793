.view-latest-articles, .view-latest-articles-2 {

  .attachment-before {
    @include clearfix;
    margin-bottom: 20px;
    border-bottom: solid thin #ddd;
    padding-bottom: 0px;
  }
  .views-field-field-image {
    float: left;
    margin-right: 10px;
    img {
      width: 75px;
      height: 75px;  
    }
  }
  p {
    font-size: 0.8em;
    color: #000;
    margin: 0 0 5px;
  }
  
  .view-display-id-attachment_2 {
    @include divider(5px, $border-color, top);
    .views-row {
      @include divider(5px, $border-color, bottom);
      padding-top: 8px;
    }
  }
  
  .views-row {
    margin-bottom: 10px;
  }
  .attachment-after {
    margin-top: 40px;
    .views-row {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: solid thin #ddd;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .view-latest-articles-2 {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.view-latest-articles {
  .views-row-odd {
      padding: 10px;
      background: #eee;
  }
  .views-row-odd:nth-child(3) {
      padding: 10px;
      background: $bh-blue;
      h3, p {
        color: #fff;
      }
      h5 {
        display: none;
      }
      
  }
  
}

aside {
  padding: 0px;
  .well {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    background: none;
    border: 0px;
    border-radius: 0px;
    @media screen and (min-width: 1300px) {
      padding: 0px;
    }
    h2 {
      color: $link_hover_color;
      text-transform: uppercase;
      font-style: normal;

      font-size: 1.5em;
    }
    .view-footer {
      .views-row {
        padding-bottom: 1em;
        a {
          &:link {
            color: $link_color;
            text-decoration: none;
          }
          &:visited {
            color: $link_visited_color;
            text-decoration: none;
          }
          &:hover {
            color: $link_hover_color;
            text-decoration: none;
          }
          &:active {
            color: $link_active_color;
            text-decoration: none;
          }
        }
      }
    }
    article {
      h2 {
        &:before, &:after {
          display: none!important;
        }
      }
    }
  }
}
aside.col-sm-3 {
  @media screen and (min-width: 1300px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    .field-collection-view {
      margin: 0px;
    }
  }
}

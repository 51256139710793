html {
  font-size: 18px;
  color: #333333;
  body {
    font-family: 'Gill Sans Nova';
    font-weight: 400; /* Medium */
    font-size: 1rem;
  }
  p {
    font-family: 'Gill Sans Nova';
    font-weight: 500; /* Medium */
    font-size: 1rem;
    color: #333333;
  }
  b {
    font-weight: 600; /* Semi Bold */
  }
  a {
    font-family: 'Gill Sans Nova';
    font-weight: 500;
    font-size: 0.8rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  h1 {
    font-family: 'FreightBig Pro';
    font-weight: 700; /* Bold */
    font-size: 3.052rem;
  }
  h2 {
    font-family: 'Gill Sans Nova';
    font-weight: 500; /* Medium */
    font-size: 1.25rem;
    letter-spacing: 0.1em;
    a{
      font-family: 'Gill Sans Nova';
      font-weight: 500; /* Medium */
      font-size: 1.25rem;
      letter-spacing: 0.1em;
    }
  }
  h3 {
    font-family: 'FreightBig Pro';
    font-style: italic;
    font-weight: 500; /* Medium */
    font-size: 1.953rem;
    letter-spacing: normal;
    a {
    font-family: 'FreightBig Pro';
    font-style: italic;
    font-weight: 500; /* Medium */
    font-size: 1.953rem;
    letter-spacing: normal;
  }
}
  h4 a {
    font-family: 'Gill Sans Nova';
    font-size: 1.266rem;
    letter-spacing: normal;
    text-transform: none;
  }
  h5 {
    font-family: 'Gill Sans Nova';
    font-size: 1.125rem;
    letter-spacing: 0.075em;
    a {

      font-family: 'Gill Sans Nova';
      font-size: 1.125rem;
      letter-spacing: 0.075em;
    }
  }
}

p {
  line-height: 1.5em;
  /*font-weight: 300;
  font-size: 1.05em;*/
  color: #333;

  a {
    text-decoration: underline;
  }
}


li {
  line-height: 1.5em;
  list-style: none;
}

.field__items {
  font-weight: 300;
}
.field .field-label {
  font-weight: 600;
}
//#{headings(all)} {
//  margin: 0px 0 5px;
//  color: #222;
//  font-family: 'Lato', sans-serif;
//  color: $headline;
//  font-weight: 300;
//
//  a:hover {
//    color: #000;
//    text-decoration: none;
//  }
//}

//#{headings(1,2)} {
//  color: #000;
//}


h1 span {
  display: block;
  font-weight: bolder;
  font-size: 0.5em;
  // needs latest Compass, add '@import "compass"' to your scss
  /*@include filter-gradient(#34b5d0, #007db9e8, horizontal); // IE6-9*/
  // @include background-image(linear-gradient(left, rgba(#000, 1) 0%, rgba(125, 185, 232, 0) 100%));
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  padding: 6px 12px 6px;
  margin-top: 5px;
}

.l-main h1 {
  margin-bottom: 20px;
//  border-bottom: solid thin $bh-blue;

}

h1:not(a) {

  /*font-size: 2em;*/
//  @include at-breakpoint($tab) {
//    font-size: 3em;
//  }
}

h3 {
  // font-weight: bold;
}

a {
  text-decoration: none;
  color: inherit;
  /*font-weight: bold;*/
  &:hover {
    text-decoration: none;
  }
}

.emphasised {
  font-style: italic;
  color: $text-highlight;
}

.block__title {
  @include sub-heading;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on October 4, 2014 */



@font-face {
  font-family: 'latothin';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-thin-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-thin-webfont.eot?#iefix') format('embedded-opentype'),
  url('//sites/all/themes/custom/beautydirectory/fonts/lato/lato-thin-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-thin-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-thin-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-thin-webfont.svg#latothin') format('svg');
  font-weight: normal;
  font-style: normal;
}




@font-face {
  font-family: 'latosemibold';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibold-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibold-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibold-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibold-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibold-webfont.svg#latosemibold') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'latothin_italic';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-thinitalic-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-thinitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-thinitalic-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-thinitalic-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-thinitalic-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-thinitalic-webfont.svg#latothin_italic') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Lato';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-regular-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-regular-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-regular-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-regular-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-regular-webfont.svg#latoregular') format('svg');
  font-weight: 400;
  font-style: normal;

}




@font-face {
  font-family: 'latomedium_italic';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-mediumitalic-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-mediumitalic-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-mediumitalic-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-mediumitalic-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-mediumitalic-webfont.svg#latomedium_italic') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'latosemibold_italic';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibolditalic-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibolditalic-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibolditalic-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibolditalic-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-semibolditalic-webfont.svg#latosemibold_italic') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'latomedium';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-medium-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-medium-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-medium-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-medium-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-medium-webfont.svg#latomedium') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Lato';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-lightitalic-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-lightitalic-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-lightitalic-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-lightitalic-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-lightitalic-webfont.svg#latolight_italic') format('svg');
  font-weight: 300;
  font-style: italic;

}




@font-face {
  font-family: 'latoheavy_italic';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavyitalic-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavyitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavyitalic-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavyitalic-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavyitalic-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavyitalic-webfont.svg#latoheavy_italic') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'latoheavy';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavy-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavy-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavy-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavy-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavy-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-heavy-webfont.svg#latoheavy') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Lato';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-italic-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-italic-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-italic-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-italic-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-italic-webfont.svg#latoitalic') format('svg');
  font-weight: normal;
  font-style: italic;

}




@font-face {
  font-family: 'latohairline_italic';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairlineitalic-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairlineitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairlineitalic-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairlineitalic-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairlineitalic-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairlineitalic-webfont.svg#latohairline_italic') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Lato';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-light-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-light-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-light-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-light-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-light-webfont.svg#latolight') format('svg');
  font-weight: 300;
  font-style: normal;
}




@font-face {
  font-family: 'Lato';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bold-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bold-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bold-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bold-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bold-webfont.svg#latobold') format('svg');
  font-weight: 700;
  font-style: normal;

}




@font-face {
  font-family: 'latohairline';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairline-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairline-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairline-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairline-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairline-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-hairline-webfont.svg#latohairline') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'latobold_italic';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bolditalic-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bolditalic-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bolditalic-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bolditalic-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-bolditalic-webfont.svg#latobold_italic') format('svg');
  font-weight: 700;
  font-style: italic;

}




@font-face {
  font-family: 'latoblack_italic';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-blackitalic-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-blackitalic-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-blackitalic-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-blackitalic-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-blackitalic-webfont.svg#latoblack_italic') format('svg');
  font-weight: 900;
  font-style: italic;

}




@font-face {
  font-family: 'Lato';
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-black-webfont.eot');
  src: url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-black-webfont.woff2') format('woff2'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-black-webfont.woff') format('woff'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-black-webfont.ttf') format('truetype'),
  url('/sites/all/themes/custom/beautydirectory/fonts/lato/lato-black-webfont.svg#latoblack') format('svg');
  font-weight: 900;
  font-style: normal;

}

.search-result-category {
//  @include clearfix;
}

.page-search {

  .search-form .form-item label {
    display: none;
  }
  
  #block-views-exp-find-page--2 {
    position: absolute;
    top: 9px;
    right: 0;
    
    div {
      width: auto;
    }
    
    #edit-submit-find {
      @include button(5px, $bh-blue, #fff, $bh-dark-blue, #fff);
      margin-top: 0;
    }
    
    #edit-search-api-views-fulltext {
      color: #666;
    }
  }
  
  .l-region--sidebar-first {
    .block__title {
      text-transform: none;
      margin-bottom: 10px;
      background: #f7f7f7;
      color: #000;
      padding: 16px 10px;
      font-weight: bold;
      font-size: 18px !important;
      /*border: solid thin #eee;*/
    }
    
    ul {
      padding-left: 8px;
    }
    li {
      color: #666;
      list-style: none;
      font-weight: 300;
      &.leaf {
        margin-bottom: 2px;
      }
    }
    .item-list {
      margin-top: 10px;
      border-top: solid thin #dddddd;      
      padding-top: 10px;
    }
    
    .block--facetapi {
      > div {
        margin-left: solid thin $border-color;
      }
    }
    
    .search-api-ranges-widget {

      input[type=submit] {
        margin-top: 20px;
      }

      .range-box {
        float: left;
        input[type=text] {
          width: 60px;
          padding: 20px 10px;
          margin-right: 5px;
        }
      }
    }
  }
}


// Default variables.
@import '../../../bd-sass/bd-variables';

header {
	&.container {
		@media #{$wide} {
			max-width: $wide-screen-lg !important;
		}
	}
}
@media screen and (max-width: 768px) {
	header.container-fluid {
		.container-fluid {
			.l-region--branding {
				max-width: 300px;
				float: left;
				a.site-logo {
					img {
						padding-top: 15px;
					}
				}
			}
			.navbar-collapse {
				border: 0px;
			}
			nav {
				border: 0px;
				.tb-megamenu {
					button {
						position: absolute;
						top: -65px;
						right: 10px;
						z-index: 1000;
					}
				}
			}
			> .navbar-header {
				display: block !important;
				float: none;
				button, a {
					display: none;
				}
			}
			.navbar-header::after {
				border-bottom: 1px solid #eeeeee;
				height: 10px;
				display: block !important;
			}
			.tb-megamenu {
				.nav-collapse {
          position: absolute;
					top: -65px;
					right: 0px;
					display: none;
					.nav {
						> li {
							> a, > a:hover, > a:focus, > a:active {
								border: 0px;
							}
						}
					}
				}
				}
				.bd-sub {
//					position: absolute;
//					top: -65px;
//					right: -9999px;
				}
		}
	}
}

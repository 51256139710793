.node--contact--teaser {

  h2 {
    font-style: normal;
  }

  ul {
    padding: 0;
    margin: 10px 0 0;

    li {
//      @include tags;
    }
  }
}

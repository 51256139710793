#block-views-beauty-today-block-2, #block-views-beauty-today-block-1 {

  text-align: center;
  background: #fff;
  padding: 0px 0px 120px;
//  box-shadow: 0px -3px 12px #ddd;
//  box-shadow: #ccc 0 0 10px inset;
  background: #f5f5f5;
  padding: 20px;

  h2.block__title {
//    @include box-title($bh-blue, #fff);
    text-align: left;
  }

  .views-row {
    margin-bottom: 10px;
   // background: #eee;
    padding: 10px 0 0 0;
//    border: solid thin $border-color;

    h2.field-content {
      font-size: 1.5em;
      border-right: none;
      border-left: none;
      border-top: none;
      width: 100%;
      font-style: normal;
      color: #000;
      text-transform: uppercase;
      padding: 5px 10px;
      font-weight: normal;
      margin-bottom: 10px;
      line-height: 1.4em;
    }
  }

  .views-field-body {

    p {
      font-size: 0.9em;
      margin-top: 0;
      font-weight: 300;
      padding: 10px;
      padding-top: 0;
      padding-bottom: 0;
      color: #000;
    }
  }

  .views-field-field-image {
    margin: 5px 15px 10px;

    img {
      height: auto;
    }
  }

  .views-field-nothing {
//    @include line-through(#000);
    color: #000;
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 5px;
  }
}


.node--search {
  margin: 0 0 10px 0;
  width: 99.9%; // make sure to get the right border in
  border-radius: 2px;
  position: relative;
  background: #fff;  
  border: solid thin $border-color;
  font-size: 0.8em;
  height: 400px;
  overflow: hidden;
  
  .type {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    font-size: 0.9em;
    color: #fff;
    background: #000;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 4;
  }
  
  .type.article {
    background: rgb(255, 89, 89);
  }
  
  .type.event {
    background: yellow;
  }
  
  .type.brand {
    background: $alt-color-2;
  }
  
  .type.product {
    background: $bh-blue;
  }
  
  .node__submitted {
    position: relative;
    padding: 10px;
    font-size: 0.8em;
  }
  
  .node__title {
    position: relative;
    top: 0;
    left: 0;
    padding: 30px 10px 0px;
    width: 100%;
    height: auto;
    z-index: 2;
  }
  
  .field--name-body {
    padding: 0 10px 10px;
  }
}


/*#qt-brand_info-ui-tabs1 {*/
/*  .type {*/
/*    display: none;*/
/*  }*/
/*}*/

/*.l-region--recently-read {*/
/*  .views-row {*/
/*    width: 200px;*/
/*    margin-right: 20px;*/
/*    transition: 0.2s;*/
/*    z-index: 99999;*/
/*    */
/*    &:nth-child(3n+0) {*/
/*      margin-right: 20px;*/
/*    }*/
/*    */
/*    &:hover {*/
/*      margin-top: -100px;*/
/*    }*/
/*  }*/
/*}*/

body.page-search, body.page-search-site {
  .col-sm-9 {
    article {
      h2 {
        text-align: left;
        padding-top: 0;
        padding-bottom: 0;

        &::before, &::after {
          display: none;
        }
      }
       .node-product {
         .icon-downloads-wrapper, .field-type-entityreference {
           display: none;
         }
         .col-xs-12.col-sm-6.col-md-4.col-lg-4 {
           width: 25%;
         }
         .col-sm-12.col-md-8.col-lg-8 {
           width: 75%;
         }
       }
      .node-event, .node-brand {
        .content {
          width: 75%;
          float: left;
          padding-left: 15px;
          padding-right: 15px;
        }

        .field--name-field-image, .field-name-field-logo-image {
          width: 25%;
          float: left;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    .col-sm-9 {
      @media screen and (max-width: $screen-xs-max) {
        width: 50%;
        float: left;
      }

      h2 {
        text-align: left;
        padding-top: 0;
        padding-bottom: 0;

        &::before, &::after {
          display: none;
        }
      }

      .submitted {
        margin-top: 1em;
        font-style: normal;
        text-transform: uppercase;
        font-family: 'latomedium';
      }
    }
    .col-sm-3 {
      @media screen and (max-width: $screen-xs-max) {
        width: 50%;
        float: left;
      }
    }

    .submitted {
      margin-top: 1em;
      font-style: normal;
      text-transform: uppercase;
      font-family: 'latomedium';
    }
  }
  article {
    .node-article {
      margin-bottom: 3em!important;
    }
  }
  .col-sm-3 {
    ul.facetapi-facetapi-checkbox-links {
      li {
        clear: both;
        position: relative;
        input[type="checkbox"] {
          float: left;
          max-width: 30px;
        }
        label {
          float: left;
          padding-top: 10px;
          text-transform: uppercase;
          font-weight: normal;
        }
        input[type="radio"], input[type="checkbox"] {
          margin-right: 1.5em;
          margin-bottom: 15px;
        }
        a.facetapi-checkbox {
          font-family: 'latomedium';
          text-transform: uppercase;
        }
      }
    }
  }
}

.admin-menu-search {
  .admin-menu-search {
    @media screen and (max-width: $screen-xs-max) {
      display: none;
    }
  }
}

section#block-search-form {
  max-width: 100%;
  _width: 100%;
  padding: 0px;
  .search-form-container {
    .input-group {
      display: flex;
      margin-top: 0px;
      @media screen and (max-width: 979px) {
        margin-top: 50px;
      }
      input[type=text] {
        height: 63px;
        border: solid thin #000;
      }
      .input-group-btn {
        position: absolute;
        right: 0;
        z-index: 2;
        .btn {
          background: #000000;
          height: 63px;
          width: 80px;
          border-radius: 0px;
          border: solid thin #000000;
          padding-top: 0px;
          .glyphicon-search {
            font-size: 3rem;
            &::before {
              content: "\f105";
              font-family: FontAwesome;
            }
          }
        }
      }
    }
  }
}


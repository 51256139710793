.node-type-portfolio-hub {

  .l-region--sidebar-second {
    padding: 0px;

    .view-display-id-attachment_3 {
//      @include social-media-icons-2();
      padding-bottom: 80px;
    }

    .node-teaser {
//      @include clear-divider(bottom);
      padding-bottom: 40px !important;

      .field--name-field-phone {
//        @include divider(10px, $border-color, bottom);
      }
    }

    .view-id-portfolio_hub_views.view-display-id-block_2 {
      margin-left: 20px;
      margin-right: 20px;

      .view-display-id-attachment_3 {
        margin-top: 40px;
      }

      .attachment-after {
        margin-top: 40px;
      }
    }

    .block__title {
//      @include box-title($bh-blue, #fff);
      margin-bottom: 20px;
    }
  }
}

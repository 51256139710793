.l-region--mid-leaderboard {
    
  .mid-leaderboard {
    color: #000;
    width: 100%;
//    @include divider(20px, $border-color, bottom);
    /*@include divider(20px, $border-color, top);*/
    margin-top: 0;
    font-size: 18px;
    text-align: center;
    img {
      height: auto;
    }
    
    
    .block__title {
        border-bottom: solid thin $border-color;
        border-top: solid 3px $bh-dark-blue;
        background: #f7f7f7;
        padding: 9px 10px 9px;
        margin-top: 0;
        margin-bottom: 20px;
        color: $bh-blue;
    }
    
    .view-content > div {
      margin-bottom: 30px;
    }
  }

  #block-views-41ceb72ed8fea5a0d7fc8864b1005759 {
//    @include tile();

    .block__title {
        // @include ribbon();
        margin-left: -1%;
        width: 102%;
        margin-top: 10px;
        background: #000;
        padding: 9px 10px 9px;
        margin-bottom: 0px;
        color: #fff;
    }
    
    .block__content {
      padding-top: 20px;
      overflow: auto;
    }
  }
  
  .view-content > div {
    margin-bottom: 30px;    
  }

  
  #block-views-41ceb72ed8fea5a0d7fc8864b1005759 {
    margin-bottom: 20px;
    
    @include clearfix;
    .view-content > div {
      text-align: center;
      float: left;
      width: 22%;
      margin-left: 3%;
      padding-right: 3%;
      border-right: solid thin $border-color;
      position: relative;
      
      h4 {
        position: absolute;
        top: 0;
        background: #fff;
        left: 20px;
        right: 20px;
        margin: 0 auto;
      }
      
      &:last-child {
        border-right: none;
      }
      
      img {
        height: auto;
      }
    }
  }
}

// this is the container for the large preview image on hover
//.large-image-container {
//  background: rgba(#fff, 0.9);
//  position: fixed;
//  overflow: hidden;
//  top: 0px;
//  display: none;
//  z-index: 99999;
//
//  &::before {
//    content: "\f1ce";
//    z-index: -1;
//    font-family: fontAwesome;
//    -webkit-animation: spin 2s infinite linear;
//    -moz-animation: spin 2s infinite linear;
//    -o-animation: spin 2s infinite linear;
//    animation: spin 2s infinite linear;
//    @include absolute-center;
//    height: 25px;
//    width: 25px;
//    color: #ccc;
//  }
//
//  img {
//    @include absolute-center();
//    max-height: 70%;
//    max-width: 100%;
//    border: solid thin $border-color;
//    padding: 20px;
//    background: #fff;
//  }
//}

.section-brands {

  fieldset {
   border-color: $border-color;

    .fieldset-title {
      color: #666;
    }
  }

  .ui-tabs-nav {

    position: relative;
    top: auto;
    right: auto;


//    @include at-breakpoint($tab) {
//      position: absolute;
//      top: 0;
//      left: 0;
//      right: auto;
//      width: auto;
//    }

    li {
      margin-bottom: 10px;
      
      a {
        width: 100%;
        display: inline-block;
      }
    }

    font-size: 1.3em;

    li.ui-state-active a {
      background: #fdfdfd;
      box-shadow: 0px 0px 5px #ddd inset;
      color: #333;
    }

    li:first-child {
//      @include at-breakpoint($tab) {
//        padding-left: 0;
//      }
    }

    a {
//      box-shadow: 1px 1px 1px #ddd;
//      box-shadow: 0px 0px 5px #ddd;
      padding: 8px 9px;
      border: solid thin #f3f3f3;
    }
  }

  .node--brand--full {
    // create a separate space for the scroll-bar
   // padding-right: 15px;
    .flag-outer {
      top: -11px;
    }
  }

  #block-quicktabs-brand-info {


    .views-exposed-form {
      //padding: 10px 20px;
      // Rpadding-bottom: 0;
//      margin-bottom: 30px;
//      border-bottom: double 2px $border-color;

      .views-widget {
        margin-top: 0px;
        background: #fff;
//        width: 45%;
//
//        input {
//          width: 100%;
//        }
      }

      .views-widget-sort-sort_bef_combine {
        display: none;
      }

      label {
       // padding-bottom: 30px !important;
        font-size: 16px !important;
        border-bottom: none !important;
      }

      select {
        width: 40%;
      }
    }

    > .view-content {
      clear: both;
      margin-top: 50px;
      background: #fff;
    }

    .form-item-items-per-page {
      label, div {
        float: left;
        width: auto;
      }
      .bef-select-as-links {
        margin-top: 10px;
      }
    }

    .form-type-bef-link {
//      @include span-columns(1, 2);
      margin-bottom: 5px;
      font-size: 1em;
      color: #999;
      font-weight: 300;
      text-transform: uppercase;
      float: left;
      margin-right: 20px;
      border: solid thin $border-color;

      //    &:first-child {
      //      width: 100%;
      //      text-align: center;
      //    }

      .bef-secondary-options {

        .form-item {
          clear: both;

          input {
            width: 40%;
          }
        }

      }

      a {
        display: inline-block;
        padding: 2px 5px;
        width: 100%;
        &.active {
          background: #fff;
          color: #000;
        }
      }

    }
  }

  .l-main {
    .l-region--sidebar-first {
      overflow: visible;
      box-shadow: none;

      .view-content {
        background: #fff;
        clear: both;
      }

      .views-row {

        img {
          margin: auto auto;
        }
      }
    }

    h1 {
      text-align: center;
//      @include line-through(#000);

      span {
        font-size: 0.8em;
      }
    }
  }
}

.node-type-brand {

  .field--name-field-target-market {
    display: none;
  }
  .view-filters {
    /*display: none;*/
    margin-bottom: 20px;
  }

  #block-quicktabs-brand-info .block__title {
    visibility: hidden;
    margin-bottom: 30px;
    border-bottom: none;
  }

  .l-main {
    margin-top: 00px;
  }

  .l-region--sidebar-first {
    /*margin-top: -64px;*/
    margin-top: 0px;
    padding-top: 5px !important;
    // overwrite the normal shadow with more top
    box-shadow: #eee 10px -6px 30px;

    .views-row {
//      @include clear-divider(bottom);
    }
  }

  .l-content {

    //    font-size: 1.05em;

    .field--name-field-email {
      .field-item {
        word-wrap: normal;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .field--name-field-logo-image {
      border: solid thin $border-color;
      position: relative;
      height: 300px;
      width: 100%;
      margin: 0px 0 40px;

      img {
        //        padding: 20px;
//        @include absolute-center();
      }
    }

    .field-content {
      font-weight: 300;
    }

    .field__label, .block__title, h3.field-label {
//      @include sub-heading;
      border-bottom: 0;
      //      border-top: solid thin $border-color;
      padding-top: 20px;
      font-weight: normal;
      margin-bottom: 0px;
    }

    .field-collection-view {
      h3:first-of-type {
        border-top: none;
      }
    }

    .non-visible {
      visibility: hidden;
    }

    .fixed-title {
//      @include sub-heading;
      position: fixed;
      top: 70px;
      background: #fff;
      z-index: 9;
      width: 265px;
    }

    .field--label-above {
      margin-bottom: 20px;
      font-weight: 300;
    }

    .views-row {

      &::after {
        content: " ";
        width: 50%;
        margin: 10px 25%;
        text-align: left;
        display: block;
//        @include divider(5px, #fff, bottom);

      }

      &:last-child::after {
        content: none;
//        @include clear-divider(bottom);
      }
    }

    .node__content > div:not(.field--name-field-logo-image), .view {

      margin-bottom: 40px;

    }

    .field--name-field-social-media {

//      @include social-media-icons-2();

      .field__item {
        float: left;
      }
    }
  }

  .l-main {
    margin-top: 10px !important;

    .view-header {
      font-size: 13px;
      font-weight: initial;
      color: #999;
      font-style: italic;
      margin-bottom: 20px;
      margin-left: 0px;
//      position: absolute;

    }
  }

  .l-content {
    overflow: hidden;
    height: 100%;
  }

  #block-views-brand-products-block {
    position: relative;
  }

  .field-collection-container, #block-views-brands-block-6, #block-views-brands-block-5, #block-views-brands-block-7  {
    padding: 0px 20px 10px;
    background: #fcfcfc !important;

    h2.node__title {
      font-size: 1.6em;
      font-style: normal;
    }
  }

  #block-views-brands-block-1 {
    .views-row {
      font-size: 0.8em;
      color: #666;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: solid thin $border-color;
    }
    .block__title {
      background: $bh-blue;
      color: #fff;
      padding: 7px 10px;
      margin-bottom: 16px;
      border-radius: 2px;
    }
  }
}
body:not(.node-type-brand)
{
  .extra-extras {
    display: none;
  }
}
body.node-type-brand {
  .view-views-list-brand-press-release {
    .product-content {
      //margin-top: 5em;
      padding-left: 35px;
    }
  }
  aside {
    #magical-modal-link {
      margin: 2em 0px;
    }
    .node-brand {
      .field-name-field-contact-categories {
        display: block;
        font-family: 'FreightBig Pro';
        font-style: italic;
        font-weight: 500; /* Medium */
        font-size: 1.953rem;
        letter-spacing: normal;
        margin-bottom: 0px;
        color: #333;
        padding-bottom: 10px;
        border-bottom: 0;
      }
      .flag-outer.flag-outer-brand-favourite {
        top: inherit;
        position: relative;
        right: 0px;
        i.far {
          color: $bd_pink;
          &::before {
            font-size: 4rem;
            content: "\f004";
            font-family: FontAwesome;
            color: #F7796D;
            font-style: normal;
          }
        }
      }
      article.node-contact {
        header {
          h2 {
            text-align: left;
            font-size: 1em;
            padding: 0px;
            margin: 0px;
            padding-bottom: 5px;
            a {
              &:link {
                color: $link_color;
              }
              &:visited {
                color: $link_visited_color;
              }
              &:hover {
                color: $link_hover_color;
              }
              &:active {
                color: $link_active_color;
              }
            }
          }
        }
      }
      .field-collection-container {
        h3.field-label {
          display: block;
          color: #333;
          padding-bottom: 10px;
          border-bottom: 0;
          padding-top: 20px;
          margin-bottom: 0px;
          width: 100%;
          font-weight: 500; /* Medium */
        }
        .field--name-field-social-media {
          a {
            background: #cccccc;
            color: #ffffff;
            margin: 0px 3px;
            padding: 5px 10px;
            &:hover {
              background: $link_hover_color;
            }
          }
        }
      }
      .field-name-field-logo-image {
        border: solid thin #cccccc;
        /* padding: 6em 1em; */
        /* margin-bottom: 2em; */
        position: relative;
        margin: 0px 0 40px;
        height: 300px;
        width: 100%;
        img {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          margin: auto auto;
          max-width: 100%;
          display: block;
        }
      }
    }
    #magical-modal-link {
      a {
        background: $bd-brand;
        color: #ffffff;
        text-decoration: none;
        text-transform: uppercase;
        padding: 1em;
        font-size: 0.8em;
        &:hover {
          background: $link_hover_color;
        }
      }
    }
  }
  section.col-sm-9 {
    @media screen and (max-width: $screen-fx-max) {
      width: 100%!important;
    }
    #block-views-brands-brand-image {
      .field-name-field-logo-image {
        max-width: 150px;
        margin-top: 3em;
      }
    }
    .view-footer {
      margin-bottom: 2em;
      .footer-back {
        text-align: right;
        span {
          a {
            text-decoration: none;
            font-weight: bold;
            &:link {
              color: $link_color;
            }
            &:visited {
              color: $link_visited_color;
            }
            &:hover {
              color: $link_hover_color;
            }
            &:active {
              color: $link_active_color;
            }
          }
        }
      }
    }
    #block-quicktabs-brand-info {
      .ui-widget-content {
        border: 0px;
        padding-left: 0px;
        #edit-field-categories-tid-selective-wrapper {
          max-width: 140px;
          margin-left: 0px;
          background: #fff;
          display: block;
          position: relative;
          padding-top: 4px;
          label {
            display: none;
          }
          select.form-control {
            border: 0px;
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            -webkit-transition: none;
            -moz-transition: none;
            transition: none;
          }
        }
        ul.ui-tabs-nav {
          left: 20px;
          border: none;
          background: none;
          li {
            border: none;
            background: none;
            a {
              &:link {
                color: $link_color;
              }
              &:visited {
                color: $link_visited_color;
              }
              &:hover {
                color: $link_hover_color;
              }
              &:active {
                color: $link_active_color;
              }
              @media screen and (max-width: $screen-fx-max) {
                width: 100%;
                text-align: center;
              }
            }
          }
          li.ui-tabs-active a {
            color: $link_active_color;
          }
        }
        .view-brands {
          margin-top: 3em;
          .view-content .views-row  {
            .node-event .content h2 a,  .node-article .content h2 a {
              &:link {
                color: $link_color;
              }
              &:visited {
                color: $link_visited_color;
              }
              &:hover {
                color: $link_hover_color;
              }
              &:active {
                color: $link_active_color;
              }
            }
          }
        }
        .view-brand-products {
          @media screen and (max-width: 979px) {
            .node-product {
              .product-content {
                float: none!important;
              }
            }
          }
          @media screen and (max-width: $screen-sm-max) {
            .download-links.col-sm-12.col-md-3.col-lg-3 {
              width: 25%;
              float: left;
            }
          }
          @media screen and (min-width: $screen-sm-mins) and (max-width: $screen-sm-max) {
            .product-image.product-content.col-xs-12.col-sm-6.col-md-4.col-lg-4 {
              width: 33.33333%;
            }
            .col-sm-12.col-md-8.col-lg-8 {
              width: 66.66667%;
            }
          }
          .product-content {
            .group-products-details {
              float: none;
              width: 100%;
              margin-right: 0;
              padding: 0px;
              span.field-label {
                font-weight: normal;
                min-width: 70px;
              }
                ul.field-name-field-rrp-nz, ul.field-name-field-rrp-aud, ul.field-name-field-packsize-nz,
                ul.field-name-field-packsize-au, ul.field-name-field-packsize {
                  display: inline-block;
                  width: 300px;
                  padding-left: 0px;
                  display: flex;
                  display: -webkit-flex;
                  li {
                    display: inline-flex;
                    display: -webkit-inline-flex;
                    margin-right: 5px;
                  }
                  //@media screen and (max-width: 480px) {
                  //  width: 150px;
                  //}
                }
            }
            .brand-content-links {
              @media screen and (max-width: $screen-xs-max) {
                margin: 0px;
                .ctools-use-modal, .other {
                  //display: none;
                }
              }
              margin: 3em 0px;
              .download-links.col-sm-12.col-md-4.col-lg-4 {
                padding-left: 0px;
                padding-right: 0px;
                width: 34%;
                @media screen and (max-width: $screen-lg-mins) {
                  width: 100%;
                }
                .prss {
                  margin-left: 10px;
                  @media screen and (max-width: $screen-lg-mins) {
                    margin-left: 0px;
                  }
                }
                .prsss {
                  margin-left: 15px;
                  @media screen and (max-width: $screen-lg-mins) {
                    margin-left: 0px;
                  }
                }
                a {
                  text-transform: uppercase;
                  border: solid thin #000000;
                  padding: 10px;
                  font-size: 10px;
                  font-family: 'latomedium';
                  letter-spacing: 2px;
                  &:visited {
                    border: solid thin $link_visited_color;
                    color: $link_visited_color;
                    text-decoration: none;
                  }
                  &:hover {
                    border: solid thin $link_hover_color;
                    color: $link_hover_color;
                    text-decoration: none;
                  }
                  &:active {
                    border: solid thin $link_active_color;
                    color: $link_active_color;
                    text-decoration: none;
                  }
                  @media screen and (max-width: $screen-lg-mins) {
                    display: block;
                    margin-top: 1em;
                    text-align: center;
                  }
                }
              }
            }
            .owl-controls {
              position: absolute;
              top: 30%;
              width: 100%;
              .owl-buttons {
                position: relative;
                .owl-prev {
                  position: absolute;
                  left: 0px;
                  z-index: 11;
                  background: none;
                  color: #000;
                  font-size: 1.5em;
                  opacity: 1;
                  .fa:hover {
                    color: $pitch;
                  }
                }
                .owl-next {
                  position: absolute;
                  right: 0px;
                  z-index: 11;
                  background: none;
                  color: #000;
                  font-size: 1.5em;
                  opacity: 1;
                  .fa:hover {
                    color: $pitch;
                  }
                }
              }
            }
            .owl-wrapper-outer {
              z-index: 0 !important;
              img {
                margin: 0 auto;
              }
            }
            .field-name-field-product-image {
              img {
                margin: 0 auto;
              }
            }
            .icon-downloads-wrapper {
              max-width: 150px;
              margin: 0 auto;
              position: relative;
              margin-top: 15px;
              margin-bottom: 15px;
              z-index: 1;
              @media screen and (max-width: $screen-fx-max) {
                z-index: 0;
              }
              .download-links {
                .fa {
                  color: $link_hover_color;
                  position: absolute;
                }

                .fa-download {
                  top: 0px;
                }
                .fa-envelope, .fa-thumb-tack {
                  a {
                    position: absolute;
                    display: block;
                    width: 20px;
                    height: 15px;
                    left: 0px;
                    top: 0px;
                  }
                }
                .fa-inverse {
                  color: $link_active_color;
                }

                .flag-outer {
                  top: 0px !important;
                  right: inherit;
                  font-size: inherit;
                  border: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: $screen-fx-max + 1) {
    .mobile-filters, .is-active-mobi {
      display: none;
    }
  }

  @media screen and (max-width: $screen-fx-max) {
    aside {
      display: none;
      width: 100%!important;
      .field-name-field-logo-image {
        .img-responsive {
          margin: 0 auto;
        }
      }
    }
    .section-is-active-info {
      display: none;
    }
    .l-main {
      padding-left: 0px;
      padding-right: 0px;
    }
    .is-active-info {
      .brand-info {
        background: #000;
        bottom: 0px;
        color: #fff;
        right: 0px;
        left: 0px;
        height: 49px;
        ul {
          li {
            text-indent: -9999px;
            &::before {
              content: 'X  <  Brand Information';
              display: block;
              position: absolute;
              color: #fff;
              cursor: pointer;
              left: 50px;
              text-indent: 0px !important;
              top: 13px;
            }
          }
        }
      }
    }
    .is-active-product-info {
      #edit-field-categories-tid-selective-wrapper {
        .views-widget {
          top: -102px !important;
          z-index: 20 !important;
          left: 130px !important;
          width: 36em !important;
        }
      }
    }
    section#block-quicktabs-brand-info {
      ul.ui-tabs-nav {
        display: none;
        left: 0 !important;
        border: none;
        background: #fff !important;
        z-index: 1 !important;
        //top: -45px;
        overflow: visible !important;
        height: 100%;
        li {
          float: none;
          top: 0px;
          left: 0px;
          width: 100%;
          background: #fff!important;
        }
      }
      #edit-field-categories-tid-selective-wrapper {
        .views-widget {
          display: none;
          position: absolute;
          left: 6px;
          select.form-control {
            text-transform: uppercase;
          }
        }
      }
    }
    .hides {
      ul.ui-tabs-nav {
        height: auto!important;
      }
    }
    #block-views-brands-brand-image {
      .view-id-brands {
        .field-name-field-logo-image {
          margin: 0 auto !important;
          margin-bottom: 2em !important;
          margin-top: 2em !important;
        }
      }
    }

    .brand-info {
      display: none;
      position: absolute;
      z-index: 1;
      left: -25px;
      width: 100%;
      background: #ffffff;
      ul {
        li {
          width: 100%;
          cursor: pointer;
          text-transform: uppercase;
          font-size: 13px;
          font-family: 'latoheavy';
          letter-spacing: 2px;
          text-align: center;
          padding-top: 2em;
          &.is-active-info {
            display: none;
          }
        }
      }
    }
    .footer-back {
      display: none;
    }
    //
    //.views-exposed-widgets {
    //  display: none;
    //}
    .mobile-filters {
      color: $bg;
      background: $bd-brand;
      text-transform: uppercase;
      text-align: center;
      padding: 0px 35px;
      cursor: pointer;
      position: relative;
      .icon-box {
        display: flex;
        justify-content: flex-end;
        float: none;
        font-size: 2.5em;
        background: #000;
        height: 43px;
        padding-right: 35px;
        cursor: pointer;
        z-index: 1;
        text-align: right;
        margin-top: -35px;
        padding-right: 80px;
      }
      .editorial, .overview, .oassets, .pressr, .brandex, .eventstab {
        padding-top: 15px;
        z-index: 9999999;
        display: none;
        position: absolute;
        padding-left: 100px;
        width: 100%;
        text-align: left;
        height: 43px;
        padding-right: 35px;
        cursor: pointer;
      }
      .mobile-menu-mobi {
        max-width: 100%;
        margin: 0 auto;
        display: block;
        span {
          background: #000000;
          margin: 3px 0;
          float: left;
          &.icon-bar-filter {
            width: 86px;
            margin-top: 15px;
            margin-bottom: 15px;
          }
          &.icon-bar {
            width: 15px;
            height: 3px;
          }
        }
        .line {
          -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
          transition: all .25s cubic-bezier(.645, .045, .355, 1);
          -webkit-transition-timing-function: cubic-bezier(.645, .045, .355, 1);
          transition-timing-function: cubic-bezier(.645, .045, .355, 1);
          background: #fff;
          border-radius: 3px;
          -moz-border-radius: 3px;
          -webkit-border-radius: 3px;
        }
        .line-1 {
          float: right;
          margin-top: 5px;
          margin-left: 0px;
          transform: translateY(20px) translateX(0) rotate(45deg);
          -webkit-transform: translateY(20px) translateX(0) rotate(45deg);
          -ms-transform: translateY(20px) translateX(0) rotate(45deg);
        }
        .line-2 {
          float: right;
          margin-top: 20px;
          margin-left: -40px;
          display: none;
        }
        .line-3 {
          float: right;
          margin-top: 15px;
          transform: translateY(10px) translateX(40px) rotate(135deg);
          margin-right: 14px;
        }
      }
      .is-active-mobi {
        span.line-1 {
          -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
          -ms-transform: translateY(10px) translateX(0) rotate(45deg);
          transform: translateY(10px) translateX(0) rotate(45deg);
          width: 15px !important;
          float: right;
          margin-top: 10px;
          margin-left: -15px;
        }
        span.line-2 {
          opacity: 0;
          float: right;
        }
        span.line-3 {
          -webkit-transform: translateY(-9px) translateX(0) rotate(-45deg);
          -ms-transform: translateY(-9px) translateX(0) rotate(-45deg);
          transform: translateY(5px) translateX(0) rotate(-45deg);
          margin-right: 1px;
          //width: 30px !important;
          //float: right;
          //margin-top: 10px;
        }
        .views-exposed-widgets {
          display: block;
        }
      }
    }
    //.view-footer {
    //  display: none;
    //}
  }
}

body.node-type-brand {
  section#block-quicktabs-brand-info {
    .view-brand-products {
      .select2-container .select2-choice {
        border: 0px;
        background: none;
        text-transform: uppercase;
        font-family: 'latoheavy';
        font-size: 13px;
        letter-spacing: 2px;
        border-radius: 0px;
        box-shadow: none;
        width: 200px;
        overflow: inherit;
        &:link {
          color: $link_hover_color;
        }
        @media screen and (max-width: $screen-fx-max) {
          width: 200px;
          color: #ffffff !important;
          margin-top: 20px;
          height: auto;
          .select2-search-choice-close {
            display: block!important;
            &::after {
              content: 'x';
              /* font-family: FontAwesome; */
              color: #fff;
              position: absolute;
              /* z-index: 999999999; */
              width: 80px;
              height: 60px;
              display: block;
              background: #000;
              font-size: 30em;
              padding-left: 35px;
              top: -10px;
            }
          }
        }
        .select2-chosen {
          margin-right: 0px;
          @media screen and (max-width: $screen-fx-max) {
            color: #ffffff !important;
          }
        }
        .select2-arrow {
          border: 0px;
          border-radius: 0px;
          background: none;
          display: none;
        }
      }
    }
    .view-display-id-block_3, .view-display-id-block_4 {
      .node-teaser {
        .col-sm-3 {
          width: 23.72881%;
          float: left;
        }
        .col-sm-9 {
          width: 74.57627%;
          float: right;
          margin-right: 0;
        }
      }
      .views-row {
        .node-event {
          .submitted, span.date-display-single {
            padding-left: 5px!important;
          }
          .field--name-field-image {
            width: 23.72881%;
            float: left;
          }
          .content {
            width: 74.57627%;
            float: right;
            margin-right: 0;
          }
        }
        width: 100%;
        //float: left;
        //padding-left: 15px;
        //padding-right: 15px;
        //@media screen and (max-width: $screen-xs-max) {
        //  width: 50%;
        //}
        .node-teaser {
          display: block;
          border-top: solid thin #eeeeee;
          /* min-height: 408px; */
          /* margin-bottom: 3em !important; */
          padding: 15px;

          .submitted, span.date-display-single {
            display: block !important;
            font-size: 1.25rem;
            text-align: left;
            color: #777;
            font-family: "Gill Sans Nova";

            padding: 0px 10px;

            font-style: normal;
            margin-bottom: 0px;
          }

          h2 {
            text-transform: uppercase;
            text-align: left;
            display: block;
            font-family: 'latoheavy';
            padding: 0px 10px 0px;
            letter-spacing: 2px;
            font-size: inherit;
            font-style: normal;
            margin-top: 10px;
            a {
              &:link {
                color: $link_color;
              }
              &:visited {
                color: $link_visited_color;
              }
              &:hover {
                text-decoration: none;
                color: $link_hover_color;
              }
              &:active {
                color: $link_active_color;
              }
            }
          }

          img {
            width: 100%;
            padding: 10px;
          }

          .field-type-text-long, .field-type-text-with-summary {
            font-family: 'Gill Sans Nova';
            padding: 12px;
            text-align: left;
            letter-spacing: 0.05em;
            color: #777;
            /* white-space: nowrap; */
            /* text-overflow: ellipsis; */
            overflow: hidden;
          }
        }
      }
    }
  }
  aside.col-sm-3 {
    width: 28.45528%;
    margin-right: 4.06504%;
  }
  section.col-sm-9 {
    width: 67.47967%;
  }
}
.top-logo {
  display: none;
  @media screen and (max-width: $screen-fx-max) {
    display: block;
  }
  .field-name-field-logo-image {
    max-width: 150px;
    margin: 15px auto;
  }
}
section#block-views-brands-brand-image, .region-sidebar-first {
  .field-name-field-logo-image {
    @media screen and (max-width: $screen-fx-max) {
      display: none;
    }
  }
}
.extra-extras {
  position: relative;
}

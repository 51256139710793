#block-quicktabs-portfolio-hobs {

//  @include divider(40px, $border-color, top);
  margin-bottom: 40px;

  ul.ui-tabs-nav {
    top: 48px;
  }

//  border: solid thick $border-color;
//  padding: 20px;

  margin-top: 20px;

  > .block__title {
    display: none;
  }

  .view-header h2 {
//    @include box-title($bh-dark-blue, #fff);
    float: left;
    margin-bottom: 25px;
  }

  .attachment.attachment-before {
    width: 70%;
    float: left;

    ul {
      padding: 0;
      margin: 0;

      li {
//        @include divider(5px, $border-color, bottom);
        font-weight: 300;
      }
    }
  }

  .view-display-id-block_7 > .view-content {
    width: 25%;
    float: right;
    text-align: center;
    margin-top: 10px;
  }

  .views-field-title {
    display: inline-block;
  }

  .field--name-field-primary-article-category {
    display: inline-block;

    .field__item {
//      @include tags;
      margin-bottom: -7px;
      margin-left: 8px;
      padding: 0 8px;
    }
  }

  .view-display-id-attachment_5 {

    img {
      height: 55px;
      width: auto;
    }

    ul {
      padding: 0;
      margin: 0;
      margin-top: 10px;
      float: left;

      li {
        div {
          float: left;
        }

        .views-field a {
          margin-left: 20px;
          float: left;
          padding: 16px;
          font-size: 14px;
          text-align: center;
          font-weight: 300;
          text-transform: uppercase;
          background: #eee;

          &:hover {
            text-decoration: none;
            background-color: $bh-blue;
            color: #fff;
          }
        }
      }
    }

  }
}

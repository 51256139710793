

select {
  background: #fff;
  width: 100%;
  padding: 7px 18px;
  color: #666;
  font-weight: 300;
  border: solid thin $border-color;
  font-size: 14px !important;
  font-weight: 300;
  width: 68%;
  color: #000;
}

hr {
  clear: both !important;
}

.user-register-form {

  select {

    width: 100%;

//    @include at-breakpoint($tab) {
//      width: 68%;
//    }
  }

  .form-actions {
    float: right;
  }
  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  .fieldset-legend {
    display: none;
  }
}

input[type=text], input[type=password], textarea {
  border: solid thin $border-color;
  padding: 0px 20px;
  font-size: 14px !important;
  height: 42px;
  font-weight: 300;
  width: 100%;

//  @include at-breakpoint($tab) {
//    width: 68%;
//  }
  color: #000;
  outline: none;
  &:focus {
      outline: none;
      border-color: $bh-blue;
  }
}

.not-logged-in.node-type-webform {
  textarea {
    padding: 10px;
  }

  .form-content {
    text-align: left !important;
  }

  .form-item label {
    float: none;
    margin-bottom: 20px;
  }

  .form-textarea-wrapper {
    width: 100% !important;
  }
}

.form-textarea-wrapper {
  width: 68% !important;
  display: inline-block;

  textarea {
    height: 300px;
  }
}

@mixin submitbutton($background-color, $color: #fff) {
  background: $background-color;
  color: $color;
  border: none;
  padding: 10px;

  &:hover {
    background: darken($background-color, 5%);
    text-decoration: none;
  }
}

.views-exposed-widget {
  width: 100%;
}

#edit-cancel {
  @include submitbutton(#ccc);
}

.not-logged-in .webform-submit {
  @include submitbutton($bh-blue);
}

#edit-submit {
  @include submitbutton($bh-blue);
  margin-right: 10px;
}

#edit-preview {
  @include submitbutton(#fff, #999);
}

.form-item label:not(.option), .form-actions label:not(.option) {
  display: block;
  font-weight: 300;
  float: left;
  padding-top: 10px;
  margin-right: 30px;
//  @include at-breakpoint($tab) {
//    min-width: 120px;
//  }

}

.form-item {
  position: relative;
//  display: inline-block;

  .description {
//    position: absolute;
//    right: 0;
//    top: 10px
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.views-exposed-form {

  margin-top: 0px;

  label:not(.option) {
    @include sub-heading;
    width: 100%;
    border-bottom: none;
  }



  .views-exposed-widget {
    margin-bottom: 5px;
  }

  .views-submit-button {
    margin-bottom: 0;
  }

  #edit-date-wrapper {

    select {
      padding: 7px 7px;
    }
    .form-item-date-value-month {
      select {
        width: 84px;
      }
    }
    .form-item-date-value-day {
      select {
        width: 60px;
      }
    }
    .form-item-date-value-year {
      select {
        width: 95px;
      }
    }
  }
}


// clear floats on radio buttons
.form-radios:after {
  content: " ";
  clear: both;
  display: table;
}

.view-nodequeue-5 {

  margin-bottom: 50px;

  .owl-item {
    position: relative;
    width: 150px;
    height: 75px;
    margin-right: 24px;
    margin-bottom: 15px;
//    border: solid thin #333;

    img {
      position: absolute;
      max-width: 150px;
      max-height: 70px;
      height: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto auto;
      /*-webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      filter: grayscale(100%);*/

    }
  }
}

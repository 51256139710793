body.page-pressrelease {
  .view-content {
    max-width: 100%;
  }
  .product-content {
    h2 {
      @include title();
      text-align: left;
      padding: 0px;
    }
    .fa {
      color: $bd_pink;
    }
  }
}

body.node-type-brand{
  .view-views-list-brand-press-release {
    .node-press-release {
      .product-content {
        .brand-content-links {
          margin-top: 10px;
          .fa {
            color: $bd_pink;
            a {
              display: block;
              cursor: pointer;
              position: absolute;
              z-index: 1;
              height: 20px;
              width: 20px;
              top: 0px;
            }
          }
          .fa-file-pdf {
            &::before {
              content: '\f1c1';
              font-family: FontAwesome;
            }
          }
          .col-sm-12.col-md-3.col-lg-3 {
            width: 5%;
            padding-left: 0px;
            @media screen and (max-width: $screen-xs-max) {
              width: 8%;
              float: left;
            }
          }
        }
      }
    }
  }
}

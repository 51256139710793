.node--brand--image {

  img {
    max-width: 150px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: auto;
  }
}

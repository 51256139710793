ul.ui-tabs-nav {
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 15px;
  text-transform: uppercase;
  
  li {
    height: 39px;
    
    &.ui-state-focus, &.ui-state-focus a {
      outline: none;
    }
    
    &.ui-tabs-active {

      a {
        color: $bh-blue;
      }
    }

//    @include at-breakpoint($tab) {
//      float: left;
//    }

    display: block;
    padding: 5px 10px;
    margin: 0;
    border-right-color: #666;
    border: none;
    font-size: 0.8em;
    
    a {
      color: #aaa;
      
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.node--industry-and-brand-news--full {
//  @include divider(20px, $border-color, bottom);

  .submitted {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
    color:#333;
  }
}

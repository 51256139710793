.l-footer-terms {
  z-index: 99;
  position: relative;
  background-color: #000;
  padding-top: 1px;
  font-size: 0.8em;
  font-weight: bold;
  .footer-terms {
    margin-bottom: 0 !important;
    padding: 20px 0;
		max-width: $site-width;
		margin: 0 auto;
    .terms {
      text-align: center;
      p {
        margin: 0;
        padding: 0;
        font-weight: 200;
        font-size: 0.889rem;
        letter-spacing: 0.05em;
        text-align: left;
        color: #fff !important;
      }
    }
    text-align: center;
      img {
        margin-top: 20px;
      }
    }
  }


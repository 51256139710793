#block-quicktabs-latest-products, #block-quicktabs-products-on-counter, #block-quicktabs-nz-products-on-counter,
#block-views-portfolio-hub-views-block-5  {
  position: relative;

    h2.block-title {
      font-style: normal;
      text-align: center;
      padding-top: 1em;
      padding-bottom: 2em;
      text-transform: uppercase;
      //font-size: 40px;
      font-family: inherit;
      &::before {
        content: " ";
        display: table;
        background: #ccc;
        width: 30%;
        height: 1px;
        position: relative;
        margin-top: 20px;
        float: left;
        @media screen and (max-width: $screen-fx-max) {
          display: none;
        }
      }
      &::after {
        content: " ";
        display: table;
        background: #ccc;
        width: 30%;
        height: 1px;
        position: relative;
        margin-top: 20px;
        float: right;
        @media screen and (max-width: $screen-fx-max) {
          display: none;
        }
      }
    }


  > .block__content {
    min-height: 220px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .ui-tabs-nav {

    left: 0;
    right: 0;
    margin: 0 auto;
    top: 90px;
    text-align: center;

    li {
      float: left;
    }
//
//    @include at-breakpoint($tab) {
//      top: 50px;
//      left: auto;
//    }
    z-index: 9999;
  }

  .owl-wrapper-outer {
    clear: both;
  }

//  @include divider(40px, $border-color, top);
  margin-top: 0;

  .views-field-title  {
    text-align: center;
    font-size: 0.9em;
    padding: 7px;
    font-weight: 300;
  }

  .views-field-field-product-image {
    min-height: 150px;
    position: relative;
    margin-bottom: 10px;

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .views-field-title-1 {
    text-align: center;

    a {
      font-size: 0.8em;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  .owl-carousel {
    .fa  {
      &::before, &::after {
        color: #000000;
      }
    }

//    @include owl-buttons;
  }

  .ui-widget-content {
    border: 0px;
    ul.ui-tabs-nav {
      position: relative;
      background: none;
      border: 0px;
      top: 0px;
      width: 296px;
      li {
        background: none;
        border: none;
        margin: 1px 5px 0 0;
        a {
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          display: block;
          /*width: 100px;*/
          padding: 0px;
          color: $link_color;
          &:hover {
            color: $link_hover_color;
          }
          &:active {
            color: $link_active_color;
          }
          &:visited {
            color: $link_visited_color;
          }
        }
        &.ui-tabs-active {
          a {
            color: $link_active_color !important;
          }
        }
      }
    }
    .owl-carousel {
      .owl-controls {
        position: absolute;
        top: 40%;
        width: 100%;
        .owl-buttons {
          .owl-prev {
            position: absolute;
            left: 0px;
            z-index: 10;
            font-size: 3em;
            background: none;
            color: #000000;
          }
          .owl-next {
            position: absolute;
            right: 0px;
            z-index: 10;
            font-size: 3em;
            background: none;
            color: #000000;
          }
        }
      }
      .owl-wrapper-outer {
        max-width: 100%;
      }
    }
    .view-content {
      max-width: 100%;
      .views-field-title-1 {
        padding: 0px 10px;
        a {
          text-align: center;
          display: block;
          font-weight: bolder;
          color: $link_color;
          &:hover {
            text-decoration: none;
            color: $link_hover_color;
          }
        }
      }
      .views-field-title {
        a {
          padding: 10px;
          display: block;
          text-align: center;
          font-size: 0.8em;
        }
        &:link {
          color: $link_color;
        }
        &:visited {
          color: $link_visited_color;
        }
        &:hover {
          color: $link_hover_color;
        }
        &:active {
          color: $link_active_color;
        }
      }
    }
    .ui-tabs-panel {
      padding: 0px;
    }
  }
}

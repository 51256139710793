.block--instagram-social-feed {
  margin-bottom: 15px;
}

.page-media-beautydirectory-statistics, .page-media-beautydirectory-statistics-nz {
  h1 {
    display: none;
  }
  .view-header {
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }
  .title-wrapper {
    text-align: center;
    position: relative;
    .line {
      border-bottom: 1px solid black;
      position: absolute;
      width: 100%;
      top: 29px;
    }
    .textbox {
      position: absolute;
      width: 100%;
      h1 {
        text-align: center;
        text-transform: uppercase;
        background-color: black;
        color: white;
        display: inline;
        overflow: hidden;
        font-size: 2.5em;
        font-style: normal;
        font-weight: bold;
        padding: 0 20px !important;
        margin: 0 auto;
        border: 10px solid white;
      }
    }
  }
  table {
    margin: 95px auto 0 12px;
    display: inline-table;
    td {
      display: inline-block;
      width: 23%;
      float: left;
      margin-right: 43px;
      min-width: 256px;
      max-width: 256px;
      .chart-wrapper {
        width: 100%;
        border: 1px solid black;
        display: block;
        margin-bottom: 26px;
        text-align: center;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        font-size: 1.6em;
        vertical-align: top;
        max-height: 496px;
        min-height: 496px;
        .circle {
          margin: 1rem auto !important;
          font-weight: bold;
        }
        .chart-subject {
          background-color: #000;
          color: #fff;
          padding: 10px 0;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 121%;
          overflow: hidden;
        }
        .chart-description {
          padding: 20px;
          padding-top: 0;
          font-size: 0.9em;
          font-weight: bold;
          line-height: 120%;
          min-height: 182px;
          max-height: 182px;
          overflow: hidden;
        }
        .chart-footer {
          width: 100%;
          background-color: #efefef;
          display: block;
          font-weight: normal;
          font-size: 37%;
          padding: 4px 0;
        }
      }
    }
    td.col-last {
      margin-right: 0;
    }
  }
}

// Stats block on homepage

.bd-stats-block {
  margin-bottom: 10px;
  .view-footer {
    float: right;
    margin-bottom: 10px;
    font-size: 90%;
  }
  .chart-wrapper {
    cursor: pointer;
    .circle {
      margin: 10px 10px 10px 0;
      float: left;
    }
    .chart-footer {
      font-size: 60%;
    }
    .chart-description {
      height: 83px;
      vertical-align: middle;
      display: table-cell;
      font-weight: bold;
    }
  }
}

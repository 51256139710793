#block-views-promoted-content-block {
  
  border: solid thin $border-color;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 10px;
  overflow: hidden;
  /*margin-top: 10px;*/
  /*margin-bottom: 0px;*/
  height: 80px;
  position: fixed;
  top: -80px;
  left: 0;
  background: #fff;
  width: 100%;
  z-index: 999;
  transition: 0.2s;
  box-shadow: 5px 5px 10px rgba(150,150,150,0.1);
  
  &.visible {
    top: 52px;
  }
  
  .owl-item {
    height: 100%;
    padding: 0 10px;
    border-right: solid thin $border-color;        
    overflow: auto;
    
    .views-field-field-primary-article-category {
      text-transform: uppercase;
      font-size: 0.7em;
      color: $bh-blue;
      margin-bottom: 0px;
    }
    
    .views-field-title {
      
      a {
        font-size: 0.9em;
        color: #000;
        font-weight: 300;
      }
    }
  
    .views-field-field-image {
      
      height: 100%;
      margin: 0 10px 0 0;
      float: left;

      img {
        height: 60px;
        width: 60px;
      }
    }
  }
}

.block--views-category-highlights-block-1, #block-views-jobs-block-3 {
  
  p {
    font-size: 0.8em;
    margin-top: 0;
  }

  .block__title {
//    @include block-heading($bh-blue);
 
  }

  .view-content > div {
    margin-bottom: 10px;
  }
  
//  @include clearfix;
  margin-bottom: 20px;
  position: relative;
  
    
    
  .views-row {
    
//    @include clearfix;
    width: 100%;
    
    img {
      margin-bottom: 10px;
      height: auto;
      width: 40%;
      float: left;
      margin-right: 4%;
      margin-top: 5px;
    }
  }

  .more-link {
//    @include more-link();
    top: 15px;
    right: 10px;
  }
}


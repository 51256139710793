@mixin owl-buttons() {

  .owl-item {
    text-align: center;
  }

  .owl-buttons {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 100%;
    height: 110px;

    .owl-prev, .owl-next {
      position: absolute;
      background: none;

      i {
        font-size: 5em;
        color: #000;
      }
    }

    .owl-prev {
      left: -40px;
    }

    .owl-next {
      right: -40px;
    }
  }
}

@mixin absolute-center() {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto auto;
}

@mixin more-link() {
  display: block;
  color: $bh-blue;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

@mixin box-title($background-color, $color, $padding: 5px 20px) {
  background: $background-color;
  padding: $padding;
  color: $color;
  font-style: normal;
}

@mixin tags() {
  list-style: none;
  padding: 3px 8px;
  margin-right: 2px;
  font-size: 11px;
  text-transform: uppercase;
  background: none !important;
  color: #000;
  font-weight: bold;
  float: left;
  border-left: solid thin #000;
}

@mixin li-bullets() {

  li:before {
    content: "-";
    margin-right: 20px;
    font-family: fontAwesome;
  }
}

@mixin title-over-image() {
  position: relative;
  margin-bottom: 0px;

  .views-field-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 12px;

    h3, h4, h5, a, span {
      color: #fff;
      margin-bottom: 0;
    }
  }
}

@mixin tile() {
  background: #fff;
  box-shadow: #ddd -1px 1px 2px;
  border-radius: 3px;
  border-top: solid 5px #fff;
  margin-bottom: 10px;
}

@mixin grayscale($value) {
  -webkit-filter: grayscale($value);
  -moz-filter: grayscale($value);
  filter: grayscale($value);
}

@mixin border-bottom($spacing, $color) {
  margin-bottom: $spacing;
  padding-bottom: $spacing;
  border-bottom: solid thin $color;
}

@mixin divider($spacing, $color, $position) {
  margin-#{$position}: $spacing;
  padding-#{$position}: $spacing;
  border-#{$position}: solid thin $color;
}

@mixin clear-divider($position) {
  margin-#{$position}: 0 !important;
  padding-#{$position}: 0 !important;
  border-#{$position}: 0 !important;
}

@mixin vendor-prefix($name, $argument) {
  -webkit-#{$name}: #{$argument};
  -ms-#{$name}: #{$argument};
  -moz-#{$name}: #{$argument};
  -o-#{$name}: #{$argument};
  #{$name}: #{$argument};
}

@mixin button($border-radius: "5px", $background: "#fff", $font-color: "#000", $hover-background: "$bh-blue", $hover-font-color: "#fff") {
  background: $background;
  color: $font-color;
  border: solid thin $border-color;
  text-transform: uppercase;
  @include vendor-prefix(transition, 0.5s);
  @include vendor-prefix(border-radius, $border-radius);

  &:hover {
    background-color: $hover-background;
    color: $hover-font-color;
  }

  a {
    padding: 10px 20px;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

@mixin sub-heading {
  display: block;
  font-size: 22px;
  font-weight: bold;
  font-family: 'Lato', san-serif;
  color: #1e6f80;
  margin-bottom: 0px;
  color: #333;
  font-size: 20px !important;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-style: normal;
//  text-shadow: #ddd 0 0 40px;
  border-bottom: solid 1px $border-color;

  span {
    font-weight: 300;
  }
}

@mixin block-heading($border-top-color) {
  border-bottom: solid thin #dddddd;
  border-top: solid 2px $border-top-color;
  padding: 14px 0px 13px;
  margin-top: 0;
  margin-bottom: 20px;
  font-style: normal;
}

@mixin fadeout-vertical($height: 1em) {
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $height;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), #ffffff 50%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), #ffffff 50%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 50%);
  }
}

@mixin line-through($color) {
  overflow: hidden;
  text-align: center;

  > span {
    position: relative;
    display: inline-block;
  }

  > span:before, > span:after {
    content: ' ';
    position: absolute;
    top: 50%;
    border-bottom: 1px solid;
    width: 100%; /* half of limiter */
    margin: 0 10px;
    color: $color;
  }

  > span:before {
    right: 100%;
  }

  > span:after {
    left: 100%;
  }
}

// 3D CSS Ribbon ------------------------------------------------------------
// Plugin by Leandro D'Onofrio - http://dzign.us/

//** Default variables for the ribbon **//

$ribbon-3d-background-color: #428bca !default;
$ribbon-3d-position: bottom !default;
$ribbon-3d-sides: left right !default;
$ribbon-3d-overlap-horizontal: 10px !default;
$ribbon-3d-overlap-vertical: 10px !default;
$ribbon-3d-overlap-color: darken($ribbon-3d-background-color, 30%) !default;

//** 3D CSS Ribbon mixin **//

@mixin ribbon(// Set the background color of the ribbon
$background-color: $ribbon-3d-background-color,
  // Set the position of the sides
$position: $ribbon-3d-position,
  // Set the sides of the ribbon
$sides: $ribbon-3d-sides,
  // Set the width of the overlaps
$overlap-horizontal: $ribbon-3d-overlap-horizontal,
  // Set the height of the overlaps
$overlap-vertical: $ribbon-3d-overlap-vertical,
  // Set the color of the edges
$overlap-color: $ribbon-3d-overlap-color) {

  background-color: $background-color;
  position: relative;
  z-index: 6;

  &:before, &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    font-size: 0;
    line-height: 0;
    z-index: 5;

    @if $position == "bottom" {
      border-top: 0 solid transparent;
      border-bottom: $overlap-vertical solid transparent;
      bottom: -$overlap-vertical;
    }
  }
  @if $position == "top" {
    border-bottom: 0 solid transparent;
    border-top: $overlap-vertical solid transparent;
    border-top: $overlap-vertical solid transparent;

    top: -$overlap-vertical;
  }

  $sides-length: length($sides);

  @if ($sides == right) or ($sides-length == 2) {

    &:before {
      border-left: $overlap-horizontal solid $overlap-color;
      right: 0;
    }

  }

  @if ($sides == left) or ($sides-length == 2) {

    &:after {
      border-right: $overlap-horizontal solid $overlap-color;
      left: 0;
    }

  }

}

//** Default variables for the ribbon's back **//

$ribbon-3d-back-background-color: $ribbon-3d-background-color !default;
$ribbon-3d-back-position: $ribbon-3d-position !default;
$ribbon-3d-back-height: 40px !default;
$ribbon-3d-back-width: 60px !default;
$ribbon-3d-back-horizontal-distance: $ribbon-3d-overlap-horizontal !default;
$ribbon-3d-back-vertical-distance: $ribbon-3d-overlap-vertical !default;
$ribbon-3d-back-snip-color: transparent !default;

//** 3D CSS Ribbon mixin's back **//

@mixin ribbon-back(// The side to create
$side,
  // Set the background color of the ribbon's back
$background-color: $ribbon-3d-back-background-color,
  // Set the position of the ribbon's back
$position: $ribbon-3d-back-position,
  // Set the height of the ribbon's back
$height: $ribbon-3d-back-height,
  // Set the width of the ribbon's back
$width: $ribbon-3d-back-width,
  // Set the vertical distance of the ribbon's back
$horizontal-distance: $ribbon-3d-back-horizontal-distance,
  // Set the horizontal distance of the ribbon's back
$vertical-distance: $ribbon-3d-back-vertical-distance,
  // Set the color of the back's snip
$snip-color: $ribbon-3d-back-snip-color) {

  display: block;
  height: $height;
  width: $width;
  background-color: $background-color;
  position: absolute;
  z-index: -1;

  @if $side == left {
    left: - ($width - $horizontal-distance);
  }

  @if $side == right {
    right: - ($width - $horizontal-distance);
  }

  @if $position == bottom {
    bottom: -$vertical-distance;
  }

  @if $position == top {
    top: -$vertical-distance;
  }

  @if $snip-color != transparent {

    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      font-size: 0;
      line-height: 0;
      border-bottom: $height / 2 solid transparent;
      border-top: $height / 2 solid transparent;

      @if $side == left {
        border-left: ($height / 2) / 2 solid $snip-color;
        left: 0;
      }

      @if $side == right {
        border-right: ($height / 2) / 2 solid $snip-color;
        right: 0;
      }

    }

  }

}

@mixin typo() {
	font-weight: normal;
	font-family: 'Lato', sans-serif;
}


.navpn {
  margin-top: 40px;
  width: 100%;

  .read-next {
    width: 100%;
  }

  &.visible {
    right: 0px;
  }

  > div {
//    @include title-over-image;
    width: 45%;
    max-height: 210px;
    max-width: 300px;

    i {
      position: absolute;
      color: #999;
      top: 95px;
      font-size: 30px;
    }
    &:hover i {
      color: #000;
    }
  }

  #pnprev {

    i {
      left: -30px;
    }
    margin-left: 30px;
    float: left;

  }

  #pnnext {

    i {
      right: -30px;
    }
    margin-right: 30px;

    float: right
  }
}

@mixin animate-spinner {
//  @include vendor-prefix(transform-style, preserve-3d);
//  @include vendor-prefix(animation, rotating 2s linear);
}

.ajax-progress-throbber:after {
  content: "LOADING...";
  float: left;
  font-weight: 300;
  margin-left: 20px;
  margin-top: 8px;
}

@keyframes rotating {
  from {
    transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
  }
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
  }
}


.ajax-progress-throbber {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 200px;
  height: 54px;
  margin: auto auto;
  border: solid thin $border-color;
  background: #fff;
  color: #555;
  box-shadow: rgba(100, 100, 100, .3) 0 0 10px;
  padding: 8px 0px 0 20px;
  z-index: 99999;
  opacity: 1;
}

.ajax-progress-throbber .throbber {
  background: none;
  float: left;
  background: url("/sites/all/themes/bd/images/logos/brand-initials-small.png");
  float: left;
  height: 32px;
  width: 32px;
  color: $bh-blue;
  content: "";
//  @include animate-spinner();
}

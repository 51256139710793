.node--industry-and-brand-news {

  .field--name-field-image {
    margin-bottom: 15px;
  }
  
  .content {
//    @include span-columns(9 omega, 12);
  }

  .submitted {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
  }

}

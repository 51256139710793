.homepage-breadcrumb {
  margin-top: 0px;
  margin-bottom: 20px;
  text-transform: uppercase;
  background: #f7f7f7;
  
  span {
    padding: 15px 30px;
    &::before, &::after {
      top: 17px;
    }
  }
  
  span:nth-child(2) {
    color: $bh-dark-blue !important;
    &:before {
      content: "";
      background: none;
      border: none;
    }
    &:after {
      content: "";
      background: none;
      border: none;
    }
  }
  span:nth-child(3) {
    background: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: 900;
  }
}

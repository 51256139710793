.node-type-article {

  .field--name-field-brand {
    display: none;

//    @include at-breakpoint($tab) {
//      display: block;
//    }

    float: right;
    padding: 30px 0 40px 20px;
    margin: 0px 0 20px 20px;
    width: 150px;
    border: solid thin $border-color;
    border-right: none;
    border-bottom: none;
  }
}

.l-region--header {
  position: relative;
  margin-bottom: 0 !important;
}

.l-header {
  position: relative;
  z-index: 1;
  height: auto;
  margin-top: 0px;
}

#block-block-16 {
  display: none;
  position: absolute;
  left: 5px;
  width: 137px;
  transition: 0.2s;
    
  p {
    margin: 0;
    padding: 0;
    line-height: 0;
  }
  
  &.visible {
    bottom: 12px;
  }
}

.t-header {
  overflow: visible;
  /// @include bh-gradient;
  margin: 0 0 -10px;
  padding: 0;
  color: $bh-dark-blue;
  font-size: 12px;

  #block-views-header-block {
    float: right;
    margin-top: 18px;
    text-transform: uppercase;

    .block__content div {
      float:  left;
      
    }
    .views-row {
      margin-right: 20px;
    }
    .views-field {
      margin-right: 4px;
    }
    
  }

  .site-logo {

    display: block;
    margin-bottom: 20px;
//    @include at-breakpoint($tab) {
//      margin-bottom: 0;
//    }

    transition: 0.2s;
    width: 100%;
    text-align: center;
    padding-top: 20px;

    img {
      width: 100%;
      max-width: 850px;
      z-index: 999;
      position: relative;
    }
    
    &.hidden {
      top: -50px !important;
    }
  }

  #block-block-10 {
    /* position: absolute; */
    width: 400px;
    text-align: center;
    float: left;
    p {
      margin: -1px 0 0 0;
      font-weight: bold;
    }
  }
  
  #block-system-user-menu {
    float: right;
    h2 {
      display: none;
    }
    ul {
      padding-left: 0;
      margin: 0;
      li {
        text-transform: uppercase;
        float: left;
        list-style: none;
        margin-right: 10px;
        a {
          padding: 3px 11px;
          background-color: #fff;
          color: $bh-dark-blue;
          border-radius: 3px;
        }
      }
    }
  }
}

#block-role-toggle-main {
    position: fixed;
    top: 30px;
    z-index: 9999999;
}

header.container-fluid {
    margin-top: 86px;

	.region-branding  {
		zoom: 1;
		max-width: 1168px;
		_width: 1168px;
		padding-left: 5px;
		padding-right: 5px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;

	}
	> .navbar-collapse {
			padding: 0px;
	}
}


body.page-user-dashboard-favourite, body.page-my-board {
  .view-views-user-dashboard-content {
    .group-wrapper {
      a {
        text-transform: uppercase;
        display: block;
        text-align: center;
        color: $bd_pink;
      }
    }
    .view-header {
      div {
        font-style: normal;
        text-align: center;
        padding-top: 0em;
        padding-bottom: 3em;
        text-transform: uppercase;
        font-size: 3rem;
        font-family: inherit;
        &::before {
          content: " ";
          display: table;
          background: #ccc;
          width: 30%;
          height: 1px;
          position: relative;
          margin-top: 20px;
          float: left;
          @media screen and (max-width: $screen-fx-max) {
            width: 20%;
          }
          @media screen and (max-width: $screen-xs) {
            display: none;
          }
        }
        &::after {
          content: " ";
          display: table;
          background: #ccc;
          width: 30%;
          height: 1px;
          position: relative;
          margin-top: 20px;
          float: right;
          @media screen and (max-width: $screen-fx-max) {
            width: 20%;
          }
          @media screen and (max-width: $screen-xs) {
            display: none;
          }
        }
      }
    }
  }
  .view-content {
    max-width: 100%;
    .product-content {
      h2 {
        text-transform: uppercase;
        text-align: center;
        display: block;
        font-family: 'latoheavy';
        //padding: 0px 3em 1em 0em;
        letter-spacing: 2px;
        font-size: 1.4rem;
        font-style: normal;
        min-height: 4em;
      }
      .group-wrapper {
        position: relative;
        max-width: 180px;
        margin: 1em auto;
        span.field-label {
          text-align: left;
          margin-right: 10px;
          display: inline-block;
          vertical-align: top;
          font-weight: bold;
          float: left;
          line-height: 137%;
          clear: both;
          &:nth-last-of-type(1) {
            margin-left: 25px;
          }
        }
        #magical-modal-link {
          padding: 12px 5px;
          background: $bd_pink;
          &:hover {
            background: $bd-brand;
          }
          a {
            color: #ffffff;
            display: block;
            text-transform: uppercase;
            font-size: 12px;
            text-align: center;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    .field-name-field-product-image {
      min-height: 180px;
      img {
        margin: 0 auto;
      }
    }
  }
  .icon-downloads-wrapper {
    @media (max-width: $screen-sm-max) {
      .col-sm-12.col-md-3.col-lg-3 {
        width: 33%;
        float: left;
      }
    }
    max-width: 150px;
    margin: 0 auto;
    position: relative;
    .download-links {
      .fa {
        color: $pitch;
        position: absolute;
      }

      .fa-download {
        top: 0px;
      }
      .fa-envelope {
        a {
          position: absolute;
          display: block;
          width: 20px;
          height: 15px;
          left: 0px;
          top: 0px;
        }
      }

      .flag-outer {
        top: 0px !important;
        right: inherit;
        font-size: inherit;
        border: 0px;
      }
    }
  }
}

.is-user-active #block-bd-custom-bd-user-extras {
  @media screen and (max-width: $screen-sm-max - 11) {
    width: 100%;
    text-align: left;
    height: 100%;
    min-height: 1000px;
    background: #fff;
    margin-top: -44px!important;
  }
}
.is-user-visible {
  .tb-megamenu .nav-collapse.collapse.in {
    @media screen and (max-width: 979px) {
      min-height: 1000px!important;
    }
  }
}
.is-user-active {
  header.container-fluid {
    padding-left: 0px !important;
  }
  ul.profile-inner {
    @media screen and (max-width: 979px) {
      padding-left: 0px;
    }
  }
}
  .is-user-visible #block-bd-custom-bd-user-extras {
    @media screen and (max-width: $screen-sm-max - 11) {
      display: block!important;
    }
  }
  .l-region--branding {
    position: relative;
    margin-bottom: 2em !important;
    /**border-bottom: solid thin #ccc;**/
    @media screen and (max-width: 979px) {
      .tb-megamenu-column-inner {
        border: 0px !important;
      }
      #block-block-9 > p {
        text-align: left;
        a.site-logo {
          max-width: 300px;
          display: block;
          img {
            width: 100%;
          }
        }
      }
      #block-bd-custom-bd-user-extras {
        display: none;
        @media screen and (max-width: $screen-sm-max - 11) {
          position: absolute;
          z-index: 999999999;
          -webkit-transition: opacity 2.0s ease-in;
          -moz-transition: opacity 2.0s ease-in;
          -o-transition: opacity 2.0s ease-in;
          opacity: 1;
        }
      }
    }

    #block-system-user-menu {

      ul {
        padding: 0;
        margin: 0;
        float: right;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .leaf {
          color: $bh-blue;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          list-style: none;
          float: left;
          margin-right: 10px;
          //        transition: 0.2s;
          padding: 13px 5px 4px;
          font-weight: normal;
          font-size: 0.75em;


          a::before {
            margin-right: 5px;
          }

          &.last {
            padding: 18px 10px 4px;
            margin-top: -5px;
            margin-right: 0;
            background-color: #999;
            color: #fff;

            //          @include ribbon(#999, top, left, 3px, 3px);
            &::after {
              top: 1px;
              left: -3px;
            }

            &:hover {
              background-color: $bh-blue;
            }
          }
        }
      }
    }
  }
@media screen and (max-width: 979px) {
  .is-user-visible {
    a.site-logo {
      opacity: 0;
    }
  }
}

.front {

  // for the sidebar

  .l-region--sidebar-second {
    .block--dfp-mrec, .block--dfp-mrec-nz, .block--dfp-bd-new-au-mrec-2 {
      width: 100%;
      display: block;
      text-align: center;
    }

    .block--dfp-mrec, .block--dfp-mrec-nz {
//      @include divider(20px, $border-color, bottom);
      padding-bottom: 20px;
    }

    .block--dfp-bd-new-au-mrec-2 {
      margin-bottom: 20px;
    }
  }
}

.back-to-top {
  position: fixed;
  top: 80px;
  left: 30px;
  color: #ddd;
  height: 50px;
  width: 200px;
  z-index: 99999999;
  font-size: 20px;
  cursor: pointer;
  transition: .5s;
  opacity: 0;
  text-transform: uppercase;
}

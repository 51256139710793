.l-page > .l-footer {

  border-top: solid 1px #000;
  display: none;
  
  #block-views-footer-content-block {
//    @include span-columns(5, 12);
  }

  #block-block-24 {
//    @include span-columns(4, 12);
  }
  
  #block-views-footer-content-block-3 {
//    @include span-columns(3 omega, 12);
    ul li {
//        @include clearfix;
//        @include divider(14px, $footer-divider-color, bottom);
      
      img {
        float: left;
        margin-right: 10px;
      }
    }
  }

  #block-block-24 {
    
    p {
      font-size: 0.9em;
      padding: 0;
      margin: 0;
      
      a {
        color: $footer-text-color;
        text-decoration: none;
        
        &:hover {
          color: $footer-text-color;
          text-decoration: none;
        }
      }
    }
    div > div:first-child {
//      @include divider(20px, $footer-divider-color, bottom)
    }
    
    img {
      float: left;
      height: 80px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
  
  padding: 40px 0;
  margin-top: 30px;
  background: #fff;

  h2.block__title {
    font-size: 16px;
    color: $footer-header-color;
    text-shadow: none;
    border-bottom: none;
  }
  
  ul {
    padding-left: 0;
    margin-left: 0;
    
    li {
      list-style: none;
      color: $footer-text-color;
      font-size: 1em;
      font-weight: 300;
      text-transform: none;
      /*margin-bottom: 10px;*/
//      @include divider (7px, $footer-divider-color, bottom);

    }
  }
}

//@import "compass";
//@import "susy";

// Set consistent vertical and horizontal spacing units.
$vert-spacing-unit: 20px;
$horz-spacing-unit: 10em;

// Define Susy grid variables mobile first.
$total-columns: 4;
$column-width: 6em;
$gutter-width: $horz-spacing-unit;
$grid-padding: 5px;
$column-padding: 1em;
$container-style: magic;
$container-width: 1200px;

// Susy Media Layouts @see http://susy.oddbird.net/guides/reference/#ref-media-layouts
$tab: 34em 16; // At 44em use 12 columns.
$desk: 60em 16; // At 70em use 16 columns.
$show-skins: 70em 16;

//@include at-breakpoint($desk) {
//  // $container-width: 1000px;
//}

.l-header,
.l-region--header,
.l-main,
.l-region--navigation,
.l-region--branding,
.footer-terms,
.footer-links,
.l-region--footer {
//  @include container; // Define these elements as the grid containers.
  margin-bottom: $vert-spacing-unit;
}

.l-region--skin-left, .l-region--skin-right {
  display: none;
}

.l-region--breadcrumbs {
//  margin-bottom: 20px;
}

.l-region--breadcrumbs {
//  @include container; // Define these elements as the grid containers.
}

#block-crumbs-breadcrumb {
//  @include span-columns(12, 12);
}

@mixin tile() {
  padding: $column-padding;
  margin-bottom: 10px;
  border-top: solid 2px #000;

}

.l-region--top-leaderboard {
  display: none;
//  @include at-breakpoint($show-skins) {
//    display: block;
//  }
}


//@include at-breakpoint($show-skins) {
  .l-region--skin-left, .l-region--skin-right {
    display: block;
    position: absolute;
    width: 150px;
    top: 52px;
    
    p {
      margin: 0;
    }
  }

  
  $skin-gutter: -175px;
  .l-region--skin-left {
    left: 0px;
    margin-left: $skin-gutter;
  }
  
  .l-region--skin-right {
    right: 0px;
    margin-right: $skin-gutter;
  }
//}

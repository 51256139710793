.page-search {
  .node--product.node-teaser {
    .field--name-field-brand {
      display: block;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.node--product.node-teaser {

  padding-top: 40px;

  &.node--sticky {
    border-left: solid 2px $bh-blue;
  }

  .product-content {
//    float: left;
//    width: 74%;

//    @include at-breakpoint($tab) {
//      @include span-columns(9 omega, 12);
//    }
  }

  .product-image {
//    margin: 10px 10px 0 0;
//    width: 150px;
//    float: left;

    text-align: center;
    width: 100%;
    
//    @include at-breakpoint($tab) {
//      @include span-columns(3, 12);
//    }

    .owl-item > div {
      text-align: center;

      img {
        max-width: 90%;
      }
    }


    .field--name-field-product-image {
      text-align: center;
      max-height: 200px;


      img {
        max-width: 150px;
        max-height: 150px;
        width: auto;
        height: auto;
      }
    }
  }

  .field--name-body {
    clear: both;

    p {
      /*line-height: 1.4em;*/
      /*font-size: 0.9em;*/
      font-size: 1em;
      margin-top: 10px;
      margin-bottom: 10px;

      a {
        display: inline !important;
      }
    }
  }

  .field--name-field-brand {
    display: none;
  }

  .field--name-field-logo-image {
    margin-bottom: 30px;
    margin-top: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .group-products-details {
    ul {
      float: left;
      width: 300px;
      padding: 0;
      margin: 0;
    }

    li {
      list-style: none;
      float: left;
      width: 100px;
    }
  }

  .download-link {

    &.hidden {
      display: none !important;
    }

//    @include at-breakpoint($tab) {
//      display: block;
//    }

    padding: 2px 5px;
    border: solid thin #ddd;
    text-transform: uppercase;
    font-size: 0.7em;
    width: 100px;
    text-align: center;
    margin: 10px auto 0;
    color: #bbb;

    &:hover {
      border-color: #999;
      color: #333;

      a {
        text-decoration: none;
      }
    }
    
    &.color-palette {
      background-color: #999999;
      color: #fff;
      margin-top: 0;
      &:hover {
        background-color: #333;
      }
    }
  }

  .field--name-field-categories {
//    @include clearfix;
    margin-top: 8px;

    .field__items > .field__item {
      font-size: 0.6em;
      color: #fff;
      float: left;
      font-weight: normal;
      background: $bh-blue;
      text-transform: uppercase;

      padding: 3px 6px;
      border-radius: 3px;
      margin: 0 5px 0px 0;
    }
  }
}

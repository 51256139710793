.node-type-article {

  .article-hide-brand-images {
    .field--name-field-brand {
      display: none;
    }
  }

  #block-views-in-this-section-block {

    .view .view-content .views-row {
      
      .attachment-after {
//        @include divider(20px, $border-color, top);
      }
    }
  }

  .l-main h1 {
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: none;
//    @include at-breakpoint($tab) {
//      font-size: 3em;
//    }
  }
  
  .flag-wrapper {
    top: 0px;
  }
  
  .node--article--full {
    position: relative;

    ul {
      li {
        list-style: disc;
        margin-bottom: 15px;
      }
    }
    
    ol {
      li {
        list-style: decimal;
        margin-bottom: 15px;
      }
    }

    > .submitted {
      overflow: auto;
      color: #999;
      font-size: 0.8em;
//      @include divider(10px, $border-color, bottom);
      margin-bottom: 30px;

      > span {
        float: left;
        margin: -1px 0 0;
      }
    }


    .content > .field--name-field-image {

//      @include at-breakpoint($tab) {
//        max-width: 30%;
//        float: left;
//        margin-right: 20px;
//      }

      img {
        height: auto;
        width: auto;
      }
    }

    .content > .field--name-field-image-hotspot {

      .img-hotspot {
        margin-top: 55px;
      }

//      @include at-breakpoint($tab) {
//        max-width: 100%;
//        float: left;
//        margin-right: 0px;
//      }

      img {
        height: auto;
        width: auto;
      }
    }


    
    .content > .field--name-body {

      .media-youtube-video {
        position: relative;
        padding-top: 35px;
        overflow: hidden;
        height: 0;
        padding-bottom: 56%;

//        @include at-breakpoint($tab) {
//          text-align: center;
//          padding-bottom: 35%;
//          height: 70%;
//        }
      }

      .media-youtube-video iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        
//        @include at-breakpoint($tab) {
//          width: 70%;
//        }
      }

      img {
        max-width: 70%;
        height: auto;
      }

      a {
        text-decoration: underline;
      }
    }
  
    p {
     line-height: 1.7em;
    }
  }
    
  .field--name-field-article-category {
    display: none;

//    @include at-breakpoint($tab) {
//      display: block;
//    }
    float: left;
    margin-left: 20px;
    margin-right: 10px;

    .field__item {

//      @include tags();
      padding: 1px 8px;
    }
  }
  

  .field__label, .node__submitted {
    border-bottom: solid thin $border-color;
    padding: 0px 0 9px;
    margin-bottom: 20px;
  }
  .inner-wrapper {
    max-width: $inner-width;
    margin: 0 auto;
    .submitted {
      text-align: center;
      font-style: normal;
      font-size: 0.889rem;
      font-weight: 200;
      letter-spacing: 0.05em;
      .posted {
        display: inline-block;
      }
      .field-name-field-article-category {
        display: inline-block;
        .field-items {
          display: inline-block;
          .field-item {
            display: inline-block;
            padding-left: 5px;
            a {
              &:link {
                color: $link_color;
              }
              &:visited {
                color: $link_visited_color;
              }
              &:hover {
                color: $link_hover_color;
                text-decoration: none;
              }
              &:active {
                color: $link_active_color;
              }
          }

          }
        }
      }
    }
    .field-name-body {
      &::after {
        content: '';
        display: block;
        clear: right;
      }
      padding: 0em 7em 5em;
      .field-name-field-brand {
        display: block;
        float: right;
        padding: 30px 0 40px 20px;
        margin: 0px 0 20px 20px;
        width: 150px;
        border: solid thin #dddddd;
        border-right: none;
        border-bottom: none;
        .flag-outer {
          display: none!important;
        }
        .field-name-field-logo-image {
          margin-bottom: 3em;
        }
      }
      @media screen and (max-width: $screen-sm-max) {
        padding: 0em 2em;
      }
    }
    h1 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .content {
      .field-name-field-gallery {
        padding: 5em 7em;
        @media screen and (max-width: $screen-sm-max) {
          padding: 5em 2em;
        }
      }
      .field-name-field-related-content {
        padding: 3em 7em;
        border-top: 1px solid #ccc;
        @media screen and (max-width: $screen-sm-max) {
          padding: 3em 2em;
          .col-sm-3 {
            width: 25%;
            float: left;
          }
          .col-sm-9 {
            width: 75%;
            float: left;
          }
        }
        .submitted {
          margin: 0px;
          text-align: left;
        }
      }
    }
    ul.list-inline {
      li.nodequeue-ajax-toggle {
        display: none;
      }
    }
  }
}
.view-news-top-gallery {
  .view-content {
    max-width: 100%;
    .views-field-title {
      text-align: center;
      padding-bottom: 0;
      border-bottom: none;
      font-size: 2em;
      margin: 0.8em 0.8em 2em;
      font-weight: 500;
    }
    a.carousel-control.right, .carousel-control.left {
      background: none !important;
    }
  }
}

body.page-news {
  section#block-views-footer-block-4 {
    padding-top: 40px;
  }
}

article#node-804535 {
  @media screen and (max-width: 480px) {
    .col-xs-6.col-sm-6.col-md-4.col-lg-4 {
      width: 100%;
    }
    h2 {
      &::before, &::after {
        width: 20%;
      }
    }
  }
}


.front .full-page-background {
  display: none !important;

//  @include at-breakpoint($tab) {
//    display: none !important;
//  }

}

fieldset {
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
}

#logged-out {
	header.navbar-default {
		//opacity: .9;
		background-color: #ffff;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
    background: rgba(255,255,255,0.9);
	}
  #block-views-megamenu-block .view-header p {
    padding-bottom: 0px;
  }

  .tb-megamenu .nav > .active > a,
  .tb-megamenu .nav > .active > a:hover,
  .tb-megamenu .nav > .active > a:focus,
  .tb-megamenu .nav > li > a:focus,
  .tb-megamenu .nav > li > a:hover {
    background-color: inherit!important;
  }
  .tb-megamenu-main-menu {
    margin: 0 auto;

    display: table;
    @media screen and (max-width: 979px) {
      display: block;
    }
  }

  #user-login, #user-register-form {
    max-width: 600px;
    margin: 0 auto;
    span.password-link {
      float: none;
      a {
        color: #ffffff;
        &:hover {
          color: $bd_pink;
        }
      }
    }
    @media screen and (max-width: 468px) {
       padding: 0px 20px;
       .form-item label:not(.option), .form-actions label:not(.option) {
         float: none;
      }
      input[type=text], input[type=password], textarea, .form-control, .resizable-textarea textarea {
        width: 100%;
      }
      span.password-link {
        float: none;
        a {
          color: #ffffff;
          &:hover {
            color: $bd_pink;
          }
        }
      }
    }
    button {
      @media screen and (max-width: 469px) and (max-width: 590px) {
        width: 188px;
      }
      max-width: 288px;
      color: #fff;
      border-radius: 10px;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.1em;
      display: inline-block;
      background: $pitch;
      padding: 14px;
      width: 100%;
      border-radius: 0px;
      -moz-border-radius: 0px;
      -webkit-border-radius: 0px;
      &:hover {
        opacity: 0.9;
        text-decoration: none;
      }
    }
  }

  .l-footer-links {
    margin-top: 0;
  }

  video {
    display: none;

    @media (min-width: 34em) {
      display: block;
    }
  }

  video#bgvid {
      position: fixed; right: 0; bottom: 0;
      min-width: 100%; min-height: 100%;
      width: auto; height: auto; z-index: -100;
      background-size: cover;
  }

  .l-nav {
    opacity: .9;
    border-bottom: solid 2px black;
  }

  #block-search-form {
    display: none;
  }

  .l-footer {
    //display: none;
  }

  .l-region--footer {
    display: none;
  }

  // background image for the non-logged in pages
  .full-page-background {

    display: none;
//    @include at-breakpoint($tab) {
//      display: block;
//
//      /* Set rules to fill background */
//      min-height: 100%;
//      min-width: 1772px;
//
//      /* Set up proportionate scaling */
//      width: 100%;
//      height: auto;
//
//      /* Set up positioning */
//      position: fixed;
//      top: 0;
//      left: 0;
//      z-index: -1;
//    }
  }

  @media screen and (max-width: 1024px) { /* Specific to this particular image */
    .full-page-background {
      left: 50%;
      margin-left: -512px;   /* 50% */
    }
  }

  .l-content {
    min-height: 0 !important;
    position: inherit !important;
    padding-right: 0px;
  }

  .l-main {
    margin-top: 20px;
    //padding: 10px 30px;
    //min-height: 100px !important;
    position: inherit !important;
  }

  .form-content {
		@media screen and (max-width: $screen-xs-max) {
			padding: 0px;
			h1 {
				font-size: 2em !important;
			}
			.button {
				width: 200px;
			}
		}

    h1 {
      border-bottom: none;
    }

    .password-link {
      color: #999;
      float: right;
      margin-top: 28px;
      margin-right: 40px;
    }

    text-align: center;

    padding: 30px 30px 80px;

//    @include at-breakpoint($tab) {
//      padding: 30px 30px 80px;
//    }

    //background-color: rgba(#fff, 0.2);

    &.opaque {

      h1 {
        font-weight: normal;
        text-transform: uppercase;
        text-decoration: none !important;
        color: #fff;
        //        text-shadow: $bh-blue 0 0 15px;
        line-height: 1.2em;
        font-size: 6em;
				font-family: 'latothin';
        @media screen and (max-width: $screen-xs-max) {
          padding-top: 3em;
        }
        @media screen and (min-width: $screen-xs-max + 1) and (max-width: 979px){
          padding-top: 0em;
        }

//        @include at-breakpoint($tab) {
//          font-size: 4em !important;
//
//        }
      }

      padding-bottom: 20px;
      padding-top: 85px;
      @media screen and (max-width: 979px) {
        padding-top: 0px;
      }

      //background-color: rgba(#fff, 0.2);

//      @include at-breakpoint($tab) {
//        background-color: rgba(#fff, 0.1);
//      }
    }
    //  border: solid 1px $border-color;

//    @include at-breakpoint($tab) {
//      width: columns(12);
//    }

    margin: 0 auto;

    p {
      margin-bottom: 0px;
    }

    #edit-submit {

      width: 100%;
      margin-top: 20px;

//      @include at-breakpoint($tab) {
//        padding: 10px 50px;
//        float: right;
//        width: 300px;
//        margin-right: 19px;
//      }
    }

    h1 {
      margin: 0 0 10px;
      padding-top: 0;
    }
  }
 .button-wrapper {
	 max-width: 500px;
	 margin: 0 auto;
  .button {
//    @include button(5px, $bh-blue, #fff);
    display: inline-block;
    margin-bottom: 10px;
    @media screen and (max-width: 979px) {
      width: 100%;
    }

//    @include at-breakpoint($tab) {
//      width: 40%;
//    }
    //margin-right: 2%;
    opacity: 0.7;
    border: none;
    transition: 1s;
    &:hover {
      opacity: 1;
    }
    &:last-child {
      margin-right: 0;
    }
		a:hover {
			background: none;
			border: solid 1px #F7796D;
			color: #ffffff;
		}
  }

  .button.white {
    a {
      background-color: #fff;
      color: $bh-blue;
    }
    opacity: 1;
  }

  .button.black {
    a {
      background-color: #000;
      color: #fff
    }
    opacity: 1;
  }
 }

  .segment-container {

    &.fade {
      box-shadow: #eee 0 19px 52px inset;
      border-bottom: solid 3px $bh-blue;
      border-top: solid 2px black;

    }

    background-color: #fff;

    &.color {

      background-color: rgba(0, 0, 0, 0.7);
      min-height: 496px;
      @media screen and (max-width: 979px) {
        min-height: 450px;
      }

//      @include at-breakpoint($tab) {
//        background-color: rgba($bh-blue, 0.7);
//      }

      color: #fff;

      h1, h2, h3, p {
        color: #fff;
      }
    }

    &.border {
      padding-bottom: 40px;
      border-bottom: solid 3px $bh-blue;
    }

    &.semi-opacity {
      background-color: rgba(255, 255, 255, .8) !important;
    }

    &.opacity {
      background-color: transparent !important;
      h1 {
        font-weight: normal;
        font-size: 2.8em !important;
        color: #000;
        text-shadow: #fff 0 0 13px;
      }
    }
  }

  .segment {


    //padding: 10px 20px 10px;

//    @include at-breakpoint($tab) {
//      padding: 60px 20px 60px;
//    }

    overflow: auto;
//    @include container;
    text-align: center;

    &.thin {
      padding: 40px 20px 20px;
    }
  }

//  i {
//    margin-bottom: 20px;
//  }

  p {
		@include fontP();
		margin-bottom: 0px;
		/*padding-bottom: 57px;*/
    font-size: 18px;
    /*padding-left: 10px;
    padding-right: 10px;*/
  }

  h1 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;

    &.blue {
      color: $bh-blue;
    }
  }

  h2 {
    @include h2();
    padding: 0px 0px 30px 0px;
    font-weight: bolder;
    &.blue {
//      @include button(0px, $bh-blue, #fff);
    }
  }

  h3 {
    margin-bottom: 20px;
    padding: 20px;
  }

  .testimonials {

    .column {

//      @include at-breakpoint($tab) {
//        @include span-columns(1, 3);
//        @include nth-omega(3);
//      }

      background-color: #fff;
      padding: 20px;

    }

    p {
      font-size: 1.3em;
      line-height: 1.5em;
      font-style: italic;
      color: #666;
    }

    img {
      border-radius: 100px;
      height: 100px;
      width: 100px;
      margin-bottom: 20px;
    }
  }

  position: relative;
  z-index: 99999999;
  width: 100%;

  .boxes {
    margin-top: 40px;
    margin-bottom: 40px;

    div {
//      @include vendor-prefix(border-radius, 250px);
//      @include vendor-prefix(box-shadow, #ccc 0 10px 10px);
      border: solid 10px #fff;
      display: inline-block;

      width: 70%;
      margin-bottom: 10px;

//      @include at-breakpoint($tab) {
//        width: 29%;
//        margin-bottom: 0;
//      }

      margin-right: 4%;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);

      &:nth-child(3n) {
        margin-right: 0;
      }

      height: 270px;
      position: relative;
      overflow: hidden;
      text-align: center;

      &:hover {

        h3 {
          margin-top: 20px;
        }

        p {
          margin-top: 0px;
          opacity: 1;
        }

        img {
//          @include grayscale(100%);
          opacity: 0.8;
//          @include transform(scale(1.5));
        }
      }

      img {
//        @include vendor-prefix(transition, all 0.5s ease);
        position: absolute;
        width: 200%;
        height: auto;
        max-width: 200%;
        top: 0;
        left: 0;
        overflow: hidden;
      }

      h3 {
//        @include vendor-prefix(transition, margin-top 0.5s ease);
        position: relative;
        font-size: 1.1em;
        text-align: center;
        text-transform: uppercase;
        font-weight: normal;
        margin-top: 110px;
        margin-bottom: 10px;
        padding: 7px;
        width: 100%;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        display: inline-block;
      }

      p {
//        @include vendor-prefix(transition, margin-top 1s ease);
        opacity: 0;
        position: relative;
        z-index: 2;
        font-weight: 300;
        font-size: 14px;
        width: 100%;
        padding: 10px 20px;
        margin-top: 100px;
        display: inline-block;
        background: rgba(255,255,255, .8);
      }
    }
  }

  .stats .column{

//    @include at-breakpoint($tab) {
//      @include divider(40px, $border-color, bottom);
//    }

    margin-top: 0px;

    li {
      margin-bottom: 40px;
    }
    span {
      background: #f7f7f7;
      color: #000;
      padding: 2px 5px;
    }
  }
  .introduction, .stats  {

    .column {
//      @include span-columns(1, 1);

//      @include at-breakpoint($tab) {
//        @include divider(30px, $border-color, top);
//        padding-top: 40px;
//      }

      margin-top: 0;
      padding-bottom: 20px;


      ul {
        padding: 20px;
        margin: 0;

        &.third {

//          @include at-breakpoint($tab) {
//            @include span-columns(1, 3);
//            @include nth-omega(4);
//          }
        }

        li {
          text-align: center;
          list-style: none;
          text-transform: uppercase;
          font-size: 1em;
          font-weight: 300;
          margin-bottom: 8px;

        }
      }
    }

  }
	.segment-container {
		.introduction {
			max-width: $inner-width;
			margin: $auto;
		}
	}

}

#google_recaptcha_user_register_form {
  margin: 0 auto;
  display: block;
  max-width: 304px;
}




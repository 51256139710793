.node--product--search {

  .flag-outer {
    display: none;
  }
  
  &:hover .flag-outer {
    display: block;
  }
  
  .node__title {
    position: static;
    padding: 40px 10px 0px !important;
    width: 100%;
    height: auto;
    z-index: 2;
  }
  
  .field--name-field-brand {
    position: absolute;
    top: 14px;
    z-index: 2;
    left: 10px;
    font-size: 0.8em;
    font-weight: bold;
  }
  
  .categories {
    padding: 0 5px 0 10px;
    margin-top: 0px;
    position: relative;
    z-index: 2;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    float: left;
    text-transform: uppercase;
//    background: $ribbon-3d-back-background-color;
    left: -5px;
//    @include ribbon($ribbon-3d-back-background-color, top, left, 5px, 5px);
  }
  
  .field--name-field-product-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    margin: auto auto;
    overflow: visible;
    
    img {
      position: absolute;
      border: none;
      height: auto;
      width: auto;
      max-width: 90%;
      max-height: 55%;
      top: auto !important;
      bottom: 20px;
      left: 0;
      right: 0;
      margin: auto auto;
    }
  }
}

.node--article--search, .node--gallery--search {
  
//  @include fadeout-vertical(80px);
  
  .field--name-field-image {
    overflow: auto;
    height: auto;
    margin-bottom: -40px;
    width: 100%;
    
    img {
      position: static;
      width: 100%;
    }
  }
  .node__title {
    position: static;
    padding: 50px 10px 0px !important;
    width: 100%;
    height: auto;
    z-index: 2;
  }
  
  .submitted {
    position: absolute;
    bottom: 15px;
    z-index: 6;
    left: 10px;
    font-size: 0.8em;
    font-weight: bold;
  }
  
  /*.categories {*/
  /*  padding: 0 5px 0 10px;*/
  /*  position: absolute;*/
  /*  bottom: 20px;*/
  /*  left: 0;*/
  /*  z-index: 2;*/
  /*  background: rgba(255, 255, 255, 0.8);*/
  /*  float: left;*/
  /*  text-transform: uppercase;*/
  /*  color: #245dc1;*/
  /*  font-size: 0.7em;*/
  /*}*/
  /**/
  /*.submitted {*/
  /*  position: absolute;*/
  /*  top: 14px;*/
  /*  z-index: 6;*/
  /*  left: 10px;*/
  /*  font-size: 0.8em;*/
  /*  font-weight: bold;*/
  /*}*/
  /**/
  /*img {*/
  /*  position: absolute;*/
  /*  width: 85%;*/
  /*  height: 100px;*/
  /*  top: auto;*/
  /*  bottom: 50px;*/
  /*  left:0;*/
  /*  right: 0;*/
  /*  margin: 0 auto;*/
  /*  z-index:1;*/
  /*}*/
}

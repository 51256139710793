.node--brand--teaser {

  .field--name-field-logo-image {
    height: 140px !important;

    img {
      height: auto;
      width: auto;

    }
  }

  .content {
//    @include span-columns(9 omega, 12);
  }

  .submitted {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
  }
}

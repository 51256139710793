#block-bd-custom-social-links-header {

  display: none;

//  @include at-breakpoint($tab) {
//    display: block;
//  }
  position: absolute;
  top: 8px;
  right: 0;
  transition: 0.2s;
  z-index: 1;

  p {
    font-size: 19px;
    margin: 0;
    // color: $bh-blue;
    
    .fa {
      margin-right: 8px;
      color: #989898;
    }
  }
  
  &.small {
    top: 80px;
  }
  
  @each $channel in $social-media-colors {
    .fa-#{nth($channel, 1)}:hover {
      color: nth($channel, 2);
    }
  }
  
  a:hover {
    
    i {
      
      // color: $bh-dark-blue;
    }
  }
}

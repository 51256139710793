.node--article--teaser, .node--industry-and-brand-news--teaser {

  .field--name-field-image {
    margin-bottom: 15px;
  }

  .content {
//    @include span-columns(9 omega, 12);
  }

  .submitted {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
  }

  .field--name-field-sell {
    font-weight: 300;
    color: #333;
    font-size: 1.05em;
    line-height: 1.5em;
    margin-bottom: 20px;
  }


  .field--name--field-sell, .field--name-body, p {
    a {
      display: inline !important;
    }
  }
}
.node-unpublished {
  background: none!important;
}

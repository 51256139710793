.field--name-field-brand-contacts {

  margin-bottom: 40px;
  
  .field--name-field-company {
    .field__item {
      font-weight: normal;
    }
  }

  
  .field--name-field-contact-categories {
//    @include sub-heading;
    border-bottom: 0;

  }
  .node__content {
    div {
      margin-bottom: 0 !important;
      font-weight: 300;
    }
  }
}

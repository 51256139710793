.view-homepage-top-stories-2 {
//  @include clearfix;
  
  .view-content {
    h3 {
      font-weight: 300;
      
      a {
        font-weight: 300;
        
      }
    }
    font-size: 1em;
    width: 33%;
    float: left;
    
    .views-row, .owl-carousel {
//      @include title-over-image();
      
      .views-field-field-image {
        
        img {
          width: 100%;
          height: auto;
        }
      }
      
      .views-field-term-node-tid {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        
        ul {
          margin: 0;
          padding: 0;
        
          li {
            float: left;
            text-transform: uppercase;
            font-size: 0.7em;
            font-weight: 300;
            list-style: none;
            padding: 5px 10px;
            background: $bh-dark-blue;
            color: #fff;
            display: none;
            
            &:first-child {
              display: block;
            }
          }
        }
      }
    }
    
  }
  .attachment-before { 
    width: 39%;
    margin-right: 2%;
    float: left;

    .view-content {
      width: 100%;
      
      h3 {
        text-transform: uppercase;
        font-size: 1.8em;
      }
    }
    
    .owl-pagination {
      position: absolute;
      bottom: 9px;
      right: 12px;
    }
    
    .owl-buttons {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      height: 210px;
      width: 100%;
      
      .owl-prev, .owl-next {
        position: absolute;
        background: none;
        
        i {
          font-size: 10em;
        }
      }
      
      
      .owl-prev {
        left: -10px;
      }
      
      .owl-next {
        right: -10px;
      }
    }
    
  }
  
  .attachment-after {
    width: 24%;
    height: 375px;
    margin-left: 2%;
    float: right;
    position: relative;
    border: solid thin #fff;
    border-bottom-color: $bh-blue;
    border-top-color: $bh-blue;
    
    .view-header {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      text-align: center;
      z-index: 1;
      
      p {
        background: #fff;
      }
    }

    .owl-pagination {
      position: absolute;
      bottom: 13px;
      right: 12px;
    }
    
    .view-content {
      width: 100%;
      
      img {
        border-bottom-right-radius: $border-radius;
      }
      
      .views-field-title {
        bottom: 2px;
        padding-bottom: 45px;
        top: auto;
        background: #fff;
        color: #000;
        
        h3 {
          color: #000;
          text-transform: uppercase;
        }
      }
    }
    
  }
}

#block-views-home-page-top-block-1, #block-views-home-page-top-block-4, .view-display-id-nz_home_slider_new {
//  @include clearfix;
  width: 100%;
  position: relative;
  margin-bottom: 3em;

  .block__title {

//    @include box-title($bh-dark-blue, #fff);

  }

  .terms {
    display: none;
//    @include at-breakpoint($tab) {
//      display: block;
//    }

    position: absolute;
    right: 0;
    top: -10px;

    ul li {
//      @include tags;
    }
  }

  .view-header {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    text-align: center;
    z-index: 1;

    p {
    }
  }

  .owl-pagination {
    position: absolute;

    bottom: -20px;

//    @include at-breakpoint($tab) {
//      bottom: 25px;
//    }
    right: 12px;
    z-index: 2;

    .owl-page {

      span.owl-numbers {
        padding: 5px 9px;
      }

      &.active span.owl-numbers {
        background: $bh-blue;
      }

    }
  }

  .view-content {
    max-width: 100%;
    #views-bootstrap-carousel-1 {
      .carousel-control.right, .carousel-control.left {
        display: block !important;
        background: none !important;
      }
    }

    .owl-wrapper-outer {
      height: 317px;

    }

    width: 100%;



//    @include at-breakpoint($tab) {
//      @include title-over-image();
//      .owl-wrapper-outer {
//        height: auto;
//      }
//    }

    img {
      margin-bottom: 10px;
      width: 100%;
      height: auto;
    }

    .views-field-title {
      bottom: 15px;

      h3 {
        /*color: #000;*/
//        @include at-breakpoint($tab) {
//          font-size: 36px;
//        }
        font-weight: normal;
        margin-bottom: 0;
        padding-bottom: 0;
        text-align: center;
        font-size: 36px;
        a {
          &:link {
            color:$link_color
          }
          &:visited {
            color: $link_visited_color;
          }
          &:hover {
            color: $link_hover_color;
            text-decoration: none;
          }
          &:active {
            color: $link_active_color;
          }
        }
      }

      h5 {
        font-size: 18px;
        margin-bottom: 25px;

//        @include at-breakpoint($tab) {
//          color: #fff;
//          margin-bottom: 5px;
//        }

      }
    }
  }

  .carousel-control .icon-prev:before {
    content: '\f053';
    font-family: FontAwesome;
  }

  .carousel-control .icon-next:before {
    content: '\f054';
    font-family: FontAwesome;
  }
  @media screen and (max-width: 480px) {
    .carousel-control .icon-prev,
    .carousel-control .icon-next,
    .carousel-control .glyphicon-chevron-left,
    .carousel-control .glyphicon-chevron-right {
      top: 30%;
    }
  }
}



#block-block-21 {

  margin-top: 40px;
  
  p {
    padding: 0;
    margin: 0;
  }

  h3 {
//    @include box-title($bh-dark-blue, #fff);
    margin-bottom: 0px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .portfolio {

    overflow: hidden;
    margin: 0 10px;

//    @include title-over-image;

    .views-field-title {
//      @include vendor-prefix(transition, 0.5s);
      left: 1px;
      bottom: -100px;
    }

    a {

      &:hover .views-field-title {
        bottom: 7px;
      }
      img {
        max-width: auto;
        width: 100%;
      }
    }
  }

}

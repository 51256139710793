section#block-views-home-page-top-block-5 {
  .flag-outer-brand-favourite {
    display: none;
  }
  h2.block-title {
    font-style: normal;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 2em;
    text-transform: uppercase;
    &::before {
      content: " ";
      display: table;
      background: #ccc;
      width: 30%;
      height: 1px;
      position: relative;
      margin-top: 20px;
      float: left;
      @media screen and (max-width: $screen-fx-max) {
        display: none;
      }
    }
    &::after {
      content: " ";
      display: table;
      background: #ccc;
      width: 30%;
      height: 1px;
      position: relative;
      margin-top: 20px;
      float: right;
      @media screen and (max-width: $screen-fx-max) {
        display: none;
      }
    }
  }
  .view-content {
    max-width: 100%;
    .col-xs-12.col-sm-6.col-md-4.col-lg-4 {
      @media screen and (max-width: $screen-xs-max) {
        width: 100%;
        float: left;
      }
      .news-wrapper {
        padding-bottom: 3em;
        padding-top: 3em;
        -webkit-box-shadow: 0px 1px 5px 1px #eeeeee;
        -moz-box-shadow: 0px 1px 5px 1px #eeeeee;
        box-shadow: 0px 1px 5px 1px #eeeeee;
        min-height: 391px;

        &:hover {
          -webkit-box-shadow: 0px 1px 22px 1px #666666;
          -moz-box-shadow: 0px 1px 22px 1px #666666;
          box-shadow: 0px 1px 22px 1px #666666;
        }

        .field-name-field-logo-image {
          img.img-responsive {
            margin: 0 auto;
          }
        }

        .field-name-field-news-type {
          padding: 10px;
          a {
            text-align: center;
            text-transform: uppercase;
            display: block;
            letter-spacing:normal;
            &:link {
              color:$link_color;
            }
            &:visited {
              color:$link_visited_color;
            }
            &:hover {
              color: $link_hover_color;
              text-decoration: none;
            }
            &:active{
              color: $link_active_color;
            }
          }
        }

        .views-field-title {
          padding: 10px;
          a {
            @include headH();
            text-transform: uppercase;
            text-align: center;
            display: block;
            font-weight: bolder;
            &:link {
              color:$link_color;
            }
            &:visited {
              color:$link_visited_color;
            }
            &:hover {
              color: $link_hover_color;
              text-decoration: none;
            }
            &:active{
              color: $link_active_color;
            }
          }
        }

        .views-field-create {
          padding: 10px;
          display: block;
          text-align: center;
          color: #b7b7b7;
          font-size: 0.8em;
        }
      }
    }
  }
}

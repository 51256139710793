header {
	.container-fluid {
		> .navbar-collapse {
			padding: 0px;
		}
	}
	nav {
		// border: solid thin $border-color;
		position: relative;
		.region-navigation {
			max-width: 1168px;
			_width: 1168px;
			padding-left: 5px;
			padding-right: 5px;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			@include clearfix;
			section#block-tb-megamenu-main-menu {
				position: relative;
				max-width: 870px;
				_width: 870px;
				padding-left: 5px;
				padding-right: 5px;
				margin-left: auto;
				margin-right: auto;
				//float: left;
				//margin: 0 auto;
				@media screen and (min-width: 980px) {
					margin-left: 20%;
				}
			}
			section#block-search-form {
				position: relative;
				//max-width: 200px;
				//_width: 200px;
				padding-left: 5px;
				padding-right: 5px;
				margin-left: auto;
				margin-right: auto;
				float: right;
				clear: right;
				//padding-bottom: 2em;
				//padding-top: 1em;
				@media screen and (min-width: 980px) {
					max-width: 500px;
				}
				@media screen and (max-width: $screen-sm-max) {
					margin-bottom: 1em;
				}
			}
		}
	}


	.region-branding {
		@include clearfix;
	}
	.media {
		margin: 0px;
	}
	.view-megamenu {
		.view-header {
			margin: 20px 0px;
		}
	}
}

.collapse {
	display: block;
}
.tb-megamenu .dropdown-menu .active > a,
.tb-megamenu .dropdown-menu .active > a:hover {
	color: #000;
}
//fix unclickable parent menu
@media (min-width: 768px) {
	.dropdown:hover .dropdown-menu {
		display: block;
	}
}

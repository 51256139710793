.node--experts--search {
 
  img {
     position: absolute;
     height: auto;
     max-width: 85%;
     top: 0 !important;
     bottom: 0;
     left: 0;
     right: 0;
     margin: auto auto;
   }
 }


#block-views-in-this-section-block, #block-views-upcoming-events-block-1 {

//  @include background-image(linear-gradient(left, #fff 0%, #fdfdfd 100%));


  .block__title {
    padding-top: 40px;
    margin-bottom: 20px !important;
  }

  .views-exposed-form {
    select {
      width: 100%;
    }

    .views-exposed-widget {
      margin-bottom: 0;
    }
  }

  .active {
    color: $bh-blue;
  }

  .active-trail {
    background: #f7f7f7;

    .views-field-title .field-content::after {
      content: "Now Reading";
//      @include tags();
      background-color: #ccc;
      margin: 3px 30px 0 0;
      color: #999;
    }
  }

  .attachment-after {

    .block__title {
      margin-top: 40px;
      margin-bottom: 20px !important;
    }

    .views-row {

      .views-field-nothing {
        font-size: 0.9em;
        font-weight: bold;
        color: #666;
      }

      padding: 10px;
      background: #f7f7f7;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 10px !important;
      }
    }

  }

  .views-field.views-field-title {
    font-size: 0.9em;
    font-weight: bold;
    color: #666;
    float: left;
    text-transform: uppercase;
    padding-right: 20px;
//    @include span-columns(2 omega, 3);
  }

  .views-field.views-field-field-image {
//    @include span-columns(1, 3);
    float: left;

    img {
      width: 100px;
      height: 70px;
    }
  }

  .view .view-content .views-row {
    padding: 15px 0;
    min-height: 115px;
    margin: 0;
    border-bottom: solid thin #eee;
    position: relative;

    .views-field-term-node-tid {
      ul {

        li {
          display: none;
//          @include tags();

          &:first-child {
            display: block;
            background: $bh-blue;
            color: #fff;
            position: absolute !important;
            top: 5px;
            left: -5px;
          }
        }
      }
    }
  }
}

.node--event--full {

  ul {
    li {
      list-style: disc;
      margin-bottom: 15px;
    }
  }

  ol {
    li {
      list-style: decimal;
      margin-bottom: 15px;
    }
  }

  .field--name-field-event-category {

    .field__item {
//      @include box-title($bh-blue, #fff);
      text-transform: uppercase;
    }
  }

  .field--name-field-gallery {
//    @include divider(20px, $border-color, top);

    .jb-cap-frame .jb-caption {
      background: #000 !important;
      text-shadow: none;
      color: #fff !important;
    }
  }

  .location.vcard {
    display: inline-block;
    vertical-align: top;

    .adr {
      font-weight: normal;

      .fn {
        display: block;
      }

      .country-name {
        display: none;
      }
    }
  }

  h3.field-label {
//    @include sub-heading;
    width: 100%;
    margin-top: 60px;
  }

  .field-label {
    min-width: 60px;
    display: inline-block;
  }
}

body.page-event-calendar {
  .calendar-calendar .month-view .full td.date-box .inner, .calendar-calendar .week-view .full td.date-box .inner {
    min-height: inherit !important;
  }
  .calendar-calendar .month-view .full tr td.date-box {
    height: unset;
    max-height: 100px;
  }
  .calendar-calendar .month-view .full tr td.multi-day {
    height: 58px;
    max-height: 58px;
  }
  .calendar.monthview {
    .views-field-title {
      h3.field-content {
        font-size: 1.17em;
        margin: 0px;
        a {
           padding: 0px;
        }
        &:link {
          color: $link_color;
        }
        &:visited {
          color: $link_visited_color;
        }
        &:hover {
          color: $link_hover_color;
        }
        &:active {
          color: $link_active_color;
        }
      }
    }
  }
  .calendar-calendar .month-view .full .calendar.monthview {
    background: #ffe5e3;
  }

  section#block-quicktabs-beautydiary-home-page {
    > h2.block-title {
      display: none;
      @media screen and (max-width: $screen-fx-max) {
        display: block;
      }
    }
    .view-content {
      width: 90%!important;
    }
    ul.pager {
      @media screen and (max-width: $screen-fx-max) {
        display: block!important;
      }
      li.pager-previous {
        left: 10px;
      }
      li.pager-next {
        left: 10px;
      }
    }
  }
  .view-upcoming-events {
    .owl-carousel {
      position: relative;
      .owl-controls {
        position: absolute;
        width: 100%;
        top: 0px;
        .owl-prev {
          position: absolute;
          left: -30px;
          top: 4em;
        }
        .owl-next {
          position: absolute;
          right: -30px;
          top: 4em;
        }
        div {
          background: none!important;
          color: #000000!important;
          font-size: 30px!important;
          opacity: 1!important;
          .fa {
            font-weight: bold!important;
          }
        }
      }
    }
  }
}
.node-type-event .node-event {
  h1 {
    text-align: left;
    margin-bottom: 1em;
    font-weight: 500;
    padding-bottom: 0;
    border-bottom: none;
    font-size: 2em;
  }
  .field-name-field-location {
    display: inline-block;
  }
  span.field-label {
    display: inline-block;
    font-weight: bold;
    min-width: 60px;
  }
  .location.vcard {
    display: flex;
    .adr {
      display: inline-flex;
    }
    .locality, .country-name {
      margin-left: 10px;
    }
  }
  .field-name-field-gallery {
    margin: 2em;
    border-top: solid thin #dddddd;
    padding-top: 20px;
    border-bottom: solid thin #dddddd;
    padding-bottom: 20px;
  }
  h3 {
    border-bottom: solid thin #dddddd;
    padding-bottom: 20px;
  }
}

// Hide the toolbar or admin menu on small screens.
@media only screen and (min-width: 768px) and (max-width: 991px){
	#admin-menu {
		display: none !important;
	}
	.contextual-links-region {
		position: static;
	}

	html {
		body.toolbar, body.admin-menu {
			padding-top: 0 !important;
			margin-top: 0 !important;
		}
	}
}

.not-logged-in #block-tb-megamenu-main-menu {
	display: none;

//	@include at-breakpoint($tab) {
//		display: block;
//	}
}

#block-tb-megamenu-main-menu {
	position: absolute;
	top: 0;
	left: 0;

//	@include at-breakpoint($tab) {
//		position: relative;
//	}
}

.not-logged-in {
	#block-tb-megamenu-main-menu {
		margin-left: auto;
		margin-right: auto;
		max-width: 600px;
    /*margin-top: 20px;*/
	}
}


.media .tb-megamenu-row.row-fluid:nth-child(2) {
	background: black;
	border: solid thin #000 !important;
	padding-top: 0;

	.tb-megamenu-column-inner {
		border: none;
	}
}

.expert-panel {
	background: $bh-blue;
	color: #fff;
	a {
		color: #fff !important;
		&:hover {
		}
	}
}



.l-nav {
	overflow: visible;
	padding: 0px;
	width: 100%;
	transition: 0.2s;
	z-index: 99999999999;
	background: #fff;
	//  margin-bottom: 30px;
	//  padding-bottom: 5px;
	//  box-shadow: #f3f3f3 0 6px 31px 2px;


}

.l-nav.fixed {
	top: -106px - $categories !important;
	opacity: 1;
	box-shadow: rgba(0, 0, 0, 0.2) 0 5px 14px;
}

.l-region--navigation {

	//  display: none;
	//  position: absolute;
	//  top: 2px;
	//  left: 2px;

//	@include at-breakpoint($tab) {
//		display: block;
//		position: relative;
//	}
	margin-bottom: 0px !important;

}

.l-region--navigation.logo-spacing {
	margin-left: 200px;
}

#block-tb-megamenu-main-menu {
	margin-bottom: 0 !important;
	width: 100%;

	transition: 0.2s;

	&.small {
		margin-left: 200px;
	}
}

#block-views-megamenu-block {

	.view-header p {
		font-size: 1.5em;
		line-height: 0.3em;
	}
}

.view-megamenu {
	.attachment-before, .attachment-after, .view-content {
		.views-row {

			width: 200px;
			//      @include span-columns(1, 4);
			//      @include nth-omega(5);
			padding: 10px 0;
			position: relative;

			.views-field-title {
				height: 34px;
				display: table-cell;
				vertical-align: middle;
			}

			img {
				width: 100%;
				height: auto;
			}

			a {
				color: #222;
			}

			&:hover {
			}

			a:hover {
				text-decoration: none;
				color: #000;
			}
		}
	}
	.view-id-megamenu {
		float: left;
		margin-right: 20px;
		&:last-child {
			margin-right: 0px;
		}
	}
}

.tb-megamenu {
	@include bh-gradient-2();
	background: inherit;
	border: none;
	font-size: 15px;
	color: #000;
	text-transform: uppercase;
	button {
		#menu_toggler {
			display: inline-block;
			vertical-align: middle;
			color: #e92132;
			font-size: 16px;
			font-weight: 700;
			cursor: pointer;
			.mobile-menu-bar {
				width: 30px;
				height: 27px;
				display: block;
				margin: 0 auto;
				span {
					float: left;
					width: 100%;
					background: #000000;
					height: 5px;
					margin: 3px 0
				}
				.line {
					-webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
					transition: all .25s cubic-bezier(.645, .045, .355, 1);
					-webkit-transition-timing-function: cubic-bezier(.645, .045, .355, 1);
					transition-timing-function: cubic-bezier(.645, .045, .355, 1);
					background: $bd_pink;
					border-radius: 3px;
					-moz-border-radius: 3px;
					-webkit-border-radius: 3px;
				}
				.line-1 {
					top: 0;
				}
				.line-2 {
					top: 50%;
				}
				.line-3 {
					bottom: 0;
				}
			}
		}
	}
	.btn-navbar:hover,
	.btn-navbar:focus,
	.btn-navbar:active,
	.btn-navbar.active,
	.btn-navbar.disabled,
	.btn-navbar[disabled],
	.btn-navbar {
		background: none;
		border: 0px;
		box-shadow: none;
		cursor: none;
	}
	.is-active {
		.mobile-menu-bar {
			.line-1 {
				-webkit-transform: translateY(12px) translateX(0) rotate(45deg);
				-ms-transform: translateY(12px) translateX(0) rotate(45deg);
				transform: translateY(12px) translateX(0) rotate(45deg);
			}
			.line-2 {
				opacity: 0;
			}
			.line-3 {
				-webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
				-ms-transform: translateY(-10px) translateX(0) rotate(-45deg);
				transform: translateY(-10px) translateX(0) rotate(-45deg);
			}
		}
	}
}

.tb-megamenu-submenu {
	width: 100%;
}

.tb-megamenu-column-inner {
	border: solid thin $border-color;
	border-top: none;
	padding: 0 !important;
	font-size: 14px;
	width: 100%;

	.attachment-before {
		.view-id-megamenu {
			position: relative;
//			@include divider(20px, #eee, right);

			&:last-child {
//				@include clear-divider(right);
				padding-right: 10px;

			}
			.view-header p {
			}

			.view-footer {
				position: absolute;
				bottom: 0;

				p a {
					padding: 5px 10px;
					background: #f7f7f7;
					width: 100%;
				}
			}
		}
	}

	.categories {
		float: left;
		background: #000;
		color: #fff;
		margin-right: 20px;
//		@include span-columns(1, 5);
		box-shadow: #ccc 6px 0 9px -4px;

		li {
			color: #fff !important;
			padding-top: 0px !important;
			padding-bottom: 0px !important;
			line-height: 10px;
			border-bottom: solid thin #333 !important;

			&:last-child {
				border-bottom: 0 !important;
				margin-bottom: 0 !important;
			}

			a {
				color: #fff !important;
				padding-left: 5px !important;
				text-transform: uppercase !important;
				font-size: 12px;
				font-weight: normal !important;

				&:hover {
					color: #fff !important;
					font-weight: bold;
				}
			}
		}
	}
}

.tb-megamenu .btn-navbar {
	margin-bottom: 0;
}

.tb-megamenu .nav > li {
	padding: 0 16px;
	&:first-child {
		padding-left: 0;
	}
	&:last-child {
		padding-right: 0;
	}
}

.tb-megamenu .row-fluid [class*="span"] {
	margin-left: 0 !important;
}

.tb-megamenu .nav > li > a {
	border-color: $nav-background;
	color: #000;
	text-decoration: none;
	padding: 15px 0px 13px;
	border-bottom: solid 2px #fff;
  font-weight:500 /* Medium */
}

.tb-megamenu .nav > li > a:focus,
.tb-megamenu .nav > li > a:hover {
	/*color: $main-nav-hover;*/
	/*background: $main-nav;*/
	/*opacity: 1;
	background: darken($main-nav-hover, 5%);*/
	border-bottom: solid 2px $bh-blue;
	color: #000;
}

.tb-megamenu .nav > .active > a,
.tb-megamenu .nav > .active > a:hover,
.tb-megamenu .nav > .active > a:focus {
	background-color: #fff;
	color: #333;
	border-bottom: solid 2px $link_hover_color;
}

.tb-megamenu .nav-collapse .nav > li.active > a:hover,
.tb-megamenu .nav-collapse .nav > li.active > a:focus {
	background-color: #fff;
	color: #333;
	border-bottom: solid 2px $bh-blue;
}

.tb-megamenu .row-fluid + .row-fluid {
	border-color: $main-nav;
}

.tb-megamenu .dropdown .caret {
	display: none !important;
	border-top-color: $main-nav !important;
	margin-left: 5px;
	margin-top: 6px !important;
}

.tb-megamenu .dropdown-menu {
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	height: auto !important;
	margin-top: 2px;
}

.tb-megamenu .dropdown-menu a {
	white-space: normal !important;
}

.tb-megamenu .dropdown-menu .item-list li {
	border-bottom: solid thin $border-color !important;
}

.tb-megamenu .dropdown-menu li > a {
	/*border-top: 1px solid #444;*/
	padding: 10px 20px !important;
	text-shadow: none;
	/*font-weight: 300 !important;*/
	color: #333;
	display: block !important;

}

.tb-megamenu .dropdown-menu li:first-child > a {
	border-top: 0;
}

.tb-megamenu .navbar .nav > li > .dropdown-menu:before {
	/*display: none;*/
}

.tb-megamenu .navbar .nav > li > .dropdown-menu:after {
	/*display: none;*/
}

.tb-megamenu .dropdown-menu .disabled > a,
.tb-megamenu .dropdown-menu .disabled > a:hover {
	color: #999;
}

.tb-megamenu .dropdown-menu .disabled > a:hover {
	background-color: transparent;
}

.tb-megamenu .nav li.dropdown.open > .dropdown-toggle {
	background-color: #fff;;
	color: #aaa;
	border-color: $main-nav-border;
}

.tb-megamenu .nav li.dropdown.open > .dropdown-toggle {
	background-color: #fff;;
	color: #aaa;
	border-color: $main-nav-border;
}

.tb-megamenu .nav li.dropdown.active > .dropdown-toggle {
	background-color: $main-nav-border;
	color: $main-nav;
	border-color: $main-nav-border;
}

.tb-megamenu .dropdown-submenu:hover > a, .tb-megamenu .dropdown-menu .active > a {
	background: none !important;
}

.tb-megamenu .nav > li.dropdown.open.active > a:hover,
.tb-megamenu .nav > li.dropdown.open.active > a:focus {
	color: $main-nav;
	background: $main-nav-border;
	border-color: $bh-blue;
	border-right-color: $main-nav-border;
}

.tb-megamenu .dropdown-menu {
	background-color: #fff;
	border: #ccc;
}

.tb-megamenu .dropdown-menu li > a {
	color: $main-nav;
	border-top: 1px solid #fff;
}

.tb-megamenu .dropdown-submenu:hover > a {
	background: $bh-blue !important;
	color: $link_hover_color !important;
}

.tb-megamenu .dropdown-menu .mega-dropdown-inner .tb-megamenu-column  .mega-inner .tb-megamenu-subnav li > a {
  background: #fff !important;
  &:link {
    color: $link_color;
  }
  &:visited {
    color: $link_visited_color;
  }
  &:hover {
    color: $link_hover_color;
  }
  &:active {
    color: $link_active_color;
  }

}

.tb-megamenu .nav li.dropdown.open > .dropdown-toggle,
.tb-megamenu .nav li.dropdown.open.active > .dropdown-toggle {
	background-color: #fff;
	border-color: #fff;
	color: $main-nav;
	border-bottom: solid 2px #000;
}

.tb-megamenu .mega-nav .mega-group > .mega-group-title,
.tb-megamenu .dropdown-menu .mega-nav .mega-group > .mega-group-title,
.tb-megamenu .dropdown-menu .active .mega-nav .mega-group > .mega-group-title {
	color: #ddd;
}

.tb-megamenu .tb-block {
	background: transparent;
	color: inherit;
}

.tb-megamenu .tb-block .block-inner {
	padding: 0;
}

.tb-megamenu .tb-block .block-title {
	background: inherit;
	color: #ddd;
}

.tb-megamenu .block-ct {
	background: inherit;
	color: inherit;
	padding: 0;
	/**zoom: 1;*/
}

.tb-megamenu .block-ct:before,
.tb-megamenu .block-ct:after {
	display: table;
	content: "";
	line-height: 0;
}

.tb-megamenu .block-ct:after {
	clear: both;
}

.tb-megamenu .tb-block ul.unstyled,
.tb-megamenu .tb-block ol.unstyled,
.tb-megamenu .tb-block ul.nav,
.tb-megamenu .tb-block ol.nav {
	margin-left: 0;
}

.tb-megamenu .block_menu .nav {
	margin-bottom: 20px;
}

.tb-megamenu .block_menu .nav > li {
	border-top: 1px solid #444;
}

.tb-megamenu .block_menu .nav > li > a {
	padding: 5px;
}

.tb-megamenu .block_menu .nav > li:first-child {
	border-top: 0;
}

.tb-megamenu .block_menu .nav > li:first-child > a {
	padding-top: 0;
	border-top: 0;
}

//// workaround for gated news (show/hide based on domain)
//.tb-megamenu-submenu .view-megamenu .view-id-megamenu .item-list {
//  max-height: 430px;
//  overflow: hidden;
//}


@media (max-width: 979px) {
	.tb-megamenu {
		background-image: none;
		background-color: inherit;
	}

	.tb-megamenu .nav-collapse,
	.tb-megamenu .nav-collapse .dropdown-menu {
		background-color: white;
	}
	.tb-megamenu .nav-collapse .nav > li > a,
	.tb-megamenu .nav-collapse .dropdown-menu a {
		border-bottom-color: #999;
		border-top-color: #999;
		color: #000;
		background: #ffffff;
	}
	.tb-megamenu .nav > li > a {
		color: #000;
		&:first-child {
			border-left: solid thin rgba(255, 255, 255, 0.2) !important;
		}
	}
}

@media (max-width: 738px) {

	.tb-megamenu .nav-collapse .nav li a {
		border-top-color: #444;
	}
	.tb-megamenu .nav-collapse .nav > li > a,
	.tb-megamenu .nav-collapse .dropdown-menu a {
		border-bottom: none;
		border-top-color: #999;
		color: #000;

	}
	.tb-megamenu .row-fluid + .row-fluid {
		border-top-color: #444;
	}
}

.block-view-demo .views-field-field-image img {
	border: 1px solid #ccc;
}

.block-view-demo .views-field-title a:hover,
.block-view-demo .views-field-title a:focus {
	color: $main-nav;
	min-height: 35px
}

.tb-megamenu .mega-align-justify > .dropdown-menu {
	margin-top: 0px;
	border-top: solid thin #ddd;
}

.navbar-collapse nav ul.menu {
	display: none !important;
}

.none-display {
	display: none !important;
}

@media (max-width: 979px) {
	//li.dropdown {
	//	.tb-megamenu-submenu {
	//		.mega-dropdown-inner {
	//			section#block-views-megamenu-block {
	//				.view-megamenu {
	//					.attachment {
	//						display: none;
	//					}
	//					.view-empty {
	//						.categories {
	//							float: none;
	//							background: none;
	//							color: #000;
	//							margin-right: 0px;
	//							position: relative;
	//							/* box-shadow: #ccc 6px 0 9px -4px; */
	//							li {
	//								color: #000000!important;
	//								display: inline-block;
	//								width: 40%;
	//								border: 0px !important;
	//								display: inline-block;
	//								width: 40%;
	//								border-top: solid 1px #000 !important;
	//								/* border-left: 0px !important; */
	//								/* border-right: 0px !important; */
	//								margin-left: 5%;
	//								a {
	//									&::before {
	//										display: none;
	//									}
	//								}
	//							}
	//						}
	//					}
	//				}
	//			}
	//		}
	//	}
	//}
	.tb-megamenu .nav-collapse .nav li:first-child {
		padding: 0 16px;
	}
	.tb-megamenu-subnav {
		li:first-child {
			padding: 0px!important;
		}
	}
	.tb-megamenu {
		li.tb-megamenu-item {
			&:hover {
				background: none!important;
			}
			a {
				&:hover {
					background: none!important;
				}
			}
		}
		.tb-megamenu-column-inner {
			border: 0px!important;
			.categories li {
				border: 0px!important;
			}
		}
		li {
			a {
				padding-left: 2em !important;
			}
		}
		li.showme {

			//hiding the news megamenu

			section#block-views-megamenu-block {
				.attachment-after {
					display: none;
				}
				.categories {
					float: none!important;
					background: none!important;
					margin-right: 0px!important;
					box-shadow: none!important;
					li:first-child {
						padding: 0px!important;
					}
				}
			}
			a.dropdown-toggle {
				&::before {
					color: $bd_pink!important;
					content: "";
				}
			}
      .mega-dropdown-menu {
				display: block!important;
				li + li a {
					margin-left: 0px;
					font-size: inherit;
				}
				a {
					color: #000000 !important;
					font-size: inherit;
					&::before {
						background: none!important;
						color: #000000!important;
					}
				}
			}
			a {
				color: $bd_pink!important;
				&::before {
					background: no-repeat url(../../../custom/beautydirectory/assets/images/angle-down-solid.svg)!important;
					color: $bd_pink!important;
					content: "";
					display: none;
				}
			}
		}

		li.tb-megamenu-item {
			margin-bottom: 13px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-moz-box-orient: vertical;
			-webkit-box-direction: normal;
			-moz-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-align: baseline;
			-moz-box-align: baseline;
			-ms-flex-align: baseline;
			align-items: baseline;
			position: relative;
			padding: 0 16px 5px 16px;
			height: 30px;
			&:hover .tb-megamenu-submenu {
				display: none;
			}

			a {
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				-ms-flex-negative: 0;
				flex-shrink: 0;
				-ms-flex-preferred-size: auto;
				flex-basis: auto;
				margin-bottom: 0;
				display: flex;
				width: 80%;
				font: 600 22px/1.2 "latomedium";
			}
		}
		li.arrow-drop {
			position: relative;
			&::after {
				content: "\f107";
				font-family: FontAwesome;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				height: 18px;
				margin-left: auto;
				font-size: 4rem;
				margin-right: auto;
				position: absolute;
				right: 15px;
				cursor: pointer;
			}
		}
		li.hide-this {
			&::after {
				content: "\f106" !important;
			}
			.tb-megamenu-submenu {
				ul.items-1 {
					li.tb-megamenu-item {
						margin-bottom: 0px;
						a {
							font-size: 100% !important;
							font-style: normal !important;
						}
					}
				}
			}
		}
		li.is-active-drop {
			.tb-megamenu-submenu {
				display: block;
			}
		}
	}
}
//dropdown megamenu extra overrides

@media screen and (max-width: $screen-fx-max) {
	li.arrow-events {
		.dropdown-menu {
			ul.mega-nav {
				li {
					padding: 0px !important;

					a {
						margin-left: 20px;
					}
				}
			}
		}
	}
	a.dropdown-toggle {
		&:hover {
			background: #ffffff!important;
		}
	}
	li.arrow-news {
		.dropdown-menu {
			border-top: 0px!important;
			.view-megamenu {
				.categories {
					ul {
						li {
							a {
								padding: 10px 50px !important;

								&:hover {

									color: $bd_pink;
								}
							}
						}
					}
				}
			}
		}
	}
	li.arrow-brands {
		ul.tb-megamenu-subnav {
			li {
				a {
					/*font-style: normal !important;
					font: 100 12px 'latomedium';
					font-weight: normal;*/
					margin-left: 20px;
				}
			}
		}
		.view-footer {
			ul {
				li {
					a {
						margin-left: 20px!important;
					}
				}
			}
		}
	}
}




body.page-directory-media-directory {
  aside {
    .well {
      h2.block-title {
        color: $bd-brand;
      }

      .facetapi-facetapi-checkbox-links {
        padding: 0px;

        li {
          margin-bottom: 10px;

          .facetapi-checkbox {
            color: #000;
            font-size: 14px;
            //margin-left: .5em;
          }
          input[type="checkbox"] {
            margin-right: 1em;
          }
        }
      }
    }
  }

  article h2::after, article h2::before {
    width: 20%;
  }
  .col-sm-9 {
    #block-system-main {
      em {
        margin-bottom: 1em;
        display: inline-block;
      }
    }
  }
}

#admin-menu .admin-menu-search input {
  height: 19px !important;
}

.hidden {
  display: none;
}

.fixed {
  position: fixed !important;
  margin: 0 !important;
}

img[data-src] {
  opacity: 0;
  transition: opacity .8s ease-in;
}


.messages {

//  @include at-breakpoint($tab) {
//    position: fixed;
//    bottom: 0;
//    border-bottom: none;
//    left: 0;
//    right: 0;
//    margin: 0 auto !important;
//    width: 32%;
//    z-index: 9999999;
//  }
}

.tabs--primary.tabs--primary {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 300px;
  border-bottom: 0;
  z-index: 999999999999;
  $selectedColor: $border-color;
  li.active a {
    background: $selectedColor !important;
    position: relative;
    color: #666;
  }

  li {
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 300;

    a {
      background: #fff;
      border: solid thin $border-color;
      border-right-width: 0;
      color: #000;
      padding: 5px 20px;

      &:hover {
        text-decoration: none;
        background: #f9f9f9;
      }
    }

    &:last-child > a {
      border-right-width: thin;
    }
  }
}



#admin-menu-wrapper {
  // z-index: 9999999999;
}

body, body.admin-menu.logged-in {
//  margin-top: 158px + $categories !important;
  word-wrap: break-word;
}

.l-content {

//  @include at-breakpoint($tab) {
//    min-height: 1000px;
//  }

  position: relative;
}

#admin-menu {
  // z-index: 9999999999 !important
}

.l-main {
  position: relative;
}

body:not(.node-type-product) .l-content {
  h1 {
  }
}

.l-content .view-header {
  border-bottom: thin solid $border-color;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
//
//.flag {
//  height: 19px;
//  width: 40px;
//  float: right;
//  background-size: 40px;
//  display: inline-block;
//  margin-top: 5px;
//  margin-right: 30px;
//  display: none;
//}
//
//
//.flag-au {
//  background-image: url("/sites/all/themes/bd/images/au-flag.jpg");
//}
//.flag-nz {
//  background-image: url("/sites/all/themes/bd/images/nz-flag.gif");
//}
//
//

#block-block-9 > p {
    text-align: center;
}

#block-bd-custom-bd-user-extras {
    text-align: right;
    margin-top: -24px;
    font-size: 12px;
    font-weight: 600;

    .username-block {
        display: inline-block;

        .username-image {
            display: inline-block;
            vertical-align: bottom;
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
    }
    .top-logout {
        text-align: right;
        text-transform: uppercase;

        &:before {
            content: ' ';
            border-left: 1px dotted black;
            margin: 0 15px;
        }
    }
}

.page-event-calendar {
  .multi-day {
    a {
      font-size: 0.8rem;
      font-weight: 500;
      letter-spacing: unset;
    }
  }
  h2.block-title {
    &::before {
      content: " ";
      display: table;
      background: #ccc;
      width: 30%;
      height: 1px;
      position: relative;
      margin-top: 20px;
      float: left;
      @media screen and (max-width: 979px) {
        width: 20%!important;
      }
    }
    &::after {
      content: " ";
      display: table;
      background: #ccc;
      width: 30%;
      height: 1px;
      position: relative;
      margin-top: 20px;
      float: right;
      @media screen and (max-width: 979px) {
        width: 20%!important;
      }
    }
  }

  .calendar-calendar {
    margin-bottom: 40px;
  }

  h1 {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .pager {
    clear: none;
    float: right;
    margin: 5px;

  }

  .view-header {
//    @include clear-divider(bottom);
    margin-bottom: 20px !important;
  }

  .date-heading {
    text-align: left !important;

    h3 {
//      @include box-title(#000, #fff);
      font-weight: normal;
      text-transform: uppercase;
      float: left;
    }
  }
  .view-upcoming-events {
    position: relative;
    .views-field-nothing {
       padding: 0px 10px;
      .recent-wrapper {
        display: block;
        border: solid thin #eeeeee;
        min-height: 336px;
      }
    }
    .view-content {
      max-width: 100%;
      .views-field-field-image {
        float: none!important;
        img.img-responsive {
          width: 100% !important;
          height: auto!important;
          display: block!important;
          width: 100%;
        }
      }
      .views-field-title {
        float: none !important;
        display: block!important;
        color: #000;
        a {
          //text-transform: uppercase;
          //text-align: center;
          display: block;
          padding: 2em 10px;
          font-size: 0.8rem;
          font-weight : 500;
          letter-spacing: unset;
          &:link {
            color: $link_color;
          }
          &:visited {
            color: $link_visited_color;
          }
          &:hover {
            text-decoration: none;
            color: $link_hover_color;
          }
          &:active {
            color: $link_active_color;
          }
        }
      }
      .views-field-city {
        text-transform: uppercase;
        font-family: 'latoheavy';
        padding-top: 8px;
      }
      .views-field-field-event-start-date {
        float: none !important;
        display: block!important;
        padding: 0px 0px .5em;
      }
    }
    nav.date-views-pager {
      h3 {
        position: absolute;
        top: 0px;
        left: 42%;
        color: $pitch;
        text-transform: uppercase;
        font-family: 'latomedium';
        font-size: 1.3em;
      }
      ul.pagination.pull-right {
        float: none !important;
        position: relative;
        width: 100%;
        margin: 60px 0px;
        li {
          a {
            background: none;
            color: #777;
            border: 0px;
            text-transform: uppercase;
            text-decoration: underline;
            span.glyphicon {
              display: none;
            }
          }
        }
        li.prev {
          float: left;
          left: 0px;
          margin-top: -40px;
        }
        li.next {
          float: right;
          right: 0px;
          margin-top: -40px;
        }
      }
    }
  }

  //calendar module overrides
  .calendar-calendar .month-view .full td .inner div.day {
    font-family: 'latoheavy';
  }
  .calendar-calendar th.days {
    background: none;
    border: none;
    text-transform: uppercase;
    font-family: 'latoheavy';
    letter-spacing: 3px;
    padding-bottom: 10px;
  }
  .calendar-calendar td.empty {
    color: #777;
  }
  .calendar-calendar .month-view .full tr td.single-day.today,
  .calendar-calendar .month-view .full tr td.today,
  .calendar-calendar .month-view .full td.date-box.today {
    border-bottom-color: #f7796d;
    border-color: #f7796d;
  }

}
.page-event-review {
  .view-upcoming-events {
    .view-content {
      max-width: 100%;
      .node-event {
        display: block;
        border: solid thin #eeeeee;
        min-height: 408px;
        margin-bottom: 3em!important;
        padding-top: 0px !important;
        .submitted, span.date-display-single {
          display: block!important;
          text-align: center;
          color: #777;
          font-style:normal;
        }
        h2 {

          text-align: center;
          display: block;

          padding: 0px 10px 2em;
          letter-spacing: 2px;

          a {
            &:link {
              color: $link_color;
            }
            &:visited {
              color: $link_visited_color;
            }
            &:hover {
              text-decoration: none;
              color: $link_hover_color;
            }
            &:active {
              color: $link_active_color;
            }
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
}


.front {
  .view.view-upcoming-events {
    height: 490px;
    overflow-y: scroll;
    overflow-x: hidden;

    // skin the ugly scrollbar
    &::-webkit-scrollbar-track {
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb  {
      background-color: #000000;
    }

    @media screen and (max-width: 979px) {
      //on mobile, show only top 4 items to show MORE button immediately
      li.list-group-item:nth-of-type(4) ~ li {
        display: none;
      }
      overflow-y: visible;
      height: 100%;
    }
  }
}


.view-id-upcoming_events {
  .recent-wrapper {
    .views-field-title {
      min-height: 120px;
    }
    .date-display-single {
      padding-left: 10px;
    }
  }
}

body.page-event-calendar .view-upcoming-events .owl-carousel .owl-controls {
  .owl-prev,
  .owl-next {
    top: 2em;
  }
}

.node--experts--full, .node--pr-resources--full, .node--media--full, .node--media-staff--full, .node--pr--full {
  width: 100%;

  h3 {
    //    @include box-title(#000, #fff);
//    @include sub-heading;
    border-bottom: none;
    margin-bottom: 0;
    font-weight: 300 !important;
    width: 100%;
    font-weight: bold;
  }

  ul {
    li {
      list-style: disc;
      margin-bottom: 15px;
      margin: 20px;
    }
  }

  .field-group-format-wrapper {
    border-top: solid thin $border-color;
    padding: 20px;
  }

  .field--name-field-social-media {
    display: inline-block;
  }

  .field--name-field-email {
    .field-items {
      display: inline-block;
    }
  }
  .field--name-field-media-type {
      display: inline;
  }

  .entity-paragraphs-item {
    clear: left;
    .field-label {
      display: inline-block;
      clear: left;
    }
    .field {
      display: inline-block;
    }
  }
  .field--type-paragraphs {
    float: none;
    clear: both;
    display: block;
  }

  .field--name-field-website-link {
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 0;

    > li {
      margin: 0;
      list-style: none;
    }
  }

  .field--name-field-media-team, .field--name-field-pr-staff {
    padding-left: 20px;
    padding-top: 20px;
//    @include span-columns(12, 12);

    .media-content {

//      @include span-columns(10 omega, 12);
      padding-left: 20px;
    }

    .field--name-field-image {
//      @include span-columns(2, 12);
      img {
        height: auto;
      }
    }

    .field--name-field-brand {
      float: left;
      margin-right: 20px;
    }

  }

  .field-label {
    min-width: 120px;
    text-align: left;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    line-height: 1.5em;
  }

  .location.vcard {
    display: block;

//    @include at-breakpoint($tab) {
//      display: inline-block;
//
//      &:nth-child(n+3) {
//        display: block;
//        margin-left: 134px
//      }
//    }

    > div {
      margin-bottom: 10px;
      font-weight: 300;

    }
  }

  .group-contact-details, .group-statistics, .group-products-details {
//    @include span-columns(8, 12);
    font-weight: 300;
    //    font-size: 0.9em;
    margin-bottom: 20px;
    white-space: nowrap;
  }

  .group-statistics {
    margin-bottom: 40px;
  }

  .field--name-field-pr-staff, .field--name-field-services, .field--name-field-target-audience, .field--name-field-media-team, .field--name-body {
    border-top: solid thin $border-color;
    > p {
      padding-left: 20px;
    }
    margin-bottom: 40px;
    font-weight: 300;
  }

  .field--name-field-services {
    padding: 20px 0 40px 20px;
    margin: 0px;

    li {
      list-style: none;
      &:before {
        content: " - ";
      }

      span {
        display: inline-block;
        min-width: 120px;
        margin-right: 10px;
        font-weight: bold;
      }
    }
  }

  .field--name-field-questionaire {

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        margin: 0;

        span {
          display: inline-block;
          min-width: 120px;
          margin-right: 10px;
          font-weight: bold;
        }
      }
    }
  }

  > .field--name-field-image {

//    @include span-columns(12 omega, 12);
    height: 100px;
    margin-bottom: 20px;

//    @include at-breakpoint($tab) {
//      margin-top: 60px;
//      height: 200px;
//      @include span-columns(4 omega, 12);
//    }
    //    border: solid thin $border-color;
    position: relative;

    img {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
    }
  }
  .media-content h3.field-label {
    margin-top: 20px;
  }

  ul.field--name-field-brands {
    color: #999;
  }

  ul.field--name-field-brands, ul.field--name-field-brand {
    padding: 0;
    margin: 0;

    li {

    }
  }

}
.media-directory-reset-button {
  display: none;
}
.node-media-staff {
  @media screen and (max-width: $screen-xs-max) {
    h2 {
      text-align: left;
      padding-top: 0px;
      padding-bottom: 0px;
      &::before, &::after {
        display: none;
      }
    }
  }
}


#block-views-portfolio-hub-views-block, #block-views-portfolio-hub-views-block-1 {

  position: relative;
  margin-bottom: 20px;

  .block__title {
//    @include block-heading($bh-blue);
  }

  .views-field-created, .adr {
    font-size: 0.7em;
    text-transform: uppercase;

    i {
      margin-right: 5px;
    }
  }

  .adr {
    margin-top: -5px;
  }

  .view-portfolio-hub-views {

    .views-row {
//      @include divider(15px, $border-color, bottom);

      &.views-row-8 {
//        @include clear-divider(bottom);
      }

      position: relative;
      min-height: 90px;
      color: #999;

//      &.views-row-even {
//        width: 100%;
//      }
//
//      &.views-row-odd {
//        width: 100%;
//      }

      .field--name-field-primary-article-category {
//        @include tags();
        display: inline-block;
        position: absolute;
        top: 82px;
        left: 1px;
        width: 100px;
        text-align: center;
      }
    }

    .views-field-title-1 {
      h4 {

        a {
          font-size: 1.2em;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .adr {

    }
    .country-name {
      display: none;
    }

    .field-brand {
      li {
//        @include tags();
      }
    }

    .attachment-after {
//      @include divider(20px, $border-color, top);
//      @include divider(20px, $border-color, bottom);

      .views-row {
        margin: 0;
        border: none;
        float: left;
        width: 23.5%;
        margin-right: 2%;
        padding: 10px;
        position: relative;

        .views-field-field-primary-article-category {
//          @include ribbon($bh-blue, bottom, left, 6px, 6px);
          padding: 3px 5px;
          color: #fff;
          text-transform: uppercase;
          font-size: 0.7em;
          font-stretch: expanded;
          position: absolute;
          top: 16px;
          left: 4px;
        }

        span {
          font-size: 0.8em;
          font-weight: 300;
        }

        &.views-row-last {
          margin-right: 0;
        }

        /*@include title-over-image();*/
        .views-field-image {
          float: none;
        }

        &.views-row-last {
          // background: rgba(0,0,0,0.1);
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .attachment-before {
//      @include clearfix;

      .owl-item {
        .views-field-field-image {
          margin-right: 0;
        }
      }

      .owl-pagination {
        position: absolute;
        bottom: -20px;
        right: 12px;
      }

      .owl-controls {
        margin-top: 0;
      }

      .owl-buttons {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: 210px;
        width: 100%;

        .owl-prev, .owl-next {
          position: absolute;
          background: none;
          color: $bh-blue;

          i {
            font-size: 10em;
          }
        }

        .owl-prev {
          left: -10px;
        }

        .owl-next {
          right: -10px;
        }
      }

      .submitted {

        display: inline-block;
        font-size: 0.75em;
        margin-bottom: 15px;
        font-style: italic;

        i {
          display: none;
        }

        color: #aaa;
      }

      .author {
        font-size: 0.75em;
        color: $bh-blue;
        text-transform: uppercase;
        display: inline-block;
        margin-right: 10px;
      }

      width: 100%;
      float: left;
//      @include divider(20px, $border-color, bottom);
      position: relative;

      margin-right: 1%;

      .views-field-title {
        position: absolute;
        top: 0px;
        left: 337px;
        background: rgba(255, 255, 255, 0.8);
        color: #000;

        h5 {
          font-size: 2em;
          line-height: 1.2em;
        }

        a {

          &:hover {
            text-decoration: none;
            text-shadow: 0 0 1px #999;
          }

          h4 {
            color: #000;
            font-weight: 300;
            margin-bottom: 10px;
            display: inline;
          }
        }

        ul {
          margin: 10px 0 0;
          padding: 0;

          li {
//            @include tags();
          }
        }

      }

      .views-field-field-image {
        position: relative;
      }
      .image_text {
        position: absolute;
        bottom: 0;
        left: 0;
        height: auto;
        padding: 13px 13px 10px;
        width: 100%;
        background: #000;
        opacity: 0.8;
        color: #fff;
        font-family: Arial;
        p {
          color: #fff;
        }
        .heading {
          font-size: 1.2em;
          a {
            color: #fff;
          }
          margin-bottom: 0px;
          font-weight: bold;
          line-height: 1em;
        }
      }
      img {
        height: auto;
        width: 100%;
      }
    }
    .view-content {
      clear: both;

      .field--name-field-image {
        float: left;
        margin-right: 20px;
        img {
          margin-top: 2px;
        }
      }
    }
    p {
      font-size: 0.8em;
      color: #000;
      margin: 0 0 5px;
      a {
        color: $bh-blue;
      }
    }

    //#{headings(all)} {
    //  margin: 0px 0 5px;
    //}

    .views-row {
//      @include clearfix;
    }

  }
}


#block-views-portfolio-hub-views-block-1 {
  .views-row {
    width: 48%;
    margin-right: 1.5%;
    display: inline-block;

    .field--name-field-image {
      height: 120px;
    }

    .views-row-even {
      margin-right: 0;
    }
  }
}

body.page-news, .page-taxonomy-term {
  @media screen and (max-width: 1079px) {
    .l-main {
      h1 {
        &::before, &:after {
          width: 20% !important;
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .carousel-control .icon-next, .carousel-control .glyphicon-chevron-right {
      margin-top: -90px;
    }
    .l-main {
      h1 {
        font-size: 2em !important;
      }
    }
  }

  .l-main {
    h1#page-title {
      span {
        display: none;
      }
    }
    h1 {
      text-align: center;
      &::before {
        content: " ";
        display: table;
        background: #ccc;
        width: 30%;
        height: 1px;
        position: relative;
        margin-top: 30px;
        float: left;
      }
      &::after {
        content: " ";
        display: table;
        background: #ccc;
        width: 30%;
        height: 1px;
        position: relative;
        margin-top: 30px;
        float: right;
      }
    }
    h2 {
      font-style: normal;
      a {
        &:link {
          color: $link_color;
          text-decoration: none;
        }
        &:visited {
          color: $link_visited_color;
          text-decoration: none;
        }
        &:hover {
          color: $link_hover_color;
          text-decoration: none;
        }
        &:active {
          color: $link_active_color;
          text-decoration: none;
        }
      }
    }
  }

  .view-taxonomy-term, .view-brand-news {
    .views-row {
      article {
        header, footer {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    section#block-views-footer-block-4 {
      display: none;
    }
    .col-sm-9 {
      .region-content {
        padding-left: 0px;
      }
      .l-content {
        padding-right: 0px;
      }
      .view-taxonomy-term, .view-brand-news {
        .views-row {
          .node-article, .node-teaser {
            margin-bottom: 2em!important;
            .col-sm-3 {
              width: 25%;
              float: left;
            }
            .col-sm-9 {
              width: 75%;
              float: left;
              .field-type-text-long, .field-name-field-sell {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              h2 {
                margin-top: 0px;
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}

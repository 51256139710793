.node--page--full {
  ul {
    li {
      list-style: disc;
      margin-bottom: 15px;
    }
  }

  ol {
    li {
      list-style: decimal;
      margin-bottom: 15px;
    }
  }
}
body {
	.main-container {
		max-width: 1168px;
		_width: 1168px;
		padding-left: 5px;
		padding-right: 5px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
    min-height: 850px;
	}
}
p {
  font-size: 1.2em;
}

h2 {
  @include title();
  padding: 0px;
  text-align: left;
  letter-spacing: inherit;
}
.node-press-release {
  margin-top: 2em!important;
}

.extra-extras {
  @media screen and (min-width: $screen-fx-max + 1) {
    display: none;
  }
}
.region-breadcrumbs {
  display: none;
}
section.col-sm-9 {
  .region-content {
    padding-left: 2em;
  }
}
.scrollToTop {
  margin: 0 30px 20px 0;
  text-align: center;
  text-decoration: none;
  position: fixed;
  bottom: 0;
  right: 0;
  display: none;
}

.scrollToTop:hover {
  text-decoration: none;
}
.not-media {
  margin-top: 3em;
  a {
    color: $bd_pink;
    &:hover {
      text-decoration: none;
      color: $bd_pink;
    }
  }
}

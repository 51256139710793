// Media Directory Landing page, and People, Publication landing page styles

.page-node-804535, .page-directory-media-directory-people, .page-directory-media-directory-publication {
  @media screen and (max-width: 767px) {
    .l-content {
      padding-right: 20px
    }
    section.col-sm-9 .region-content {
      padding-left: 1em;
      .field-name-field-image {
        img {
          margin-top: 15px;
        }
      }
    }
  }
  .l-region--top-first {
    position: relative;
    margin: auto;
    display: block;
    max-width: 1179px;
  }
  .sidebar-roller {
    display: none;
  }
  h1 {
    display: inline;
    margin-bottom: 10px !important;
    @media screen and (max-width: 767px) {
      padding-left: 15px;
    }
  }
  .intro {
    margin-bottom: 25px;
  }
  .l-region--sidebar-first {
    &:before {
      content: "Search by:";
      text-transform: uppercase;
      margin-bottom: 10px;
      display: block;
      font-size: 1.7em;
    }
    padding-left: 0 !important;
    box-shadow: none;
    .block--facetapi {
      background-color: #F1F1F1;
      display: block;
      padding: 15px;
      margin-bottom: 10px;
      .block__title {
        border-bottom: 0;
      }
      select {
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: bold;
        width: 100% !important;
      }
    }
    .block--facetapi {
      .block__title {
        font-size: 94% !important;
      }
      input[type=checkbox] {
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        -webkit-appearance: none;
        outline: 0;
        display: inline-block;
        position: relative;
        top: 7px;
      }
      input[type=checkbox]:checked {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve"><path id="checkbox-3-icon" fill="#000" d="M81,81v350h350V81H81z M227.383,345.013l-81.476-81.498l34.69-34.697l46.783,46.794l108.007-108.005 l34.706,34.684L227.383,345.013z"/></svg>');
      }
      input[type=checkbox]:not(:checked) {
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve"> <path id="checkbox-9-icon" d="M391,121v270H121V121H391z M431,81H81v350h350V81z"></path> </svg>');
      }
    }
    .media-directory-reset-button {
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      color: white;
      background-color: black;
      padding: 10px 20px;
    }
    .facetapi-facetapi-checkbox-links {
      padding: 10px;
      margin: 0;
      background-color: white;
      border: solid thin #dddddd;
      border-radius: 5px;
      a {
        pointer-events: none;
        cursor: default;
      }
    }
  }
  /* A-Z filter */
  #block-facetapi-f1ylnhekz5dibdj5fqatlqfljyt7afpx {
    position: absolute;
    right: 0;
    top: 122px;
    z-index: 999;
    select {
      width: 100px;
      float: right;
    }
  }
}

// Media Directory Landing page

.page-node-804535 {
  h1 {
    display: inline;
  }
}

// Media Directory back button

.media-directory-back-button {
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  float: right;
  font-size: 14px;
  padding: 5px 10px;
  z-index: 9999;
  position: relative;
  &:hover, &:visited, &:active {
    text-decoration: none;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    top: -30px;
    right: 20px;
    float: none;
  }
}

// Media "Publication" node

.node--media--full {
  .group-contact-details {
    z-index: 999;
    position: relative;
    background-color: white;
    margin-right: 0;
    width: auto;
    display: block;
    float: right;
  }
  >.field--name-field-image {
    float: left !important;
  }
  .field-group-format-wrapper {
    padding: 10px 10px 30px 0 !important;
  }
  h1 {
    display: inline-block !important;
  }
  h3 {
    padding-bottom: 0;
    font-weight: 600 !important;
    color: black !important;
    span {
      font-weight: 600 !important;
    }
  }
  .field--name-field-media-type {
    border-top: none !important;
  }
  .field--name-field-media-team, .node--teaser {
    border-top: none !important;
  }
  .field--name-field-target-audience {
    > p {
      padding-left: 0;
    }
  }
  .entity-paragraphs-item {
    &::before {
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 10px;
      display: block;
    }
    .field-label {
      clear: left;
      color: #696969;
      float: left;
      display: inline;
    }
    .field {
      display: block !important;
      line-height: 157% !important;
    }
  }
  .paragraphs-item-comscore {
    &::before {
      content: "Comscore";
    }
  }
  .paragraphs-item-google-analytics {
    &::before {
      content: "Google Analytics";
    }
  }
  .paragraphs-item-nielsen {
    &::before {
      content: "Nielsen";
    }
  }
  .paragraphs-item-roy-morgan {
    &::before {
      content: "Roy Morgan";
    }
  }
}


// Media & Media Staff common styles

.node--media-staff--full, .node--media--full {
  .media-content {
    background-color: white;
    z-index: 999;
    position: relative;
  }
  .field--name-field-social-media {
    a {
      text-decoration: none;
      margin-right: 10px;
    }
    .fa {
      color: white;
      background-color: black;
      padding: 8px;
      border-radius: 100%;
      font-size: 1.4em;
      height: 36px;
      width: 36px;
      display: inline-block;
    }
  }
}

.node-type-media-staff {
  #page-title {
    display: inline-block !important;
  }
  .media-directory-back-button, .l-region--top-first {
    display: none;
  }
}

.node-type-media {
  .l-region--top-first {
    display: none;
  }
  h1 {
    display: inline-block !important;
  }
  .media-directory-back-button, .l-region--top-first {
    display: none;
  }
}

// Media Staff node

.node--media-staff--full {
  .field--name-field-image {
    float: left !important;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .field--type-taxonomy-term-reference {
    display: inline-block !important;
  }
  .l-region--top-first {
    display: none;
  }

  .group-details {
    display: inline-block;
  }
  .field-group-format-wrapper {
    padding: 10px 10px 30px 0 !important;
  }
  .field--name-body {
    border-top: 0;
  }
  h3 {
    padding-bottom: 0;
    font-weight: 600 !important;
    color: black !important;
    span {
      font-weight: 600 !important;
    }
  }
  .entity-paragraphs-item {
    .required-fields {
      position: relative;
      display: block;
      top: 25px;
      .field {
        display: inherit !important;
      }
      .field--type-taxonomy-term-reference {
        display: inline-block !important;
      }
    }
  }
  .field--name-field-media {
    float: left;
    margin-right: 1px;
    h3 {
      font-size: 1em !important;
      padding-bottom: 10px;
    }
  }
}

article {
  h2 {
    font-style: normal;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 2em;
    text-transform: uppercase;
    font-family: 'latomedium';
    &::before {
      content: " ";
      display: table;
      background: #ccc;
      width: 30%;
      height: 1px;
      position: relative;
      margin-top: 20px;
      float: left;
    }
    &::after {
      content: " ";
      display: table;
      background: #ccc;
      width: 30%;
      height: 1px;
      position: relative;
      margin-top: 20px;
      float: right;
    }
  }
      .view-content {
        @media screen and (max-width: $screen-xs-max) {
          padding: 22px;
          .col-xs-12.col-sm-6.col-md-4.col-lg-4, .col-xs-6.col-sm-6.col-md-4.col-lg-4 {
            &:nth-child(2n+2) {
              .tile-opaque {
                background: $bd-brand !important;
              }
            }
          }
        }
      }
      .view-header {
        h2 {
          margin: 0px;
        }
      }
      .col-xs-12.col-sm-6.col-md-4.col-lg-4, .col-xs-6.col-sm-6.col-md-4.col-lg-4 {
        margin-bottom: 2em;
        .node-teaser {
          padding-bottom: 0 !important;
          padding-top: 0 !important;
        }
        &:nth-child(2n+2) {
          .tile-opaque {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            //background: $pitch !important;
            //opacity: .6;
          }
        }
      }
      .tiles-wrapper {
        position: relative;
        .image-wrapper {
          .field-type-image {
            img {
              width: 100%;
            }
          }
          .field-name-field-tile-title {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            text-align: center;
            height: 100%;
            padding-top: 40%;
            font-size: 2em;
            font-family: 'latomedium';
            text-transform: uppercase;
            padding-left: 5%;
            padding-right: 5%;
            color: $bg;

            //					&:nth-child(even) {
            //				     background: $bd-brand;
            //					}
            //					&:nth-child(odd) {
            //						background: $pitch;
            //					}
          }
          .tile-opaque {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            background: $bd-brand;
            opacity: .6;
          }
        }
        .content-wrapper {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          overflow: hidden;
          width: 100%;
          height: 0;
          transition: .5s ease;
          background: $bd-brand;
          color: $bg;
          padding: 0px 3%;
          text-align: center;
          h3 {
            text-transform: uppercase;
            font-size: 1.5em;
            font-family: 'latoheavy';
            text-decoration: underline;
            padding: 30% 0px 0px 0px;
            @media screen and (max-width: 580px) {
              padding: 0px;
            }
            a {
              &:hover {
                color: $bg;
              }
            }
          }
          .field-name-body {
            p {
              color: $bg;
            }
          }
          li.node-readmore {
            a {
              color: $pitch;
              &:hover {
                color: $bg;
              }
            }
          }
        }
        &:hover .content-wrapper {
          height: 100%;
        }
      }
    .join {
      @media screen and (max-width: $screen-xs-max) {
        padding-top: 0px !important;
        .col-sm-6 {
          padding: 0px;
          h2 {
            text-align: center !important;
          }
          ul.join-list {
            max-width: 100% !important;
            padding: 22px !important;
          }
          .button {
            margin: 0 auto;
          }
        }
        .join-users {
          position: relative;
          .mobi {
            position: absolute;
            top: 0px;
          }
          .mob {
            margin-top: 60%;
          }
        }
      }
      background: #ffffff;
      padding-top: 87px;
      padding-bottom: 87px;
      .join-brands, .join-users {
        .col-sm-6 {
          padding-left: 0px;
          h2 {
            text-align: left;
          }
          ul.join-list {
            max-width: 430px;
            padding-left: 16px;
            li {
              &::before {
                content: "\2022";
                color: $pitch;
                font-weight: bold;
                display: inline-block;
                width: 2em;
                margin-left: -1em;
              }
            }
          }
          .button {
            @include buttonB();
          }
          .join-users-extras {
            max-width: 450px;
            margin: 0 auto;
          }
        }
      }
    }
    .stats {
      background: $bd-brand;
      .statistics {
        @media screen and (max-width: $screen-xs-max) {
          padding-bottom: 0px;
        }
        .col-xs-12.col-sm-3.col-md-3.col-lg-3 {
          width: 50%;
          margin-bottom: 87px;
        }
        padding-top: 87px;
        padding-bottom: 87px;
        max-width: $site-width;
        margin: 0 auto;
        .stats-icon {
          max-width: 100px;
          padding-left: 50px;
        }
        .stats-description {
          max-width: 150px;
          text-align: center;
          color: $bg;
        }
        .stats-figures {
          font-family: 'latoheavy';
          color: $bg;
          padding-top: 22px;
          padding-bottom: 22px;
          max-width: 150px;
          text-align: center;
          font-size: 2rem;
        }
      }
    }
    .testimonials {
      background: #f1f0f2;
      .testimony {
        max-width: $site-width;
        margin: 0 auto;
        padding-bottom: 87px;
        .extras {
          @media screen and (max-width: $screen-xs-max) {
            min-height: auto;
            margin-bottom: 22px;
          }
          background: #ffffff;
          padding: 47px;
          min-height: 442px;
          h3 {
            font-family: 'latomedium';
            text-transform: uppercase;
            padding: 0px;
            font-size: 20px;
            margin: 0px 0px 10px;
            text-align: center;
          }
          .desc {
            text-transform: uppercase;
            color: #999;
            font-family: 'latothin';
            letter-spacing: 2px;
            font-size: 0.9em;
            text-align: center;
            padding-bottom: 20px;
          }
          img {
            margin: 0 auto;
            display: block;
          }
          .user-summ {
            padding-top: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .stats-reg {
      max-width: $site-width;
      margin: 0 auto;
      padding: 57px 0px;
      .button {
        max-width: 247px;
        margin: 0 auto;
        a {
          padding: 24px 14px;
          &:hover {
            color: $bd-brand;
            text-decoration: none;
            background: none !important;
            border: solid 2px $pitch;
            padding: 23px 13px;
          }
        }
      }
    }
}

.node-teaser {
  clear: left;
  &:last-child {
//    @include clear-divider(bottom);
//    margin-bottom: 60px !important;
  }

  width: 100%;

//  @include divider(20px, $border-color-light, top);
  margin-top: 0;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  margin-bottom: 0 !important;
  position: relative;
  .content h2 a {
    &:hover{
      text-decoration: none;
    }
  }
  img {
    height: auto;
    margin-top: 0px;
  }

  .field-label {
    text-align: left;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    float: left;
    line-height: 137%;
    clear: both;
  }

  .field--label-inline {
    float: left;
    &::after {
      content: " ";
    }
    &:last-child::after {
      content: none;
    }
  }

  .location.vcard {
    display: inline-block;
  }

  .group-contact-details, .group-statistics, .group-products-details {
    float: left;
    width: 104.5%;
    margin-right: 4%;
    font-weight: 300;
    font-size: 0.8rem;
    background: #fff;
    font-family: "Gill Sans Nova";
    letter-spacing: 0.71px;
    padding: 20px;
  }

  .media-content {
    float: left;
    //width: 80%;
    position: relative;

    .group-statistics {
      width: 44%;
      margin-right: 0;

      .field-label {
        min-width: 85px;
      }
    }

    .field-collection-container {

//      @include social-media-icons-2();
      width: 300px;
      height: 50px;
      top: 0;
      right: 0;
      position: absolute;
    }
  }

  .media-content {
//    @include span-columns(9 omega, 12);
  }

  .field--name-field-image, .field--name-field-logo-image {
//    border: solid thin $border-color;
//    background: url(/sites/all/themes/bd/images/logos/brand-initials-small.png) center center no-repeat;
//    @include span-columns(3, 12);
    //height: 125px;
    position: relative;
//    margin-bottom: 15px;

    img {
      //position: absolute;
//      @include absolute-center;
      max-width: 100%;
      //max-height: auto;
    }
  }

  .field--name-body {

    p {
      margin-top: 0;
    }
  }
}

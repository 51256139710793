.page-products-on-counter, .page-products-on-counter-nz, .page-products-on-nz-counter {
  .date-nav-wrapper {
    display: none;
  }

  .node--product--teaser {

    .field--name-field-brand {
      display: block !important;
    }
  }
  section.col-sm-9 {
    #block-views-brands-brand-image {
      .field-name-field-logo-image {
        max-width: 150px;
        margin-top: 3em;
      }
    }
    .view-footer {
      margin-bottom: 2em;
      .footer-back {
        text-align: right;
        span {
          a {
            text-decoration: none;
            font-weight: bold;
            &:hover {
              color: $pitch;
            }
          }
        }
      }
    }

        .view-latest-products-block {
          @media screen and (max-width: $screen-sm-max) {
            .download-links.col-sm-12.col-md-3.col-lg-3 {
              width: 25%;
              float: left;
            }
          }
          @media screen and (min-width: $screen-sm-mins) and (max-width: $screen-sm-max) {
            .product-image.product-content.col-xs-12.col-sm-6.col-md-4.col-lg-4 {
              width: 33.33333%;
            }
            .col-sm-12.col-md-8.col-lg-8 {
              width: 66.66667%;
            }
          }
          .product-content {
              margin: 3em 0px;
              .download-links.col-sm-12.col-md-4.col-lg-4 {
                @media screen and (max-width: $screen-lg-mins) {
                  width: 100%;
                }
                padding-left: 0px;
                padding-right: 0px;
                .prss {
                  margin-left: 10px;
                  @media screen and (max-width: $screen-lg-mins) {
                    margin-left: 0px;
                  }
                }
                .prsss {
                  margin-left: 15px;
                  @media screen and (max-width: $screen-lg-mins) {
                    margin-left: 0px;
                  }
                }
                a {
                  text-transform: uppercase;
                  padding: 10px;
                  font-size: 12px;
                  font-family: 'latomedium';
                  letter-spacing: 2px;
                  &:link {
                    border: solid thin $link_color;
                    color: $link_color;
                  }
                  &:visited {
                    border: solid thin $link_visited_color;
                    color: $link_visited_color;
                  }
                  &:hover {
                    border: solid thin $link_hover_color;
                    color: $link_hover_color;
                    text-decoration: none;
                  }
                  &:active {
                    border: solid thin $link_active_color;
                    color: $link_active_color;
                  }
                  @media screen and (max-width: $screen-lg-mins) {
                    display: block;
                    margin-top: 1em;
                    text-align: center;
                  }
                }
              }
            }

            .field-name-field-product-image {
              img {
                margin: 0 auto;
              }
            }
            .icon-downloads-wrapper {
              max-width: 150px;
              margin: 0 auto;
              position: relative;
              .download-links {
                .fa {
                  color: $link_hover_color;
                  position: absolute;
                }
                .fa-inverse{
                  color: $link_active_color;
                }

                .fa-download {
                  top: 0px;
                }
                .fa-envelope, .fa-thumb-tack {
                  a {
                    position: absolute;
                    display: block;
                    width: 20px;
                    height: 15px;
                    left: 0px;
                    top: 0px;
                  }
                }

                .flag-outer {
                  top: 0px !important;
                  right: inherit;
                  font-size: inherit;
                  border: 0px;
                }
              }
            }
          }

      }
  .view-filters {
    #-wrapper, #wrapper {
      display: none;
    }
    .views-exposed-form {
      .views-exposed-widget {
        max-width: 200px;
        float: right;
        @media screen and (max-width: $screen-xs-max) {
          max-width: inherit;
          float: none;
        }
        fieldset.panel-default {
          border: 0px;
          box-shadow: none;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
          margin: 0px;
          padding: 0px;
          legend.panel-heading {
            border: solid 1px #000;
            background: none;
            border-radius: 0px;
            a {
              font-size: 14px !important;
              text-align: center;
            }
          }
          .panel-body {
            background: #fff;
            border: solid 1px #000;
            border-top: 0px;
            padding: 0px;
            position: absolute;
            top: 43px;
            z-index: 1;
            @media screen and (max-width: $screen-xs-max) {
              width: 100%;
            }
            .selected-edit-sort-bef-combine-field-on-shelf-value-asc {
              border: solid 1px #000;
              border-right: 0px;
              border-left: 0px;
              border-top: 0px;
            }
            .form-type-bef-link {
              a {
                text-align: center;
                font-family: "latomedium";
                font-size: 14px !important;
                font-weight: bold;
                text-transform: uppercase;
                padding-bottom: 10px;
                padding: 15px;
                display: block;

                &:hover {
                  text-decoration: none;
                  color: $bd_pink;
                }
              }
            }
          }
        }
        //.panel-default {
        //  .panel-heading {
        //    border-bottom: 0px !important;
        //  }
        //}

        .form-item {
          position: relative;
          label {
            position: absolute;
            /* z-index: 1; */
            font-family: "latomedium";
            font-size: 14px !important;
            font-weight: bold;
            max-width: 100px;
            margin-right: 0px;
            padding-top: 8px;
            padding-left: 4em;
            display: none;
          }
          span#select2-chosen-1 {
            text-indent: 99999px;
          }
          .select2-container {
            .select2-choice {
              background: none;
              width: 200px;
              border: solid 1px #000000;
              border-radius: 0px;
              height: auto;
              padding-bottom: 8px;
              padding-top: 5px;
              .select2-arrow {
                background: none;
                border: 0px;
                right: 3em;
              }
            }
          }
          .use-select-2 {
            background: none;
            width: 200px;
            border: solid 1px #000000;
            min-height: 41px;
            padding-bottom: 8px;
            padding-top: 5px;
            border-radius: 0px!important;
            min-height: 41px;
          }
        }
      }
    }
  }
  .node-teaser {
    .group-products-details {
      padding-left: 0px;
    }
  }
  section#block-bd-custom-products-on-counter, section#block-bd-custom-products-on-counter-nz {
    position: relative;
    box-shadow: #eee 20px 0px 21px -14px;
    padding-top: 0px;
    overflow: hidden;
    top: 40px;
    left: 0;
    height: 100%;
    margin-top: 5px;
    @media screen and (max-width: $screen-xs-max){
      top: -40px;
    }
    h2 {
      display: block;
      font-weight: bold;
      font-family: 'Lato', san-serif;
      color: #1e6f80;
      margin-bottom: 0px;
      color: #333;
      font-size: 20px !important;
      text-transform: uppercase;
      padding-bottom: 10px;
      font-style: normal;
      border-bottom: solid 1px #dddddd;
    }
    .views-row {
      margin-bottom: 0px;
      padding: 15px 0px 15px 0px;
      border-bottom: solid thin #eee;
      font-size: 1em;
      font-weight: 300;
      color: #666;
      a.ajax-link {
        text-transform: uppercase;
      }
      &:hover {
        color: $bd_pink!important;
      }
    }
  }
  .view-latest-products-block {
    .view-header {
      border-bottom: 0px!important;
      h1 {
        position: absolute;
        margin-top: -20px;
        position: absolute;
        left: 3.5em;
        @media screen and (max-width: $screen-xs-max){
          position: relative;
          margin-top: 0px;
          left: 0px;
          text-align: center;
        }
      }
    }
    .node--product--teaser h2 a{
      &:link {
        color: $link_color;
      }
      &:visited {
        color: $link_visited_color;
      }
      &:hover {
        color: $link_hover_color;
        text-decoration: none;
      }
      &:active {
        color: $link_active_color;
      }

    }
    .owl-carousel .owl-wrapper-outer {
      z-index: 0!important;
      img {
        margin: 0 auto;
      }
    }
    .owl-controls {
      position: absolute;
      top: 30%;
      width: 100%;
      .owl-buttons {
        position: relative;
        .owl-prev {
          position: absolute;
          left: 0px;
          z-index: 11;
          background: none;
          color: #000;
          font-size: 1.5em;
          opacity: 1;
          .fa:hover {
            color: $pitch;
          }
        }
        .owl-next {
          position: absolute;
          right: 0px;
          z-index: 11;
          background: none;
          color: #000;
          font-size: 1.5em;
          opacity: 1;
          .fa:hover {
            color: $pitch;
          }
        }
      }
    }
  }
  .l-main {
    .ajax_target {
      h1#page-title {
        display: none;
      }
    }
  }
  section#block-bd-custom-products-on-counter {
    .views-row {
      a.active {
        color: $bd_pink;
        text-decoration: none;
      }
    }
  }
}

body.view-nz-content {
  li.au-counter {
    display: none!important;
  }
}

body.view-au-content {
  li.nz-counter {
    display: none!important;
  }
}

#block-search-form {

  width: 100%;
  margin: 10px 0 10px;

//  @include at-breakpoint($tab) {
//    width: 30%;
//    margin: 0 0 20px;
//  }
  float: right;
  position: relative;
  clear: both;

  &.small {
    
    top: 72px;
    right: 95px;
    
    input[type=text] {
      display: none;
    }
  }
  input[type=text] {
    background: #fff;
    height: 31px;
    border: solid thin $border-color;
    width: 100%;
    margin: 0px 0 0px 0;
    padding: 0px 20px;
    color: #666;
    opacity: 0.8;
    font-size: 14px;
    &:focus, &:hover {
      outline: none;
      opacity: 1;
    }
    &::-webkit-input-placeholder { /* WebKit browsers */
        color:    #999;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:    #999;
        opacity:  1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:    #999;
        opacity:  1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color:    #999;
    }
  }
  input[type=submit] {
    height: 100%;
    width: 100%;
    margin: 0;
    opacity: 0;

    &:hover {
      background: $bh-light-blue;
      color: #fff;
    }
  }
  .is-active-search {
    .form-actions {
      cursor: pointer;
      &::before {
        content: "\f00d" !important;
      }
    }
  }
  .form-actions {
      position: absolute;
      right: 0px;
      top: 1px;
      height: 30px;
      width: 43px;
      /*background: #333;*/
      color: #fff;
    @media screen and (min-width: 979px) {
      top: -45px;
    }

      &::before {
      content: "\f002";
      color: #000;
      padding-left: 10px;
      font-family: fontAwesome;
      position: absolute;
      top: 4px;
      right: 13px;
      font-size: 20px;
    }
  }

    .search-form-container {
        display: none;
    }

    .search-image-container {
        position: absolute;
        right: 0;
      @media screen and (min-width: 979px) {
        top: -45px;
      }
    }

    .search-image {
        cursor: pointer;

        &:before {
            content: "\f002";
            color: #000;
            //padding-left: 10px;
            font-family: fontAwesome;
            //position: absolute;
            //top: 4px;
            //right: 13px;
            font-size: 20px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    //.contextual-links-wrapper {
    //    display: none;
    //}

}


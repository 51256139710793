#block-bd-custom-nz-whats-new {

  margin-bottom: 20px;

  .heading {

//    @include clearfix();

    > div {
      width: 49%;
    }

    .left {
      float: left;
      span {
//        @include sub-heading;
        margin-bottom: 10px;
        font-weight: bold;
        display: block;
//        @include clearfix();
        font-size: 35px !important;
        line-height: 40px;
      }

      small {
        display: block;
      }
    }

    img {
      height: 200px;
      width: auto;
      float: right;
    }

  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-position: inside;
      list-style-type: square;
//      @include border-bottom(5px, $border-color-light);

      &:last-child {
//        @include clear-divider(bottom);
      }
    }
  }
}

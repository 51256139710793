#block-block-14, #block-dfp-leaderboard, #dfp-ad-clone_of_leaderboard {

  //display: none;

//  @include at-breakpoint($tab) {
//    display: block;
//  }
  width: 100%;
  text-align: center;

  iframe {
    margin: 20px 0;
  }
  background-color: #f9f9f9;
  margin-bottom: 0px;
  box-shadow: inset #ddd 0 0 20px;

  .block__content {
    margin-top: 0px;
  }
  
  &.visible {
   // height: 132px;
  }
}

header.l-header {
    .region-skin-left, .region-skin-right {
      display: none;
      @media screen and (min-width: 70em) {
        display: block;
        position: absolute;
        width: 150px;
      }
    }

    @media screen and (min-width: 70em) and (max-width: 1499px) {
      .region-skin-right {
        right: 0px;
        margin-right: -50px;
      }
      .region-skin-left {
        left: 0px;
        margin-left: -50px;
      }
    }
  @media screen and (min-width: 1500px) {
    .region-skin-right {
      margin-right: 0px;
      right: 0px;
    }
    .region-skin-left {
      margin-left: 0px;
      left: 0px;
    }
  }
}

.page-event-review {

  h1 {
//    @include clear-divider(bottom);
  }

  .pager {
    clear: none;
    float: right;
    margin: 5px;
  }

  .view-header {
//    @include clear-divider(bottom);
    margin-bottom: 20px !important;
  }

  .date-heading {
    text-align: left !important;

    h3 {
//      @include box-title(#000, #fff);
      text-transform: uppercase;
      float: left;
    }
  }
}

#block-views-ipress-blocks-block, #block-views-ipress-blocks-ticker-nz  {

  display: none;

  // The block title is converted into the ticker header in behaviors.js
  // Allows us to us dynamic / multiple titles without hardcoding the title
  h2 {
    display: none;
  }

//  @include at-breakpoint($tab) {
//    display: block;
//  }
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 20px;
//  @include clearfix;
  float: none;
  position: relative;

  /* Ticker Styling */
  .ticker-wrapper.has-js {
    text-transform: uppercase;
    width: 100%;
    display: block;
  }
  .ticker {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    background: #f5f5f5;
    box-shadow: #eee 0 0 10px inset;
    height: 40px;
  }
  .ticker-title {
    padding: 6px 50px 9px 20px;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #f8f0db;
    text-transform: uppercase;
    background: $bh-blue;
    color: #fff;
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      height: 21px;
      width: 23px;
      background: $bh-blue;
//      @include vendor-prefix(transform, rotate(45deg));
      top: 8px;
      right: -5px;
     }

  }
  .ticker-content {
    margin: 0px;
    padding: 11px 0px 7px;
    position: absolute;
    color: $bh-dark-blue;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.2em;
    text-transform: none;
    font-style: italic;
  }
  .ticker-content:focus {
  }
  
  .ticker-content a {
    text-decoration: none;	
    color: $bh-dark-blue;
  }
  .ticker-content a:hover {
    text-decoration: underline;	
    color: $bh-dark-blue;
  }
  .ticker-swipe {
    padding-top: 9px;
    position: absolute;
    top: 10px;
    background-color: #f7f7f7;
    display: block;
    width: 500px;
    height: 20px;
  }
  .ticker-swipe span {
    margin-left: 2px;
    background-color: transparent;
    border-bottom: 1px solid #1F527B;
    height: 8px;
    margin-top: 1px;
    width: 7px;
    display: block;
  }
  .ticker-controls {
    padding: 10px 0px 0px 0px;
    list-style-type: none;
    float: left;
    position: absolute;
    right: 30px;
    top: -18px;
  }
  .ticker-controls li {
    padding: 0px;
    margin-left: 5px;
    float: left;
    cursor: pointer;
    height: 16px;
    width: 16px;
    display: block;
    opacity: 0.4;
  }

  .ticker-controls li:hover {
    opacity: 0.7;
  }
  
  .js-hidden {
    display: none;
  }
  .no-js-news {
    padding: 10px 0px 0px 45px; 
    color: #F8F0DB;
  }
  .left .ticker-swipe {
    /*left: 80px;*/
  }
  .left .ticker-controls, .left .ticker-content, .left .ticker-title, .left .ticker {
      float: left;
  }
  .left .ticker-controls {
      padding-left: 10px;
  }
  .right .ticker-swipe {
    /*right: 80px;*/
  }
  .right .ticker-controls, .right .ticker-content, .right .ticker-title, .right .ticker {
      float: right;
  }
  .right .ticker-controls {
      padding-right: 10px;
  }

  .view-footer {
    position: absolute;
    right: 20px;
    top: -10px;
    p a {
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: none;
      font-size: 0.8em;
      padding: 2px 6px;
//      background: #000;
      color: #999;
//      border: solid thin #ccc;
//      opacity: .6;

      &:hover {
        opacity: 1;
      }
    }

    i {
      margin-left: 5px;
    }
  }
}

.view-assets, body.page-directory-media-directory, body.page-assets-new, body.page-search, body.page-directory-media-directory {

		@media screen and (min-width: $screen-tablet) {
			.mobile-filter, .is-active-mob {
				display: none;
			}
		}

		@media screen and (max-width: $screen-xs-max) {
			.is-active-mob {
				.line-1 {
					-webkit-transform: translateY(8px) translateX(0) rotate(45deg);
					-ms-transform: translateY(8px) translateX(0) rotate(45deg);
					transform: translateY(8px) translateX(0) rotate(45deg);
					width: 20px !important;
				}
				.line-2 {
					opacity: 0;
				}
				.line-3 {
					-webkit-transform: translateY(1px) translateX(0) rotate(-45deg);
					-ms-transform: translateY(1px) translateX(0) rotate(-45deg);
					transform: translateY(1px) translateX(0) rotate(-45deg);
					width: 20px !important;
				}
				.views-exposed-widgets, .block-facetapi {
					display: block;
				}
			}
			.views-exposed-widgets, .block-facetapi, section#block-current-search-standard {
				display: none;
				.select2-container.select2-container-multi {
					width: 100%!important;
				}
			}
			.mobile-filter {
				color: $bg;
				background: $bd-brand;
				text-transform: uppercase;
				text-align: center;
				padding: 15px;
				cursor: pointer;
					.mobile-menu-bar-mob, .mobile-menu-bar-mobi  {
						max-width: 140px;
						margin: 0 auto;
						display: block;
						span {
							background: #000000;
							margin: 3px 0;
							float: left;
							line {

							}
							&.icon-bar-filter {
								width: 86px;
								margin: 0 auto;
							}
							&.icon-bar {
								width: 30px;
								margin: 0 auto;
								height: 3px;
								margin-bottom: 3px;
							}
						}
						.line {
							-webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
							transition: all .25s cubic-bezier(.645, .045, .355, 1);
							-webkit-transition-timing-function: cubic-bezier(.645, .045, .355, 1);
							transition-timing-function: cubic-bezier(.645, .045, .355, 1);
							background: #fff;
							border-radius: 3px;
							-moz-border-radius: 3px;
							-webkit-border-radius: 3px;
						}
						.line-1 {
							top: 0;
						}
						.line-2 {
							top: 50%;
						}
						.line-3 {
							bottom: 0;
						}
		      }
		  	}
	  	}
	 }
	.view-filters {
		.form-control {
			border: 0px;
			transition: none;
			box-shadow: none;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			padding: 0px;

		}
		.views-widget-filter-title {
			label {
				color: $pitch;
			}
		}
		.views-exposed-widget {
			.views-widget {
				.bef-select-as-links {
					.form-item {
						.form-item {
							float: left;
							margin: 5px;
							width: 20px;
							height: 20px;
							position: relative;
							background: #000;
							margin: 5px;
							a{
								color: #ffffff;
								text-align: center;
								margin: 0 auto;
								display: block;
							}
						}
					}
				}
			}
		}
		.views-widget-filter-field_assets_category_tid, .views-widget-filter-field_assets_colour_tid,
		.views-widget-filter-title_selective, .views-widget-filter-field_product_type_tid,
		.views-widget-filter-field_categories_tid, .views-widget-filter-field_brand_target_id_1,
		.views-widget-filter-field_colours_tid {
			label:not(.option) {

			}
			.views-widget {
					.form-group {
						clear: both;
						position: relative;
						input[type="checkbox"] {
							float: left;
							max-width: 30px;
						}
						label {
							float: left;
							padding-top: 10px;
							text-transform: uppercase;
							font-weight: normal;
						}
					}
			}
		}
	}
	.view-content {
		.masonry-item {
			float: left;
		}
	}
	.view-content {
		.masonry-item {
			border: 0px;
			background: none;
			position: relative;
			cursor: pointer;
			.views-field-nothing {
				display: block ;
				max-width: 100px;
				margin-left: 130px;
				margin-top: 10px;
				.fa {
					color: $pitch;
					font-size: 1em;
				}
				@media screen and (max-width: 460px) {
					max-width: 90%!important;
				}
			}
			//&:hover .views-field-nothing {
			//	display: block !important;
			//	bottom: 80px;
			//	right: 10px;
			//	position: absolute;
			//	.fa {
			//		color: $pitch;
			//	}
			//}
			.views-field-title {
				text-transform: uppercase;
			}
			.views-field-nothing-1 {
				padding-top: 10px;
				padding-left: 10px;
			}
			.field-name-field-asset-description {
				font-size: 0.8em;
				color: #b7b7b7;
			}
			span {
				display: inline-block;
				font-size: 0.8em;
				color: #b7b7b7;
				.col-sm-4 {
					padding: 0px;
				}
				.col-sm-4 {
					padding-right: 0px;
				}
				&.field-content {
					color: #000;
					font-family: 'latomedium';
					font-size: 1em;
				}
				&.flag-wrapper {
					font-size: 1em;
				}
				&.flag-throbber {
					display: none;
				}
			}
			ul.field-name-field-on-shelf {
				display: inline-block;
				max-width: 120px;
				padding-left: 0px;
			}

			@media screen and (max-width: $screen-sm-max) {
				.col-sm-12.col-md-3.col-lg-3 {
					width: 25%;
					float: left;
				}
				.views-field-nothing {
					max-width: 70%;
					margin: 10px auto ;
				}
			}
			@media screen and (min-width: $screen-md-min) and (max-width: 1099px) {
				.views-field-nothing {
					margin-left: 100px !important;
				}
			}
		}
	}


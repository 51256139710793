
.node-type-pr {
  #block-views-directories-block-4, #block-views-directories-block-5 {
    .block__title {
      font-weight: 300;
    }


    .node:last-child {
    }

    margin-bottom: 60px;
  }
}

.node--pr--full {
  margin-bottom: 20px;

  .views-field-field-image img {
//    @include divider(20px, $border-color, bottom);
  }

  .field--name-field-brands {
    margin-top: 15px;
  }

  .media-content {


  }
}

body.page-node-804538 {
  section#block-bd-refresh-pr-letters, section#block-bd-refresh-brand-letters {
    padding-right: 4em;
    padding-left: 10px;
    margin-top: 3em;
    @media screen and (max-width: 910px) {
      padding-right: 0em;
    }
    a {
      float: left;
      width: 32px;
      height: 30px;
      position: relative;
      background: #000;
      margin: 5px;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      padding-top: 6px;
      &:hover {
        border: solid thin #000000;
        color: #000000;
        background: #ffffff;
      }
    }
  }
  .pr-bloc {
    .img-responsive {
      //width: 100%;
    }
  }
  .l-main {
    .l-content {
      padding-right: 0px;
      max-width: 900px;
      margin: 0 auto;
      .region-content {
        //div.contextual-links-wrapper {
        //  //display: none!important;
        //}
        position: relative;
        @media screen and (max-width: 420px) {
          section#block-block-45, section#block-block-38 {
            margin-top: 200px;
          }
          section#block-bd-refresh-pr-letters {
            position: absolute;
            top: 280px;
          }
        }
        section#block-bd-refresh-pr-letters, section#block-bd-refresh-brand-letters {
           h2.block-title {
             display: none;
           }
        }
      }
    }
  }
  h1 {
    @include blockTitle();
    @media screen and (max-width: 420px) {
      padding: 0em;
      margin: 0em;
    }
  }
  #glossary_nav {
    display: none;
  }
  @media screen and (min-width: 421px) and (max-width: $screen-sm-max) {
    .col-md-6 {
      width: 50%;
      float: left;
    }
  }
  @media screen and (max-width: 420px) {
    .col-md-6 {
      width: 100%;
      float: none;
    }
  }
}

body.page-directory-pr, body.node-type-pr {
  #magical-modal-link {
    text-transform: uppercase;
    color: #C4C4C4;
    margin-top: 2em;
    a {
      text-decoration: underline;
      &:link {
        color: $link_color;
      }
      &:visited {
        color: $link_visited_color;
      }
      &:hover {
        color: $link_hover_color;
      }
      &:active {
        color: $link_active_color;
      }
    }
  }
  @media screen and (max-width: 420px) {
    section#block-bd-refresh-pr-letters, section#block-bd-refresh-brand-letters {
      a {
        display: block;
        float: none!important;
        padding: 6px 15px 0px 6px!important;
        margin: 0!important;
      }
    }
  }
  @media screen and (max-width: $screen-sm-min) {
    aside {
      .well {
        section#block-bd-refresh-pr-letters, section#block-bd-refresh-brand-letters {
          position: absolute;
          right: 0px;
          width: 48px;
          z-index: 1;
          background: #e0e0e0;
          a {
            background: none;
            color: #000000;

          }
        }
        section#block-views-directories-pr-block-1 {
          display: none;
        }
      }
    }
    .view-directories-pr {
      .col-md-4 {
        width: 33.33333333%;
        float: left;
        padding-left: 0px;
      }
      .col-md-8 {
        width: 66.66666667%;
        float: left;
        padding-right: 65px;
        padding-left: 0px;
      }
    }
  }

  section#block-bd-refresh-pr-letters, section#block-bd-refresh-brand-letters {
    padding-right: 0em;
    padding-left: 0px;
    width: 100%;
    float: none;
    @media screen and (max-width: 910px) {
      padding-right: 0em;
    }
    a {
      float: left;
      width: 32px;
      height: 30px;
      position: relative;
      background: #000;
      margin: 5px;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      padding-top: 6px;
      &:hover {
        border: solid thin #000000;
        color: #000000;
        background: #ffffff;
      }
    }
  }
  section#block-system-main {
    .view-directories-pr {
      .views-row {
        margin-bottom: 3em;
      }

      .col-md-8 {
        h2.block-title {
          display: block !important;
          font-size: 2rem !important;
          a {
            &:link {
              color: $link_color;
            }
            &:visited {
              color: $link_visited_color;
            }
            &:hover {
              color: $link_hover_color;
            }
            &:active {
              color: $link_active_color;
            }
          }
        }

        h2 {
          display: inline-block;
          font-size: 1.2rem;
          margin-top: 0px;
        }

        div {
          display: inline-block;
        }
      }
    }
  }
  .region-sidebar-first {
    .view-directories-pr {
      margin-top: 3em;
      .views-row {
        a {
          text-transform: uppercase;
          padding: 10px 0px;
          display: block;

          &:hover {
            color: $bd_pink;
            text-decoration: none;
          }
        }
      }
    }
  }
}



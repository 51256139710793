.role-toggle-form {

  float: left;

  &:hover input[type=submit] {
    color: #000 !important;
  }

  input[type=submit] {
    text-transform: uppercase;
    background: none;
    border: none;
    color: #eee;
    margin-right: 10px;
    padding: 15px 5px 4px;
    font-weight: normal;
    font-size: 0.7em;
//    @include vendor-prefix(transition, 0.5s);

    &:focus {
      outline: none;
    }
  }

  .role-toggle-role-active {
    font-weight: normal !important;
    text-transform: uppercase !important;
    &:before {
      content: "\f06e";
      font-family: fontAwesome;
    }
  }
  .role-toggle-role-inactive {
    text-decoration: line-through !important;
    font-style: normal !important;
    text-transform: uppercase !important;
    &:before {
      content: "\f070";
      font-family: fontAwesome;
    }
  }
}

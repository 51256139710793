// Default variables.
@import '../../../bd-sass/bd-variables';

header {
	.container-fluid {
		nav {
			section#block-tb-megamenu-main-menu {
				.tb-megamenu {
					//background: none;
				}
			}
		}
		.navbar-header {
			display: $none !important;
		}
	}
	&.navbar-default {
		background-color: $trans;
		border-color: $z;
		border: $z;
	}
}

// Wide screen media query
@media #{$wide} {
	header {
		.container-fluid {
			nav {
				.secondary {
					display: none !important;
				}
		  }
		}
	}
}

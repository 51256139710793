.l-footer-links {
  background-color: #fff;
  color: #000;
  border-top: solid 1px $border-color;
  position: relative;
  padding-top: 1px;
  font-size: 0.8em;
  font-weight: bold;
  padding: 20px 0;
  margin-top: 100px;
  margin-bottom: 0 !important;

  .footer-links {
    margin-bottom: 0 !important;
		max-width: $site-width;
		margin: 0 auto;
		.fa {
			color: $link_hover_color;
			margin-top: 22px;
      font-size: 1.5rem;
		}
  }

  .links {
//    @include clearfix;
    margin-bottom: 0 !important;

//    @include at-breakpoint($tab) {
//      @include span-columns(12 omega, 16);
//    }

    font-weight: 300;
    text-transform: uppercase;
    
    ul {
      margin-top: 8px;
      padding-left: 0;
      
      li {
        list-style: none;

        text-align: center;
        margin-right: 10px;
				display: inline-block;

//        @include at-breakpoint($tab) {
//          margin-left: 40px;
//          margin-right: 0;
//          float: right;
//        }

      &:last-child {
        margin-left: 0;
      }
      
      }
    }    
  }

  .social {
//    @include at-breakpoint($tab) {
//      @include span-columns(4, 16);
    }

    text-align: center;
    font-size: 2em;

    i {
      margin-right: 10px;
    }
  }


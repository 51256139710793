#block-views-portfolio-hub-views-block-8 {
//  @include divider(40px, $border-color, top);
  margin-top: 20px;
  margin-bottom: 0;
}

#block-views-portfolio-hub-views-block-9 {
  margin-bottom: 40px;

  ul li.views-row-last {
    border-bottom: none !important;
  }
}

#block-views-portfolio-hub-views-block-8, #block-views-portfolio-hub-views-block-9 {

  > .block__title {
//    @include box-title($bh-dark-blue, #fff);
    float: left;
    margin-bottom: 25px;
  }

  width: 100%;
  float: left;

  .block__content {
    width: 100%;
    float: left;

    ul {
      padding: 0;
      margin: 0;

      li {
//        @include divider(5px, $border-color-light, bottom);
        font-weight: 300;

        i {
          color: #aaa;
          margin-right: 10px;
        }

        .tag {
//          @include tags;
          float: none;
          display: inline-block;
        }
      }
    }
  }
}

$nav-color: #000;

#block-easy-breadcrumb-easy-breadcrumb, .homepage-breadcrumb {
  //  display: none;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 3em;
  //  background: #f7f7f7;
  font-size: 0.9em;
  float: none;
  max-width: $site-width;
  font-weight: 300;
//  box-shadow: #eee 0 0 10px;
  background: #fdfdfd;

  .easy-breadcrumb_segment-separator {
    display: none;
  }

  .easy-breadcrumb_segment-title {
    display: none;

//    @include at-breakpoint($tab) {
//      display: inline-block;
//    }
  }

  span, a {
    list-style: none;
    padding: 6px 30px;
    display: inline-block;
    margin: 0;
    position: relative;
    color: $nav-color;
//    border-right: solid 1px $border-color;

//    @include at-breakpoint($tab) {
//
//         &::before {
//           content: " ";
//           position: absolute;
//           height: 10px;
//           width: 10px;
//           background: #fff;
//           border-right: solid thin $border-color;
//           border-top: solid thin $border-color;
//           @include vendor-prefix(transform, rotate(45deg));
//           top: 11px;
//           right: -4px;
//           z-index: 1;
//         }
//    }

    /*    &:nth-child(3) {
          background: #f5f5f5;
        }
        &:nth-child(4) {
          background: #f7f7f7;
        }*/
    &:first-child {
      background: $nav-color;
      border-right: none;
      color: #fff;
      font-weight: bold;
      &::after {
      }
      &::before {
        background: $nav-color;
        border-color: $nav-color;
      }

    }
    &:last-child {
      font-style: italic;
      border-right: none;
      &::after {
        opacity: 0;
      }
      &::before {
        opacity: 0;
      }
    }
  }
}

.l-region--sidebar-first {

  fieldset {
    margin: 0;
  }

  position: relative;
  box-shadow: #eee 20px 0px 21px -14px;
  padding-top: 0px;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;


  select {
    background: #fff;
//    color: #aaa;
    padding: 10px;
//    margin-left: 10px;
    width: 90% !important;
    margin-bottom: -20px;
    margin-top: 0;
  }

  .views-submit-button {
    display: none;
  }
  
  margin-top: 5px;

  .view-content, .views-widget {
    background: #fcfcfc;
    position: relative;

    .active-trail {
//      background: #000;
//      border-top: double thin #eee;
//      border-bottom: double thin #eee !important;
      position: relative;
      a {
        color: #000;
        font-weight: bold;
      }
    }
  
    .views-row, .form-item > .form-item {
      margin-bottom: 0px;
      padding: 15px 0px 15px 0px;
      border-bottom: solid thin #eee;
      font-size: 1em;
      font-weight: 300;
      color: #666;
      // @include clearfix;

      img {
//        float: left;
        margin: 0 10px 0px 0;
      }

      a:not(.link-edit-summary):not(.cke_button) {
        display: inline-block;
        width: 100%;
        height: 100%;
        //padding-left: 20px;
      }
      
      .views-field-body .field-content {
        height: auto;
        position: relative;
      }
    }
  }
}

.l-region--sidebar-first {

  .views-field-field-event-start-date {
    color: #aaa;
    text-transform: none;
    margin: 3px 30px 0 0;
  }

  // mrec example spacing
  #block-block-13 {
    margin: 20px 20px 0 0;
  }
}

.current {
  color: $bh-blue;
  font-weight: bold;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: -73px;
    top: 0;
    height: 60px;
    width: 60px;
    background: #000000;
  }
}

.sidebar-roller.hover {
  opacity: 0.5;
}

.sidebar-roller:hover{
  opacity: 1;
}

.sidebar-roller {
  padding: 20px 0px 20px;
  position: absolute;
  width: 30px;
  height: 100%;
  top: 5px;
  left: 0;
  background: #666;
  opacity: 0.1;
//  @include vendor-prefix(transition, 0.3s linear opacity);

  > a {
    display: block;
    text-align: center;
    color: white;
    margin-bottom: 10px;
    width: 100%;
  }
}

.view-directories .views-row a {
  margin-left: 20px;
}

/* Hide the first row as it contains no content - not sure what generates it*/
.page-publication-archive .view-display-id-media_archive_brandid_search .views-row-first {
  padding: 0px;
}

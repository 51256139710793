.views-field-field-product-image {
  text-align: center;
  margin-top: 30px;

    img {
      max-height: 700px;
      height: auto;
      width: auto; 
    }
  }

.node--product--full {
  
  .product-stats {
    float: right;
    width: 20%;
    margin-left: 20px;
    .field--name-field-rrp,
    .field--name-field-on-shelf,
    .field--name-field-packaging,
    .field--name-field-packsize {
      border-radius: 50px;
      overflow: hidden;
      width: 100%;
      text-align: center;
      border: solid thin $border-color;
      margin-right: 0%;
      margin-bottom: 10px;
      
      .field__label {
        background: $bh-blue;;
        color: #fff;
        padding: 10px 20px;
      }
      
      .field__items {
        padding: 10px 20px;
      }
      
    }
  }

  
  .field--name-field-product-image {
    .field__item {
      float: left;
      padding: 10px;
      margin-right: 20px;
      position: relative;
      border: solid thin $border-color;
      
      i {
        position: absolute;
        top: 0;
        right: 0;
        color: $bh-blue;
        background: #fff;
        padding: 2px;
        border-radius: 3px;
      }
    }
  }
}

#block-views-brand-products-block-2 {

  .owl-item {
    text-align: center;
  }
  
  .brand {
    text-transform: uppercase;
    font-size: 0.8em;
    margin-bottom: 5px;
  }

  a {
    display: block
  }

  img {
    
  }
}

/*******************************************************************************
 * simple.layout.scss
 *
 * Styling of the "simple" layout demonstrating the Compass grid extension Susy
 * and building mobile first layouts.
 ******************************************************************************/

@import "../general";

$gutter-width: 4em;

.l-region--highlighted,
.l-region--help,
.l-region--sidebar-first,
.l-region--sidebar-second,
.l-region--top-first,
/*.l-region--bottom-first,*/
.l-region--bottom-second {
  margin-bottom: $vert-spacing-unit;
}

.l-content {
  border-top-color: #7DC06C;
  padding-right: 60px;
}

.l-region--sidebar-first {
  border-top-color: #999;
}

.l-region--sidebar-second {
  border-color: #7DC06C;
  padding: 0px 0px 20px 0px;
//  border: solid thin #eee;
//  border-right: none;
//  border-bottom: none;
}

//@include at-breakpoint($tab) {
//  .l-header,
//  .l-main,
//  .l-region--footer {
//    @include set-container-width; // Reset only the container width (elements have already been declared as containers).
//  }
//
//  .l-region--leaderboard {
//    @include span-columns(12 omega, 12);
//  }
//  /*.t-header {*/
//  /*  @include span-columns(12 omega, 12);*/
//  /*}*/
//  .l-region--mid-leaderboard {
//    @include span-columns(12 omega, 12);
//  }
//
///*  .l-region--header{
//    @include span-columns(16, 16); // Span the last (omega) 10 columns of 16.
//  }
//*/
//  .has-sidebar-first {
//    .l-content {
//      @include span-columns(8 omega, 12); // Span the last (omega) 12 columns of 16.
//    }
//    .l-region--sidebar-first {
//      @include span-columns(4, 12); // Span 4 out of 16 columns.
//    }
//  }
//  .has-sidebar-second {
//    .l-content {
//      @include span-columns(8, 12); // Span 12 out of 16 columns.
//    }
//    .l-region--sidebar-second {
//      @include span-columns(4 omega, 12); // Span the last (omega) 4 columns of 16.
//      clear: none;
//    }
//  }
//
//  .l-region--top-first {
//    @include span-columns(12 omega, 12);
//  }
///*  .l-region--top-second {
//    @include span-columns(12 omega, 12);
//  }*/
//
//  .l-region--bottom-first {
//    @include span-columns(8, 12);
///*    border-right: solid thin #ddd;
//    padding-right: 30px;
//*/  }
//  .l-region--bottom-second {
//    @include span-columns(4 omega, 12);
//  }
//  .has-two-sidebars {
//    .l-content {
//      @include span-columns(4, 12); // Span 8 out of 16 columns.
//        /*@include push(4, 16);*/
//        /*border-right: solid thin #ddd;*/
//        /*box-shadow: #f7f7f7 10px 0 10px;*/
//        /*padding-right:10px*/
//      // @include push(4, 16);  // Push element by adding 4 out of 16 columns of left margin.
//    }
//    .l-region--sidebar-first {
//      @include span-columns(4, 12); // Span 4 out of 16 columns.
///*      @include pull(11, 16);
//*/    }
//    .l-region--sidebar-second {
//      @include span-columns(4, 12); // Span 4 out of 16 columns.
//      @include omega; // This element spans the last (omega) column.
//      clear: none;
//    }
//  }
//}

.block--instagram-social-feed-instagram-social-feed-block {
  h2 {
    text-indent: -99999px;
    background-image: url("/sites/all/themes/bd/images/instagram_block_logo.png");
    background-repeat: no-repeat;
  }
  margin-bottom: 15px;
}

.instagram-social-feed {
  position: relative;
  display: block;
  max-width: 366px;
  max-height: 366px;
  height: 366px;
  overflow: hidden;
  ul {
    padding: 0;
    position: relative;
    li {
      margin: 0;
      max-width: 366px;
      height: auto;
      position: absolute;
      opacity: 0;
      &:nth-child(1) {
        animation: xfade 48s 42s infinite;
      }
      &:nth-child(2) {
        animation: xfade 48s 36s infinite;
      }
      &:nth-child(3) {
        animation: xfade 48s 30s infinite;
      }
      &:nth-child(4) {
        animation: xfade 48s 24s infinite;
      }
      &:nth-child(5) {
        animation: xfade 48s 18s infinite;
      }
      &:nth-child(6) {
        animation: xfade 48s 12s infinite;
      }
      &:nth-child(7) {
        animation: xfade 48s 6s infinite;
      }
      &:nth-child(8) {
        animation: xfade 48s 0s infinite;
      }
      img {
        width: 366px;
        height: auto;
        max-height: 366px;
      }
    }
  }
}

@keyframes xfade{
  0%{
    opacity: 1;
  }
  10.5% {
    opacity: 1;
  }
  12.5%{
    opacity: 0;
  }
  98% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.node-type-webform.logged-in {


  .webform-client-form {
    margin-top: 50px;
    font-weight: 300;
    padding: 40px;
    border: solid thin $border-color;
    background: #f9f9f9;

    .form-item {
      margin-bottom: 30px;
      
      .form-type-radio {
        margin-bottom: 15px;
      }
    }
  }
}

input[type=text], input[type=password], textarea, .form-control, .resizable-textarea textarea {
  width: 68%;
  border: solid thin #dddddd;
  padding: 0px 20px;
  font-size: 14px !important;
  height: 42px;
  font-weight: 300;
  color: #000;
  outline: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
label:not(.option), .form-actions label:not(.option) {
  min-width: 120px;
  display: block;
  font-weight: 300;
  float: left;
  padding-top: 10px;
  margin-right: 30px;
}
.resizable-textarea .grippie {
  display: none;
}
.resizable-textarea textarea {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  height: 300px;
  width: 100%;
}

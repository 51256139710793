#bd-share {
  position: fixed;
  top: 0;
  right: -100px;
  bottom: 0px;
  margin: auto 0;
  height: 272px;
  width: 67px;
  background: #fff;
  text-align: center;
  border: solid thin $border-color;
  border-right: none;
  overflow: auto;
  transition: 0.2s;
  
  &.visible {
    right: 0;
  }
  
//  @include social-media-icons();
  
  i {
    &:last-child {
      border-bottom: none;
    }
    
    font-size: 1.2em;
    -webkit-transition: 0.2s;
    -moz-transform: 0.2s;
    color: #000;
    padding: 20px 0 32px;
    border-bottom: solid thin #eee;
    width: 100%;
    height: 35px;
 
  }
}

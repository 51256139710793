.bd_banner {
  display: block;
  width: 100%;
  height: auto;
  padding-top: 15px;
  padding-bottom: 10px;
  background-color: #000;
  color: #fff;
  text-align: center;
}

.bd_banner_title {
  margin-top: 0;
  padding: 0;
  margin-bottom: 20px;
  color: #fff;
  font-size: 30px;
}

.bd_banner_buttons_container {
  margin-bottom: 5px;
}

.bd_banner_fixed {
  position: fixed;
  left: 0;
  z-index: 999999;
}

.bd_banner_fixed_bottom {
  bottom: 0;
  box-shadow: 0 -5px 15px rgba(100, 100, 100, 0.3);
}

.profile-wrapper {
  position: relative;
  a.dropdown:hover + ul {
    display: block;
  }
  ul {
    //display: none;
  }
  a {
    text-transform: uppercase;
    &:hover {
      color: $bd_pink;
    }
  }
}

ul.profile-inner {
  position: relative;
  margin-left: auto;
  margin-top: 0em;
  //margin-right: 20px;
  p.dropdown {

    @media screen and (max-width: 979px) {
      text-align: center;
    }
    .fa-times {
      content: "\f00d";
      font-family: fontAwesome;
      font-style: normal;
      display: none;
      color: $bd_pink;
      position: absolute;
      right: 15%;
      z-index: 9999999999;
      font-size: 2em;
    }
  }
   > li {
     display: block;
     float: none;
     //margin-right: 10px;
     position: relative;
     line-height: 1em;
   }
   ul {
     display: none;
     position: absolute;
     padding: 0px;
     margin-top: 2em;
     background: #fff;
     top: -5px;
     z-index: 99999;
     max-width: 100%;
     width: 210px;
     @media screen and (max-width: 979px) {
       width: 100%;
       text-align: center;
     }
   }
   ul > li {
     float: none;
     line-height: 3.5em;
   }
  @media screen and (min-width: 980px) {
    > li:hover > ul,
    > a:hover + ul {
      display: block;
    }
  }
  a {
    text-transform: uppercase;
    &:link {
      color:$link_color;
    }
    &:visited {
      color:$link_visited_color;
    }
    &:hover {
      color: $link_hover_color;
    }
    &:active {
      color:$link_active_color;
    }
  }
}
section#block-bd-custom-bd-user-extras {
  .username-block {
    cursor: pointer;
    position: relative;
    @media screen and (max-width: 979px) {
      width: 100%;
    }
  }
  .username-block:hover + ul.profile-inner {
    display: block !important;
  }
  ul.profile-inner:hover {
    display: block !important;
  }
}
a.site-logo {
  position: relative;
  z-index: 999;
}

#block-views-spotlight-block, #block-views-spotlight-block-1 {
  padding: 20px;
//  @include clearfix;
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 0px;
  padding-bottom: 0px;


  h2.block-title {
    font-style: normal;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 2em;
    text-transform: uppercase;
    //font-size: 40px;
    font-family: inherit;
    &::before {
      content: " ";
      display: table;
      background: #ccc;
      width: 30%;
      height: 1px;
      position: relative;
      margin-top: 20px;
      float: left;
      @media screen and (max-width: $screen-fx-max) {
        width: 20%;
      }
    }
    &::after {
      content: " ";
      display: table;
      background: #ccc;
      width: 30%;
      height: 1px;
      position: relative;
      margin-top: 20px;
      float: right;
      @media screen and (max-width: $screen-fx-max) {
        width: 20%;
      }
    }
  }

  .view-footer {
    p {
      //font-size: 0.8em;
      color: #000;
      margin: 0 0 5px;
      a.view-more {
        margin: 3em auto 0px auto!important;
      }
    }
  }


  
  .view-header {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    text-align: center;
    z-index: 1;
    
    p {
    }
  }

  .owl-pagination {
    position: absolute;
    bottom: 00px;
    right: 12px;
    z-index: 999;

    .owl-page {
    
      span.owl-numbers {
        padding: 5px 9px;
      }
      
      &.active span.owl-numbers {
        background: $bh-blue;
      }

    }
  }
  
  .view-content {

    max-width: 100%;
    .col-xs-12.col-sm-6.col-md-4.col-lg-4 {
      margin-bottom: 3em;
      @media screen and (max-width: $screen-xs-max) {
        width: 100%;
        float: left;
      }
      .views-field-nothing {
        .views-field-field-image {
          float: none;
          margin-right: 0px;

          img {
            width: 100%;
          }
        }

        .news-wrapper {
          padding-bottom: 3em;
          -webkit-box-shadow: 0px 1px 5px 1px #eeeeee;
          -moz-box-shadow: 0px 1px 5px 1px #eeeeee;
          box-shadow: 0px 1px 5px 1px #eeeeee;

          @media screen and (min-width: $screen-md-min) {
            min-height: 456px;
          }

          &:hover {
            -webkit-box-shadow: 0px 1px 22px 1px #666666;
            -moz-box-shadow: 0px 1px 22px 1px #666666;
            box-shadow: 0px 1px 22px 1px #666666;
          }

          .views-field-title {
            padding: 0px 10px;
            h4 {
              @include headH();
              a {
                text-align: center;
                display: block;
                margin-top: 2em;
                &:link {
                  color: $link_color;
                }
                &:visited {
                  color: $link_visited_color;
                }
                &:hover {
                  color: $link_hover_color;
                  text-decoration: none;
                }
                &:active {
                  color:$link_active_color;
                }
              }
            }
          }
        }
      }

      img {
        margin-bottom: 10px;
        width: 100%;
        height: auto;
      }
    }

  }
  
}

body.page-brands {

  .view-filters .views-exposed-widget .views-widget .bef-select-as-links .form-item .form-item {
    width: 30%;
    background: #ffffff;
    text-align: center;
    float: none;
    display: inline-block;
    @media screen and (max-width: 543px){
      width: 100%;
    }

    a {
      &:link {
        color: $link_color;
      }
      &:visited {
        color: $link_visited_color;
      }
      &:hover {
        color: $link_hover_color;
        text-decoration: none;
      }
      &:active {
        color:$link_active_color;
      }
    }
  }
  h1 {
    margin-bottom: 0;
    padding-bottom: 10px;
    border-bottom: none !important;
    text-align: center;
  }

  .views-exposed-form {
    //display: none;
//    @include at-breakpoint($tab) {
//      display: block;
//    }
    padding: 10px 40px;
    padding-bottom: 0;
    margin-bottom: 80px;
    border-bottom: double 2px $border-color;

    text-align: center;
    label {
      padding-bottom: 30px !important;
      border-bottom: none !important;
    }

    select {
      width: 40%;
    }
  }

  > .view-content {
    clear: both;
    margin-top: 50px;
  }

  .form-type-bef-link {
//    @include span-columns(1, 4);
    margin-bottom: 5px;
    font-size: 1em;
    color: #999;
    font-weight: 300;
    text-transform: uppercase;
    
//    &:first-child {
//      width: 100%;
//      text-align: center;
//    }
    &:nth-child(4n+0) {
      @include omega;
    }

    a {
      display: inline-block;
      padding: 2px;
      width: 100%;
      &.active {
        background: #fff;
        color: #000;
      }
    }

  }

  .selected:nth-child(odd) {
    background: #000;
    color: #fff !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-weight: normal;
  }

  .children {
    padding-left: 10px !important;
    &::before {
      content: "/ ";
    }
  }

  .views-exposed-widget {
    padding-right: 0;
  }

  .views-exposed-widget label {
    @include sub-heading;
    display: block;
  }

  #glossary_nav {
    width: 100%;
    text-align: center;
    @include divider(40px, $border-color, bottom);
    border-bottom: none;
    margin-bottom: 0;
    @include clearfix;
    @media screen and (max-width: $screen-xs-max){
      padding-bottom: 0px;
    }

    a {
      border: solid thin #fff;
      padding: 6px 0px;
      width: 1.95em;
      margin-right: 0px;
      color: #fff;
      text-align: center;
      display: inline-block;
      background: $bh-blue;
    }
    a:hover {
      border-color: $bh-blue;
      background: #fff;
      color: #666;
      text-decoration: none;
    }

    &.sticky {
      position: fixed;
      top: 52px;
      left: 0;
    }
  }

  .letter {
    width: 100%;

    h4 {
      font-size: 2.2em;
      color: #fff;
      background: #000;
      width: 10%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 40px;
    }
  }

  .letter-block {
    position: relative;
    margin-bottom: 50px;
    padding-bottom: 30px;
    border-bottom: dashed 1px #ddd;
    overflow: auto;
    clear: both;
    padding-left: 60px;
    padding-right: 60px;

    @media (min-width: 34em) {
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -moz-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
    }

    > a {
      position: absolute;
      top: -70px;
    }

    .views-row {
      margin-bottom: 20px;
      font-size: 1.05em;
      text-align: center;

      a {
        font-weight: 300;
        letter-spacing: unset;
        display: inline-block;
        &:link {
          color: $link_color;
        }
        &:visited {
          color: $link_visited_color;
        }
        &:hover {
          color: $link_hover_color;
        }
        &:active {
          color: $link_active_color;
        }
      }
    }
  }

}

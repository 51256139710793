section#block-views-home-page-top-block, section#block-views-home-page-top-block-6,
section#block-views-home-page-top-block-11, section#block-views-home-page-top-block-10 {
    h2.block-title {
      font-style: normal;
      text-align: center;
      padding-top: 1em;
      padding-bottom: 2em;
      text-transform: uppercase;
      /*font-family: inherit;*/
      &::before {
        content: " ";
        display: table;
        background: #ccc;
        width: 30%;
        height: 1px;
        position: relative;
        margin-top: 20px;
        float: left;
        @media screen and (max-width: $screen-fx-max) {
          width: 20%;
        }
      }
      &::after {
        content: " ";
        display: table;
        background: #ccc;
        width: 30%;
        height: 1px;
        position: relative;
        margin-top: 20px;
        float: right;
        @media screen and (max-width: $screen-fx-max) {
          width: 20%;
        }
      }
    }

    .view-content {
      max-width: 100%;
      .col-xs-12.col-sm-6.col-md-4.col-lg-4 {
        padding-bottom: 3em;
        @media screen and (min-width: 421px) and (max-width: $screen-xs-max) {
          width: 50%;
          float: left;
          .views-field-title {
            padding: 0px 10px;
            a {
              padding-bottom: 1em;
            }
          }
        }

        @media screen and (max-width: 420px) {
          width: 100%;
          float: none;
        }

        .views-field-nothing {
          .views-field-field-image {
            float: none;
            margin-right: 0px;
            img {
              width: 100%;
            }
          }
          .news-wrapper {
            -webkit-box-shadow: 0px 1px 5px 1px #eeeeee;
            -moz-box-shadow: 0px 1px 5px 1px #eeeeee;
            box-shadow: 0px 1px 5px 1px #eeeeee;
            @media screen and (min-width: $screen-md-min) {
              min-height: 495px;
            }
              @media screen and (min-width: 581px) and (max-width: $screen-xs-max) {
                min-height: 515px;
              }
              @media screen and (min-width: 421px) and (max-width: 580px) {
                min-height: 430px !important;
              }
            &:hover {
              -webkit-box-shadow: 0px 1px 22px 1px #666666;
              -moz-box-shadow: 0px 1px 22px 1px #666666;
              box-shadow: 0px 1px 22px 1px #666666;
            }
            .views-field-title {
              h4 {
                /*font-size: inherit;*/
                a {
                  /*text-transform: uppercase;*/
                  text-align: center;
                  display: block;
                  padding: 1em;
                  @include headH();
                  &:link {
                    color: $link_color;
                  }
                  &:visited {
                    color: $link_visited_color;
                  }
                  &:hover{
                    text-decoration: none;
                    color: $link_hover_color;
                  }
                  &:active {
                    color: $link_active_color;
                  }
                }
              }
            }
            .views-field-created {
              padding: 0px 10px 2em;
              display: block;
              text-align: center;
              color: #b7b7b7;
              font-size: 0.8em;
            }
            .views-field-field-primary-article-category {
              padding: 0px 10px;
              a {
                text-align: center;
                text-transform: uppercase;
                letter-spacing: normal;
                display: block;
                margin-top: 1em;
                &:link {
                  color: $link_hover_color;
                }
                &:visited {
                  color: $link_visited_color;
                }
                &:hover {
                  color: $link_active_color;
                  text-decoration: none;
                }
                &:active {
                  color:$link_active_color;
                }
              }
            }
          }
        }
      }
    }
}
a.view-more {
    color: #ffffff !important;
    text-align: center;    display: block;
    width: 166px;
    height: 40px;
    text-decoration: none;
    text-transform: uppercase;
    margin: 3em auto;
    font-size: 13px;
    font-weight: bold;
    line-height: 40px;
    &:link {
      background: $primary_button_background;
    }
    &:visited {
      background: $primary_button_background;
    }
    &:hover {
      background: $primary_button_hover_background;
    }
    &:active {
      background: $primary_button_pressed_background;
    }

}

#logged-out {
	section#block-block-9 {
		display: inline-block;
		width: 100%;
		padding-top: 20px;
	}
	section#block-bd-custom-bd-user-extras {
		display: none;
		a.top-login {
			text-transform: uppercase;
		}
	}
	.form-content {
		ul.pager {
			display: none;
		}
	}
	.view-homepage-tile {
	.view-content {
				@media screen and (max-width: $screen-xs-max) {
					padding: 22px;
					.col-xs-12.col-sm-6.col-md-4.col-lg-4, .col-xs-6.col-sm-6.col-md-4.col-lg-4 {
						&:nth-child(2n+2) {
							.tile-opaque {
								background: $bd-brand !important;
							}
						}
					}
				}
			}
			padding-bottom: 87px;
			.view-header {
				h2 {
					margin: 0px;
				}
			}
			.col-xs-12.col-sm-6.col-md-4.col-lg-4, .col-xs-6.col-sm-6.col-md-4.col-lg-4 {
				padding: 0px;
			.node-teaser {
				padding-bottom: 0 !important;
				padding-top: 0 !important;
			}
			&:nth-child(2n+2) {
				.tile-opaque {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					height: 100%;
					background: $pitch !important;
					opacity: .6;
				}
			}
		}
		background: $bg;
		.tiles-wrapper {
			position: relative;
			.image-wrapper {
				.field-type-image {
					img {
						width: 100%;
					}
				}
				.field-name-field-tile-title {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					text-align: center;
					height: 100%;
					padding-top: 30%;
					font-size: 2em;
					font-family: 'latomedium';
					text-transform: uppercase;
					padding-left: 5%;
					padding-right: 5%;
					color: $bg;
					@media screen and (max-width: 480px) {
						font-size: 1.5em;
					}

//					&:nth-child(even) {
//				     background: $bd-brand;
//					}
//					&:nth-child(odd) {
//						background: $pitch;
//					}
				}
				.tile-opaque {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					height: 100%;
					background: $bd-brand;
					opacity: .6;
				}
			}
			.content-wrapper {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				overflow: hidden;
				width: 100%;
				height: 0;
				transition: .5s ease;
				background: $bd-brand;
				color: $bg;
				padding: 0px 3%;
				text-align: center;
				h3 {
					text-transform: uppercase;
					font-size: 1.5em;
					font-family: 'latoheavy';
					text-decoration: underline;
					padding: 0px;
					a {
						&:hover {
							color: $bg;
						}
					}
				}
				.field-name-body {
					p {
						color: $bg;
					}
				}
				li.node-readmore {
					a {
						color: $pitch;
						&:hover {
							color: $bg;
						}
					}
				}
			}
			&:hover .content-wrapper {
				height: 100%;
			}
		}
	}
	.join {
		@media screen and (max-width: $screen-xs-max) {
			padding-top: 0px !important;
			.col-sm-6 {
				padding: 0px;
				h2 {
					text-align: center !important;
				}
				ul.join-list {
					 max-width: 100% !important;
					 padding: 22px !important;
				}
				.button {
					margin: 0 auto;
				}
			}
			.join-users {
				position: relative;
				.mobi {
					position: absolute;
					top: 0px;
				}
				.mob {
					margin-top: 60%;
				}
			}
		}
		background: #ffffff;
		padding-top: 87px;
		padding-bottom: 87px;
		.join-brands, .join-users {
      .col-sm-6 {
				padding-left: 0px;
				h2 {
					text-align: left;
				}
				ul.join-list {
					max-width: 430px;
					padding-left: 16px;
					li {
						display: flex;
						&::before {
							content: "\2022";
							color: #000000;
							font-weight: bold;
							display: flex;
							width: 2em;
              max-width: 15px;
							margin-left: -1em;
						}
					}
				}
				.button {
					@include buttonB();
					a {
						background: #000000;
						&:hover {
							color: #000000;
							border: solid 1px #000000;
						}
					}
				}
				.join-users-extras {
					max-width: 450px;
					margin: 0 auto;
				}
			}
		}
	}
	.stats {
		background: $bd-brand;
		.statistics {
			@media screen and (max-width: 769px) {
				padding-bottom: 0px;
				.col-xs-12.col-sm-3.col-md-3.col-lg-3 {
					width: 50%!important;
					margin-bottom: 87px;
				}
			}
				.col-xs-12.col-sm-3.col-md-3.col-lg-3 {
					width: 25%;
			}
			padding-top: 87px;
			padding-bottom: 87px;
			max-width: $site-width;
			margin: 0 auto;
			.stats-icon {
				max-width: 100px;
				//padding-left: 50px;
				margin: 0 auto;
        min-height: 130px;
        height: 130px;
			}
			.stats-description {
				max-width: 150px;
				text-align: center;
				color: $bg;
				margin: 0 auto;
			}
			.stats-figures {
				font-family: 'latoheavy';
				color: $bg;
				padding-top: 22px;
				padding-bottom: 22px;
				max-width: 150px;
				text-align: center;
				font-size: 2rem;
				margin: 0 auto;
			}
		}
	}
	.testimonials {
		background: #f1f0f2;
    // I didn't want to delete this block as it may be used again later, so I'm simply hiding it
    .testimony {
      display: none;
    }
		.testimony {
			max-width: $site-width;
			margin: 0 auto;
			padding-bottom: 87px;
			.extras {
				@media screen and (max-width: $screen-xs-max) {
					min-height: auto;
					margin-bottom: 22px;
				}
				background: #ffffff;
				padding: 47px;
				min-height: 442px;
				h3 {
					font-family: 'latomedium';
					text-transform: uppercase;
					padding: 0px;
					font-size: 20px;
					margin: 0px 0px 10px;
					text-align: center;
				}
				.desc {
					text-transform: uppercase;
					color: #999;
					font-family: 'latothin';
					letter-spacing: 2px;
					font-size: 0.9em;
					text-align: center;
					padding-bottom: 20px;
				}
				img {
					margin: 0 auto;
					display: block;
				}
				.user-summ {
					padding-top: 20px;
					line-height: 32px;
				}
			}
		}
	}
	.stats-reg {
		max-width: $site-width;
		margin: 0 auto;
		padding: 57px 0px;
		.button {
			max-width: 247px;
			margin: 0 auto;
			a {
				padding: 24px 14px;
				background: #000000;
				&:hover {
					color: #000000;
					text-decoration: none;
					background: none !important;
					border: solid 2px #000000;
					padding: 23px 13px;
				}
			}
		}
	}
	@media screen and (max-width: 480px) and (min-width: 321px) {
		section#block-tb-megamenu-main-menu {
			.tb-megamenu {
				button.btn {
					top: -5.7em!important;
				}
			}
		}
	}
  @media screen and (max-width: 320px) {
    section#block-tb-megamenu-main-menu {
      .tb-megamenu {
        button.btn {
          top: -5.3em!important;
        }
      }
    }
  }
	@media (max-width: 979px) {
		section#block-tb-megamenu-main-menu {
			.tb-megamenu {
				button.btn {
					top: -11em;
				}
			}
		}
		.is-user-visible {
			section#block-bd-custom-bd-user-extras {
				display: none!important;
			}
		}
		.tb-megamenu .collapse.in {
			padding-top: 0px!important;
		}
		l-region--branding #block-bd-custom-bd-user-extras {
			left: 100px;
			bottom: -45px;
		}
		.tb-megamenu .nav-collapse, .tb-megamenu .nav-collapse.collapse {
			position: absolute;
			top: -5.7em;
		}
	}
	@media (min-width: 980px) {
		/*.navbar-default .navbar-collapse, .navbar-default .navbar-form {
			margin-top: -50px;
		}*/
		section#block-bd-custom-bd-user-extras {
			display: none;
		}
		section#block-tb-megamenu-main-menu {
      margin-left: auto;
			.tb-megamenu .nav > li > a {
				padding: 0px 15px 0px 13px;
			}
		}
	}
}


.node-type-job {

  h1 {
    margin-bottom: 10px;
    padding-bottom: 0px;
  }

  .node--job--full, .node-job {

    ul {
      li {
        list-style: disc;
        margin-bottom: 15px;
      }
    }

    ol {
      li {
        list-style: decimal;
        margin-bottom: 15px;
      }
    }


    .field--name-body {
      @include clear-divider(top);
      //ul {
      //  li {
      //    list-style: inherit;
      //  }
      //}
    }

    .field--name-field-image {
      float: left;
      width: auto;
      height: auto;
      margin-right: 20px;
      padding: 20px;
      border: solid thin $border-color;
    }

    .submitted {

      .field-start-date {
//        float: left;
        margin-right: 20px;
      }

      .field--name-field-location {
//        float: left;

        .adr > div {
          &:after {
            content: ", ";
          }
          float: left;
          margin-right: 5px;
        }
      }


      overflow: auto;
      color: #999;
      font-size: 0.8em;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: solid thin #dddddd;
      margin-bottom: 30px;
    }
  }
}

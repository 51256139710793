.pager {
  li {
    list-style: none;
    display: inline;
  }
  li a, .pager-current {
    padding: 0;
    border: solid thin #fff;
    padding: 6px 0.8em;
    height: 1.95em;
    margin-right: 0px;
    color: #fff;
    text-align: center;
    display: inline-block;
    background: $bh-blue;
    overflow: hidden;
    display: inline;
  }
  .pager-current {
    border: solid thin $bh-blue;
    background: #fff;
    color: $bh-blue;
  }
  li a:hover, .pager_item--current {
    border: solid thin $bh-blue;
    color: #fff;
    text-decoration: none;
  }
  .pager__item--current {
    border-color: $bh-blue;
    color: $bh-dark-blue;
  }
}

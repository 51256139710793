
.l-header {
  margin-bottom: 0px !important;
  max-width: 1528px;
  margin: 0 auto;
}

.l-region--leaderboard {
  text-align: center;
  border-bottom: solid thin $border-color;
  margin-bottom: 0px;
}

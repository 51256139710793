.node--job--search {

  .node__title {
    position: static;
    padding: 50px 10px 0px !important;
    width: 100%;
    height: auto;
    z-index: 2;
  }
  
  .field--name-field-start-date {
    padding: 0 5px 0 10px;
    margin-top: 0px;
    position: relative;
    z-index: 2;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    float: left;
    text-transform: uppercase;
//    background: $ribbon-3d-back-background-color;
    left: -5px;
//    @include ribbon($ribbon-3d-back-background-color, top, left, 5px, 5px);
  }
  
  .submitted {
    position: absolute;
    top: 14px;
    z-index: 6;
    left: 10px;
    font-size: 0.8em;
    font-weight: bold;
  }
  
  img {
    position: absolute;
    border: solid thin $border-color;
    padding: 10px;
    width: 85%;
    height: auto;
    top: auto;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
  }
}

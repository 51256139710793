body.page-node, body.page-my-boards, body.page-my-board, body.page-directory {
  .exceed-form-content {
    height: 245px;
  }
  .modal-content {
    max-width: 600px;
    margin: auto;
    h4#modal-title {
      @include title();
      padding: 0px 3em 0em 2em;
    }
    .modal-header .close {
      margin-top: -2px;
      position: absolute;
      bottom: 1em;
      right: 1.4em;
      z-index: 99999999;
      background: $bd_pink;
      padding: 5px;
      color: #F7796D;
      text-shadow: none;
      opacity: inherit;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      cursor: pointer;
      //padding-top: 0px;
      line-height: 20px;
      font-weight: 400;
      font-size: 14px;
      height: 35px;
      padding-top: 6px;
      padding-bottom: 6px;
      /* width: 120px; */
      padding-right: 32px;
      padding-left: 32px;

      @media screen and (max-width: 768px) {
        background: none!important;
        position: relative!important;
        bottom: inherit!important;
        right: 0px!important;
        color: #FFFFFF;
      }
      &::after {
        content: "Cancel";
        color: #fff;
        font-family: inherit;
        font-size: 14px;
        font-weight: normal;
        position: relative;
        text-transform: uppercase;
        margin-left: -9px;
        font-weight: 400;
        @media screen and (max-width: 768px) {
          color: $bd_pink!important;
          content: "x" !important;
          font-size: 1.5em !important;
          text-indent: 0px;
          margin-right: 10px;
          font-weight: bold;
          font-family: inherit;
        }
      }
    }
    .btn-success {
      background: $bd_pink;
      border: 0px;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      text-transform: uppercase;
      font-family: 'latothin';
      span.icon {
        display: none;
      }
    }
    #magical-modal-link {
      position: relative;
      background: $bd_pink;
      padding: 5px;
      color: #fff;
      text-transform: uppercase;
      bottom: inherit;
      left: inherit;
      font-family: inherit;
      float: right;
      right: 147px;
      height: 34px;
      padding-top: 7px;
      text-align: center;
      display: block;
      padding-left: 10px;
      padding-right: 10px;
      @media screen and (max-width: $screen-xs-max) {
        width: 45%;
        right: 0px;
      }
      a {
        &:hover {
          text-decoration: none;
          color: #ffffff;
        }
      }
    }
    button.btn-default {
      background: $bd_pink;
      border: 0px;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      text-transform: uppercase;
      font-family: 'latothin';
      color: #ffffff;
    }
  }
  #modalBackdrop {
    background: rgba(0, 0, 0, 0.8)!important;
    opacity: inherit!important;
  }
}
body.node-type-brand, body.page-directory {
  #modalContent {
    width: 85%!important;
    .modal-dialog {
      width: 100%!important;
      height: auto!important;
      @media screen and (min-width: 1289px) {
        width: 40%!important;
      }
      @media screen and (min-width: 640px) and (max-width: 1288px) {
        width: 80%!important;
      }
      @media screen and (max-width: $screen-xs-max) {
        width: inherit !important;
        height: 100% !important;
        min-height: 100%!important;
      }
      #modal-content {
        max-width: 100%!important;
        .form-submit {
          background: $bd_pink !important;
          border-radius: 0px;
          -moz-border-radius: 0px;
          -webkit-border-radius: 0px;
          border: 0px;
          float: right;
          margin-right: 168px;
          font-family: inherit;
          width: 120px;
          height: 34px;
          @media screen and (max-width: $screen-xs-max) {
            width: 45%;
            margin-right: 20px;
          }
        }

        form {
          .form-item-requester-address {
            label.control-label {
              max-width: 120px;
            }

            input.form-control {

            }
          }
        }
        input[type=text], .form-textarea-wrapper {
          width: 72%!important;
          @media screen and (max-width: $screen-xs-max) {
            width: 100%!important;
          }
        }
        .form-item-report-message {
          .form-textarea-wrapper {
            width: 100%!important;
          }
        }
      }
    }
    .modal-content {
      .modal-header {
        .close {
          @media screen and (max-width: 768px) {
            position: relative!important;
            bottom: 0px!important;
            right: 0px!important;
          }
        }
      }
    }
  }
}
body.modal-open {
  #modal-content {
    form {
      .form-item-user-board {
        .select2-dropdown-open {
          //min-height: 150px;
        }
        .select2-dropdown-open {
          b {
            &::after {
              content: '\f077'!important;
            }
          }
        }
        .select2-container {
          width: 98%!important;
          margin: 0 auto;
          display: block;
          @media screen and (max-width: 768px) {
            width: 100%!important;
          }
          a.select2-choice {
            padding: 0 0 0 4px;
            border: solid 1px #CCCCCC;
            border-radius: 0px;
            -moz-border-radius: 0px;
            -webkit-border-radius: 0px;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            background: none;
            min-height: 50px;
            span.select2-chosen {
              padding: 10px 20px;
            }
            .select2-arrow {
              background-image: none;
              background: none;
              border: 0px;
              width: 40px;
              b {
                background: none;
                padding-top: 10px;
                &::after {
                  content: '\f078';
                  font-family: FontAwesome;
                  font-size: 1.3em;
                }
              }
            }
          }
        }
      }
    }
  }
  .select2-drop-auto-width {
    border-top: 0px!important;
    border: 1px solid #CCCCCC;
    border-radius: 0px;
    .select2-match {
      padding: 10px 8px;
    }
  }
}

#bd-add-to-board-form {
  @media screen and (max-width: 439px) {
    #magical-modal-link {
      height: inherit!important;
    }
    button.form-submit {
      height: 50px!important;
    }
  }
}

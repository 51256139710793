.node--pr--teaser {

  margin-bottom: 20px !important;
  padding-bottom: 20px !important;

  .content {
//    @include span-columns(10 omega, 12);

    img {

    }
  }


  div {
    font-weight: 300;
  }

  .submitted {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
  }
}

body.page-directory-media-directory {
  article {
    margin-bottom: 3em;
  }
  p.intro {
    display: none;
  }

  .node-media-staff, .node-media, .node-experts {
    .field--name-field-image {
      .img-responsive {
        margin: 0 auto;
      }
    }
    h2 {
      padding: 0px;
      text-align: left;
      margin-top: 0px;
      letter-spacing: inherit;
      &::before, &::after {
        display: none;
      }
      a {
        &:link {
          color: $link_color;
        }
        &:visited{
          color: $link_visited_color;
        }
        &:hover {
          color: $link_hover_color;
          text-decoration: none;
        }
        &:active {
          color: $link_active_color;
        }
      }
    }
    .content-staff {
      text-transform: uppercase;
      text-align: left;
      letter-spacing: inherit;
      font-size: inherit;
      font-style: normal;
      .field-name-field-email {
        text-transform: capitalize;
      }
      .field-type-text-with-summary {
        text-transform: initial;
      }page-directory-media-directory
      .group-contact-details {
        padding-top: 0px;
        padding-left: 0px;
        h3 {
          margin-top: 8px;
        }
      }
    }
    #magical-modal-link {
      text-transform: uppercase;
      margin-top: 2em;
      a {
        &:link {
          color: $link_color;
        }
        &:visited{
          color: $link_visited_color;
        }
        &:hover {
          color: $link_hover_color;
        }
        &:active {
          color: $link_active_color;
        }
      }
    }
  }

  .well {
      .select2-container {
        .select2-choice {
          padding: 5px;
          width: 195px;
          border: 1px solid #000000;
          box-shadow: none;
          border-radius: 0px;
          min-height: 46px;
          text-align: center;

          span.select2-arrow {
            display: none;
          }
        }
      }
  }
  .select2-drop-auto-width {
    width: 195px;
  }
}

.node-type-portfolio-hub {
  .portfolio-title {
    position: absolute;
    top: -36px;

    h3 {
      color: #fff;
      text-transform: uppercase;

      span {
        font-weight: bold;
      }
     }
  }
}


#block-views-homepage-slideshow-block {
  .attachment-before {
    float: left;
    margin-right: 1px;
    .views-field-title {
      background: none !important;
      font-size: 31px;
      font-weight: bold;
    }
  }
  
  .view-content {
    float: left;
    
    .views-row {
      position: relative;
      
      .views-field-title {
        position: absolute;
        bottom: 3px;
        left: 0px;
        padding: 10px;
        color: white;
        width: 100%;
        background: rgba(0,0,0,0.5);
      }
    }
    .small-image {
      width: 230px;
      margin-bottom: 1px;
      height: 134px;
    }
  }
  
}





@import '../bootstrap-sass/stylesheets/bootstrap/variables';

// Media query variables.
$tablet:              "screen and (min-width: #{$screen-sm-min})";
$normal:              "screen and (min-width: #{$screen-md-min})";
$wide:                "screen and (min-width: #{$screen-lg-min})";
$grid-breakpoint:     "screen and (min-width: #{$grid-float-breakpoint})";
$tab : "screen and (min-width: #{$screen-sm-min + 132})";

$wide-screen-lg: 100%;
$site-width: 1168px;
$inner-width: 830px;
$auto: 0 auto;
$none: none;
$bd-brand: #000;
$bd_pink: #F7796D;
$z: 0;
$trans: transparent;

/* Updated  colours */
$link_color: #333333;
$link_hover_color: #BE1E2D;
$link_active_color: #F06F7B;
$link_visited_color: #333333;

$primary_button_background: #BE1E2D;
$primary_button_hover_background: #BE1E2D;
$primary_button_pressed_background: #F06F7B;
$primary_button_disabled_background: #FFFFFF;

$primary_button_border: #BE1E2D;
$primary_button_hover_border: #BE1E2D;
$primary_button_pressed_border: #F06F7B;
$primary_button_disabled_border: #F06F7B;

/* End of Updated  colours */

  //from old theme
$pitch: #F7796D;
$bg: #f1f0f2;
$bh-blue: #000000; // #8AD4DE;
$bh-lightish-blue: lighten( $bh-blue, 40% );
$bh-light-blue: #F7796D;
$bh-dark-blue: #F7796D; //#3A929E; // darken( $bh-blue, 20% );
$alt-color-1: #45C55B;
$alt-color-2: #4A63AE;

$headline: #333;
$border-color: #ddd;
$border-color-light: #eee;
$text-highlight: #9B1417;
$color2: #7DC06C;

$gray-1: #ccc;

$main-nav: #000;
$main-nav-hover: #fff;
$main-nav-active: #fff;
$main-nav-border: #fff;
$nav-background: #fff;
$main-nav-dropdown: #333;

$footer-divider-color: rgba(#000, 0.2);
$footer-text-color: #333;
$footer-header-color: #000;

$social-media-colors: (
  facebook #3b5998,
  twitter #4099ff,
  google-plus #cd4030,
  linkedin #0074b3,
  instagram #517fa4,
  pinterest #cb2027,
  youtube #bb0000
);

@mixin bh-gradient-3() {
	// needs latest Compass, add '@import "compass"' to your scss
	background-color: rgb(52,181,208); // Old browsers
	@include filter-gradient(#000, #000, vertical); // IE6-9
	@include background-image(linear-gradient(top,  rgba(52,181,208,1) 0%,rgba(255,255,255,1) 51%,rgba(52,181,208,1) 100%));
}

@mixin bh-gradient-2() {
	background: #fff;
	border-bottom: solid 2px $bh-blue;
}


@mixin bh-gradient() {  background: $bh-blue;
	background-color: rgb(21,74,85); // Old browsers
	@include filter-gradient(#154a55, #f3f3f3, vertical); // IE6-9
	@include background-image(linear-gradient(top,  rgba(21,74,85,1) 0%,rgba(39,136,156,1) 21%,rgba(45,158,182,1) 31%,rgba(48,168,193,1) 38%,rgba(50,176,203,1) 48%,rgba(51,180,207,1) 59%,rgba(52,181,208,1) 100%,rgba(243,243,243,1) 100%));
}

// From blocks.scss
$border-radius: 30px;
$vert-spacing-unit: 20px;
$horz-spacing-unit: 2em;
$categories: -37;

$tab: 34em 16; // At 44em use 12 columns.
$desk: 60em 16; // At 70em use 16 columns.

// Legacy support.
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: true;
$legacy-support-for-ie8: true;

// New tricks
@mixin blockTitle() {
		font-style: normal;
		text-align: center;
		padding-top: 1em;
		padding-bottom: 2em;
		text-transform: uppercase;
	  font-family: 'latoheavy';
	  padding: 0px 3em 5em 2em;
	  letter-spacing: 2px;
	  font-size: 2rem;
		&::before {
			content: " ";
			display: table;
			background: #ccc;
			width: 40%;
			height: 1px;
			position: relative;
			margin-top: 20px;
			float: left;
		}
		&::after {
			content: " ";
			display: table;
			background: #ccc;
			width: 40%;
			height: 1px;
			position: relative;
			margin-top: 20px;
			float: right;
		}
}
@mixin h2() {
	font-family: 'Lato';
	font-size: 4.167rem;
	font-style: normal;
	text-align: center;
	color: $bh-blue;
	padding: 57px 0px 30px 0px;
	margin: 0px;
	@media screen and (max-width: $screen-fx-max) {
		font-size: 2.5rem;
	}
}
@mixin title() {
	text-transform: uppercase;
	text-align: center;
	display: block;
	font-family: 'latoheavy';
	padding: 0px 3em 5em 2em;
	letter-spacing: 2px;
	font-size: 2rem;
	font-style: normal;
	//@media screen and (max-width: $screen-fx-max) {
	//	font-size: 2rem;
	//}
}
@mixin  fontP() {
	font-size: 2.148rem;
	color: $bd-brand;
	font-family: 'Lato';
}

@mixin buttonB() {
	max-width: 150px;
	a {
		color: $bg;
		&:hover {
			color: $bd-brand;
			text-decoration: none;
			background: none !important;
			border: solid 2px $pitch;
		}
	}
}

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
$screen-sm-mins:             ($screen-sm-min + 1) !default;
$screen-lg-mins:             ($screen-lg-desktop - 21) !default;
$screen-fx-max:              ($screen-sm-max - 12) !default;

@mixin headH() {
/*	font-family: 'latoheavy';
	letter-spacing: 2px;
	font-size: inherit;*/
	font-style: normal;
}


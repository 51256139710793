body.page-user-dashboard-brands {
  .view-content {
    max-width: 100%;
    .col-xs-12.col-sm-6.col-md-4.col-lg-4,  .col-xs-6.col-sm-6.col-md-4.col-lg-4 {
      margin-bottom: 3em;
      .brand-wrapper {
        border: solid thin #eeeeee;
        padding: 2em;

        .views-field-title {
          @include title();

          a {
            &:hover {
              text-decoration: none;
              color: $bd_pink;
            }
          }
        }

        .field-name-field-logo-image {
          max-width: 150px;
          margin: 0 auto;
          height: 125px;
        }

        .fa-inverse {
          color: $bd_pink;

          &::before {
            font-size: 4rem;
            content: "\f004";
            font-family: FontAwesome;
            color: #F7796D;
            font-style: normal;
          }
        }

        .views-field-ops {
          display: block;
          width: 100%;
          position: relative;
          margin-bottom: 3em;

          span.field-content {
            margin: 0 auto;
            display: block;
            max-width: 100px;
          }
        }

        .flag-wrapper {
          position: relative;
          max-width: 80px;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}
